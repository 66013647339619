import { getQuoteProperty } from 'quotes-ui-lib/utils/quote';
import { isTrue } from './isTrue';
import { STATUS, DERIVED_STATUS } from 'quotes-ui-lib/constants/status';
import I18n from 'I18n';
function unreachableCase(value) {
  throw new Error(`Didn't expect to get here. Switch value: ${value}`);
}
function paymentStatusToDerivedStatus(paymentStatus) {
  switch (paymentStatus) {
    case STATUS.PAID:
      return DERIVED_STATUS.PAID;
    case STATUS.PROCESSING:
      return DERIVED_STATUS.PROCESSING;
    case STATUS.PENDING:
      return DERIVED_STATUS.PENDING_PAYMENT;
    default:
      return unreachableCase(paymentStatus);
  }
}
export function getDerivedStatus(quote, allowPayBeforeEsign) {
  const status = getQuoteProperty(quote, 'hs_status');
  if (!status) {
    return null;
  }
  if (getIsDraft(quote)) {
    return DERIVED_STATUS.DRAFT;
  }
  if (getIsPendingApproval(quote)) {
    return DERIVED_STATUS.PENDING_APPROVAL;
  }
  if (getHasChangesRequested(quote)) {
    return DERIVED_STATUS.CHANGES_REQUESTED;
  }
  if (getIsPublishing(quote)) {
    return DERIVED_STATUS.PUBLISHING;
  }
  if (getIsPublished(quote)) {
    const esignEnabled = isTrue(getQuoteProperty(quote, 'hs_esign_enabled'));
    const esignCompleted = !!getQuoteProperty(quote, 'hs_esign_date');
    const paymentEnabled = isTrue(getQuoteProperty(quote, 'hs_payment_enabled'));
    const paymentStatus = getQuoteProperty(quote, 'hs_payment_status');
    const manuallySigned = isTrue(getQuoteProperty(quote, 'hs_manually_signed'));
    if (manuallySigned) {
      return paymentEnabled ? paymentStatusToDerivedStatus(paymentStatus) : DERIVED_STATUS.SIGNED;
    }
    if (esignEnabled && paymentEnabled) {
      return esignCompleted || allowPayBeforeEsign ? paymentStatusToDerivedStatus(paymentStatus) : DERIVED_STATUS.PENDING_SIGNATURE;
    }
    if (esignEnabled && !paymentEnabled) {
      return esignCompleted ? DERIVED_STATUS.SIGNED : DERIVED_STATUS.PENDING_SIGNATURE;
    }
    if (!esignEnabled && paymentEnabled) {
      return paymentStatusToDerivedStatus(paymentStatus);
    }
    return DERIVED_STATUS.PUBLISHED;
  }
  return null;
}
export function getStatusConfig(quote, allowPayBeforeEsign) {
  const quoteIsArchived = getIsArchived(quote);
  if (quoteIsArchived) {
    return {
      use: 'draft',
      message: 'quotesUiLib.statuses.archived'
    };
  }
  const derivedStatus = getDerivedStatus(quote, allowPayBeforeEsign);
  const manuallySigned = isTrue(getQuoteProperty(quote, 'hs_manually_signed'));
  const numSignersRequired = getQuoteProperty(quote, 'hs_esign_num_signers_required');
  const numSignersCompleted = getQuoteProperty(quote, 'hs_esign_num_signers_completed');
  const hasSignerCountData = !!numSignersRequired && !!numSignersCompleted;
  if (!derivedStatus) {
    return null;
  }
  switch (derivedStatus) {
    case DERIVED_STATUS.DRAFT:
      return {
        use: 'draft',
        message: 'quotesUiLib.statuses.draft'
      };
    case DERIVED_STATUS.PENDING_APPROVAL:
      return {
        use: 'inReview',
        message: 'quotesUiLib.statuses.pendingApproval'
      };
    case DERIVED_STATUS.CHANGES_REQUESTED:
      return {
        use: 'danger',
        message: 'quotesUiLib.statuses.changesRequested'
      };
    case DERIVED_STATUS.PUBLISHING:
      return {
        use: 'warning',
        message: 'quotesUiLib.statuses.publishing'
      };
    case DERIVED_STATUS.PUBLISHED:
      return {
        use: 'success',
        message: 'quotesUiLib.statuses.published'
      };
    case DERIVED_STATUS.PENDING_SIGNATURE:
      return hasSignerCountData ? {
        use: 'inReview',
        message: 'quotesUiLib.statuses.pendingSignature',
        options: {
          numSignersRequired,
          numSignersCompleted
        }
      } : {
        use: 'inReview',
        message: 'quotesUiLib.statuses.pendingSignatureWithoutSigners'
      };
    case DERIVED_STATUS.SIGNED:
      return manuallySigned || !hasSignerCountData ? {
        use: 'success',
        message: 'quotesUiLib.statuses.signedWithoutSigners'
      } : {
        use: 'success',
        message: 'quotesUiLib.statuses.signed',
        options: {
          numSignersRequired,
          numSignersCompleted
        }
      };
    case DERIVED_STATUS.PENDING_PAYMENT:
      return {
        use: 'default',
        message: 'quotesUiLib.statuses.pendingPayment'
      };
    case DERIVED_STATUS.PROCESSING:
      return {
        use: 'scheduled',
        message: 'quotesUiLib.statuses.processing'
      };
    case DERIVED_STATUS.PAID:
      {
        const paymentDate = getQuoteProperty(quote, 'hs_payment_date');
        const date = I18n.moment(paymentDate).format('L');
        return {
          use: 'success',
          message: 'quotesUiLib.statuses.paid',
          options: {
            date
          }
        };
      }
    default:
      return unreachableCase(derivedStatus);
  }
}
export function getIsInactive(quote) {
  return getIsArchived(quote) || getIsExpired(quote);
}
export function getIsArchived(quote) {
  return isTrue(getQuoteProperty(quote, 'hs_archived'));
}
export function getIsExpired(quote) {
  const expiresAt = getQuoteProperty(quote, 'hs_expiration_date');
  return expiresAt ? I18n.moment().isAfter(expiresAt) : false;
}
export function getIsPublishable(quote) {
  return [STATUS.APPROVAL_NOT_NEEDED, STATUS.APPROVED].includes(getQuoteProperty(quote, 'hs_status'));
}
export function getIsPublished(quote) {
  return getIsPublishable(quote) && !!getQuoteProperty(quote, 'hs_quote_link');
}
export function getIsPublishing(quote) {
  return getIsPublishable(quote) && !getQuoteProperty(quote, 'hs_quote_link');
}
export function getHasChangesRequested(quote) {
  return getQuoteProperty(quote, 'hs_status') === STATUS.REJECTED;
}
export function getIsDraft(quote) {
  return getQuoteProperty(quote, 'hs_status') === STATUS.DRAFT;
}
export function getIsPendingApproval(quote) {
  return getQuoteProperty(quote, 'hs_status') === STATUS.PENDING_APPROVAL;
}
export function getIsPendingApprovalOrRejected(quote) {
  return [STATUS.PENDING_APPROVAL, STATUS.REJECTED].includes(getQuoteProperty(quote, 'hs_status'));
}
export function getIsPartiallySignedPaidOrProcessing(quote) {
  const esignEnabled = isTrue(getQuoteProperty(quote, 'hs_esign_enabled'));
  const numSignersRequired = Number(getQuoteProperty(quote, 'hs_esign_num_signers_required'));
  const numSignersCompleted = Number(getQuoteProperty(quote, 'hs_esign_num_signers_completed'));
  const isPartiallySigned = esignEnabled && numSignersCompleted > 0 && numSignersCompleted < numSignersRequired;
  return isPartiallySigned || getIsPaidOrProcessing(quote);
}
export function getIsFullySignedPaidOrProcessing(quote) {
  return getIsFullySigned(quote) || getIsPaidOrProcessing(quote);
}
export function getIsFullySigned(quote) {
  return !!getQuoteProperty(quote, 'hs_esign_date') || isTrue(getQuoteProperty(quote, 'hs_manually_signed'));
}
export function getIsPaidOrProcessing(quote) {
  return [STATUS.PAID, STATUS.PROCESSING].includes(getQuoteProperty(quote, 'hs_payment_status'));
}