import { useWhatsAppSubscriptionData } from './useWhatsAppSubscriptionData';
export function useWhatsAppSubscription({
  deferred,
  endUserPhoneNumber,
  hubSpotCustomerPhoneNumber
}) {
  const {
    whatsAppSubscription,
    loading,
    error
  } = useWhatsAppSubscriptionData({
    deferred,
    endUserPhoneNumber,
    hubSpotCustomerPhoneNumber
  });
  const isSubscribedForTemplates = whatsAppSubscription === null || whatsAppSubscription === void 0 ? void 0 : whatsAppSubscription.subscriptionStatuses.some(status => status.subscriptionStatus === 'OPT_IN');
  return {
    isSubscribedForTemplates,
    loading,
    error
  };
}