import { List as ImmutableList } from 'immutable';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import AsyncData from 'conversations-async-data/async-data/AsyncData';
import { getData } from 'conversations-async-data/async-data/operators/getters';
import { STARTED, SUCCEEDED } from 'conversations-async-data/async-data/constants/asyncStatuses';
import { isLoading, isSucceeded } from 'conversations-async-data/async-data/operators/statusComparators';
import { useAssociatedTickets } from 'svh-tickets-lib/tickets/public/hooks';
import { isConfigKeyGenericChannelId } from 'conversations-thread-data/channel-configuration/public/operators';
import { getMetadataConnectedAccountAddress } from 'conversations-message-history/common-message-format/operators/emailMetadataGetters';
import { EMAIL_METADATA } from 'conversations-message-history/common-message-format/constants/attachmentTypes';
// @ts-expect-error module not typed
import { setInReplyToId } from 'conversations-message-history/common-message-format/operators/commonMessageFormatSetters';
import { getAttachments, getChannelInstanceId } from 'conversations-message-history/common-message-format/operators/commonMessageGetters';
import CommonReply from 'conversations-thread-data/common-reply/public/records/CommonReply';
import { getInitialRecipients, getInitialRecipientsForEmail } from 'composer-data/recipients/public/operators';
import { getInReplyToId } from 'conversations-thread-data/common-reply/public/operators/getInReplyToId';
import { buildEmailMetadataAttachment } from 'composer-data/email-metadata/public/operators';
import { useConnectedAccounts } from 'conversations-thread-data/connected-accounts/public';
import { useThreadDetails } from '../../thread-details-feature/public/hooks/useThreadDetails';
// @ts-expect-error module not typed
import { useThreadHistory } from '../../thread-histories/hooks/useThreadHistory';
import { useResolvedFileAttachments } from 'composer-data/attachments/public/hooks';
import { getForwardCommunicatorState } from 'composer-data/email/public/selectors';
import { getCrmConversationId, getOriginalChannelInstanceId, getVid } from 'conversations-thread-data/thread-details/public/operators';
import { FORWARD_MESSAGE, REPLY_TO_MESSAGE } from '../constants/replyStrategies';
import { buildCommonMessage } from 'conversations-message-history/common-message-format/operators/buildCommonMessage';
import { useChannelInstances } from 'conversations-thread-data/channel-instances/public/hooks';
import { getChannelInstanceIdByDeliveryIdentifier, getChannelInstancesForChannelId } from 'conversations-thread-data/channel-instances/public/operators';
import { WEB } from 'conversations-message-history/common-message-format/constants/clientTypes';
import { OUTGOING } from 'conversations-message-history/common-message-format/constants/messageDirections';
import { getUserId } from 'conversations-thread-data/auth/public/selectors';
import { useCommonMessageWithId } from 'conversations-thread-data/thread-history/public/hooks';
import { isMessagePublished } from 'conversations-message-history/common-message-format/operators/isMessagePublished';
import { EMAIL_GENERIC_CHANNEL_ID } from 'conversations-thread-data/generic-channels/public/constants';
import { reportError } from 'conversations-error-reporting/error-reporting/reportError';
import { LogLevel } from 'conversations-error-reporting/error-reporting/constants/LogLevel';
import toJS from 'transmute/toJS';
import { useInitialSenders } from 'conversations-thread-data/common-reply/public/hooks/useInitialSenders';
export const useEmptyReply = ({
  currentChannelId: currentEditorKey,
  threadId,
  inboxId,
  messageId,
  strategy,
  userFullName,
  visitorEmail,
  clientType = WEB,
  defaultSendFrom
}) => {
  const currentChannelId = isConfigKeyGenericChannelId(currentEditorKey) ? currentEditorKey : null;
  const {
    isReply,
    replyAll
  } = useSelector(getForwardCommunicatorState);
  const userId = useSelector(getUserId);
  const {
    asyncThreadDetails
  } = useThreadDetails({
    threadId
  });
  const {
    asyncThreadHistory
  } = useThreadHistory({
    deferred: !messageId,
    threadId
  });
  const {
    asyncChannelInstances
  } = useChannelInstances({
    inboxId
  });
  const checkConnectedAccounts = currentChannelId === EMAIL_GENERIC_CHANNEL_ID;
  const {
    asyncConnectedAccounts
  } = useConnectedAccounts({
    inboxId,
    filterNonGlobalAccounts: true,
    deferred: !checkConnectedAccounts
  });
  const threadDetails = getData(asyncThreadDetails);
  const threadHistory = getData(asyncThreadHistory);
  const channelInstances = getData(asyncChannelInstances) || ImmutableList();
  const connectedAccounts = getData(asyncConnectedAccounts);
  const crmConversationId = getCrmConversationId(threadDetails);
  const {
    associatedTicket
  } = useAssociatedTickets({
    conversationId: crmConversationId || undefined
  });
  const {
    asyncCommonMessage
  } = useCommonMessageWithId({
    deferred: !messageId || !threadId || !threadHistory,
    messageId,
    threadHistory,
    threadId
  });
  const messageForIdResolved = messageId ? !isLoading(asyncCommonMessage) : true;
  const isForward = strategy === FORWARD_MESSAGE;

  // Hydrate the attachments and email recipients
  const [attachments, attachmentsLoaded] = useResolvedFileAttachments({
    asyncCommonMessage,
    deferred: !messageForIdResolved || isReply // Defer hydration if it's a reply
  });

  /* build the same metadata as today, but in common message metadata format */
  const emailMetadataAttachment = useMemo(() => {
    if (!isSucceeded(asyncChannelInstances) || !isSucceeded(asyncConnectedAccounts) || !messageForIdResolved || currentChannelId !== EMAIL_GENERIC_CHANNEL_ID) {
      // channel has no metadata
      return null;
    }
    return buildEmailMetadataAttachment({
      associatedTicket,
      asyncCommonMessage,
      /*  @ts-expect-error Jun-5-2024, 19:34UTC TODO: fix broken types introduced from typing of ThreadDetails record: https://git.hubteam.com/HubSpot/conversations-thread-view/pull/3656  */
      threadDetails,
      threadHistory,
      channelInstances,
      connectedAccounts: connectedAccounts || undefined,
      isForward,
      replyAll,
      userFullName,
      visitorEmail,
      defaultSendFrom
    });
  }, [associatedTicket, asyncCommonMessage, messageForIdResolved, asyncChannelInstances, asyncConnectedAccounts, threadDetails, threadHistory, channelInstances, connectedAccounts, isForward, replyAll, userFullName, visitorEmail, currentChannelId, defaultSendFrom]);
  const {
    senders,
    areSendersLoading
  } = useInitialSenders({
    channelId: currentChannelId,
    inboxId,
    inReplyToMessage: getData(asyncCommonMessage),
    userId,
    threadId,
    defaultSendFrom
  });
  const emptyReplyStatus = useMemo(() => {
    if (areSendersLoading) return STARTED;
    const asyncDataArray = [asyncThreadDetails, asyncChannelInstances, asyncConnectedAccounts, asyncThreadHistory];
    if (messageId) asyncDataArray.push(asyncCommonMessage);
    const isAnyDataLoading = asyncDataArray.some(asyncData => {
      const isStatusLoading = isLoading(asyncData);
      const data = getData(asyncData);
      // @ts-expect-error some of the data (connected accounts, channel instances) are Immutable iterables, which have a size property. the data property may have a value, but it would be empty
      const isDataEmpty = !data || !data.size;
      return isStatusLoading && isDataEmpty;
    });
    return isAnyDataLoading ? STARTED : SUCCEEDED;
  }, [asyncThreadDetails, asyncChannelInstances, asyncConnectedAccounts, asyncThreadHistory, messageId, asyncCommonMessage, areSendersLoading]);
  const commonMessage = useMemo(() => {
    /* set attachment metadata directly on the common message instead of the common reply */
    if (isLoading(asyncChannelInstances) || !messageForIdResolved) {
      return null;
    }

    /* set new fields (senders & recipients) on the common message */

    const inReplyToMessage = getData(asyncCommonMessage);
    const fromConnectedAccountAddress = emailMetadataAttachment ? getMetadataConnectedAccountAddress(emailMetadataAttachment) : '';
    const channelInstancesForCurrentChannel = currentChannelId ? getChannelInstancesForChannelId(getData(asyncChannelInstances), currentChannelId) : ImmutableList();
    const channelInstanceIdFromCommonMessage = inReplyToMessage ? getChannelInstanceId(inReplyToMessage) : null;
    let channelInstanceId;
    if (fromConnectedAccountAddress) {
      channelInstanceId = getChannelInstanceIdByDeliveryIdentifier({
        channelInstances: channelInstancesForCurrentChannel,
        deliveryIdentifierValue: fromConnectedAccountAddress
      });
    } else {
      /*  @ts-expect-error Jun-5-2024, 19:34UTC TODO: fix broken types introduced from typing of ThreadDetails record: https://git.hubteam.com/HubSpot/conversations-thread-view/pull/3656  */
      channelInstanceId = channelInstanceIdFromCommonMessage || getOriginalChannelInstanceId(threadDetails);
    }
    const vid = getVid(threadDetails);
    const shouldUseEmailMetadataForRecipients = Boolean(currentChannelId === EMAIL_GENERIC_CHANNEL_ID && emailMetadataAttachment);
    const initialRecipients = shouldUseEmailMetadataForRecipients ? getInitialRecipientsForEmail({
      asyncCommonMessage,
      channelInstances,
      connectedAccounts,
      /*  @ts-expect-error Jun-5-2024, 19:34UTC TODO: fix broken types introduced from typing of ThreadDetails record: https://git.hubteam.com/HubSpot/conversations-thread-view/pull/3656  */
      threadDetails,
      replyAll,
      visitorEmail,
      isForward
    }) : getInitialRecipients({
      asyncCommonMessage,
      vid: vid || undefined
    });
    let messageAttachments = ImmutableList();
    if (isForward) {
      const message = getData(asyncCommonMessage);
      const fileAttachments = message ? getAttachments(message) : ImmutableList();
      if (fileAttachments) {
        if (ImmutableList.isList(fileAttachments)) {
          const filteredList = fileAttachments.filter(attachment => attachment['@type'] !== EMAIL_METADATA);
          messageAttachments = messageAttachments.push(...filteredList.toArray());
        } else {
          // This case is a customer jira that is difficult to replicate https://git.hubteam.com/HubSpot/conversations-thread-view/pull/1875
          messageAttachments = messageAttachments.push(fileAttachments);
          reportError({
            error: new Error(`Found case of fileAttachments not being an ImmutableList.`),
            extra: {
              message: toJS(message)
            },
            level: LogLevel.info
          });
        }
      }
    }
    const hasEmailMetadataAttachment = messageAttachments.some(attachment => attachment['@type'] === EMAIL_METADATA);
    if (emailMetadataAttachment && !hasEmailMetadataAttachment) {
      messageAttachments = messageAttachments.push(emailMetadataAttachment);
    }
    const message = buildCommonMessage({
      recipients: initialRecipients || ImmutableList(),
      senders,
      attachments: messageAttachments.toArray(),
      genericChannelId: currentChannelId,
      channelInstanceId: channelInstanceId || null,
      clientType,
      direction: OUTGOING
    });
    const canReplyToSpecificMessages = currentChannelId === EMAIL_GENERIC_CHANNEL_ID;
    const commonMessageData = getData(asyncCommonMessage);
    const isAsyncCommonMsgPublished = commonMessageData ? isMessagePublished(commonMessageData) : false;
    const inReplyToId = canReplyToSpecificMessages && isAsyncCommonMsgPublished ? getInReplyToId({
      asyncCommonMessage
    }) : null;
    return setInReplyToId(inReplyToId, message);
  }, [clientType, asyncCommonMessage, asyncChannelInstances, currentChannelId, emailMetadataAttachment, isForward, messageForIdResolved, threadDetails, senders, channelInstances, connectedAccounts, replyAll, visitorEmail]);

  /* build replyId */
  let contextIdentifier = strategy;
  if (strategy === FORWARD_MESSAGE || strategy === REPLY_TO_MESSAGE) {
    contextIdentifier = replyAll ? `${strategy}-all-${messageId}` : `${strategy}-${messageId}`;
  }
  const replyId = `${currentEditorKey}-${contextIdentifier}-${threadId}-${inboxId}`;

  /* build common reply ( & common message ) */
  const reply = useMemo(() => CommonReply({
    replyId,
    commonMessage,
    attachments: attachmentsLoaded ? attachments : ImmutableList(),
    vid: getVid(threadDetails)
  }), [commonMessage, replyId, attachmentsLoaded, attachments, threadDetails]);

  /* create async empty reply */
  const asyncEmptyReply = useMemo(() => new AsyncData({
    status: emptyReplyStatus,
    data: reply
  }), [emptyReplyStatus, reply]);
  return {
    asyncEmptyReply
  };
};