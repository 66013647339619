import { getCurrentReplyRecommendationObject, getLastConversationalMessage, getReplyRecommendationsHistory } from 'conversations-message-history/reply-recommendations/operators/getters';
// @ts-expect-error module not typed
import { useThreadHistory } from 'conversations-thread-view/thread-histories/hooks/useThreadHistory';
import { getData } from 'conversations-async-data/async-data/operators/getters';
// @ts-expect-error module not typed
import { getId } from 'conversations-message-history/common-message-format/operators/commonMessageFormatGetters';
import { COMPOSITION_THREAD_ID } from 'conversations-thread-data/communicator/constants/compositionThreadId';
// @ts-expect-error module not typed
import { isFromAgent } from 'conversations-message-history/common-message-format/operators/senderTypeComparators';
export const useCurrentReplyRecommendation = ({
  threadId
}) => {
  const isCompositionThread = threadId === COMPOSITION_THREAD_ID;
  const finalThreadId = isCompositionThread ? '0' : threadId;
  const {
    asyncThreadHistory
  } = useThreadHistory({
    threadId: finalThreadId,
    deferred: threadId === COMPOSITION_THREAD_ID
  });
  const threadHistory = getData(asyncThreadHistory);
  if (!threadHistory) return {
    currentReplyRecInResponseTo: ''
  };
  const replyRecommendationsHistory = getReplyRecommendationsHistory(threadHistory);
  const lastConversationalMessage = getLastConversationalMessage(threadHistory);
  const lastConversationalMessageId = getId(lastConversationalMessage);
  const currentReplyRecommendation = getCurrentReplyRecommendationObject({
    replyRecommendationsHistory,
    lastConversationalMessage
  });
  const successfulReplyRecommendation = (currentReplyRecommendation === null || currentReplyRecommendation === void 0 ? void 0 : currentReplyRecommendation.replyType) === 'SUCCESSFUL' ? currentReplyRecommendation : undefined;
  const isAgentOrOutgoingMessage = isFromAgent(lastConversationalMessage) || (lastConversationalMessage === null || lastConversationalMessage === void 0 ? void 0 : lastConversationalMessage.direction) === 'OUTGOING';
  const unsuccessfulReason = isAgentOrOutgoingMessage ? 'AGENT_OR_OUTGOING_MESSAGE' : successfulReplyRecommendation ? undefined : currentReplyRecommendation === null || currentReplyRecommendation === void 0 ? void 0 : currentReplyRecommendation.errorReason;
  return Object.assign({}, unsuccessfulReason ? {
    unsuccessfulReason
  } : {}, {
    currentReplyRecommendation: successfulReplyRecommendation,
    currentReplyRecInResponseTo: replyRecommendationsHistory[lastConversationalMessageId] ? lastConversationalMessageId : ''
  });
};