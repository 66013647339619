/* hs-eslint ignored failing-rules */
/* eslint-disable @typescript-eslint/no-floating-promises */

import { getHasMore, getOffsetId, getOffsetTimestamp } from 'find-and-filter-data/view-members-schema/protected';
import { useCallback, useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FilterKey } from '../../search-and-filter-schema/protected';
import { fetchSearchAndFilterResults } from './filterResultsActions';
import { selectFilterResults } from './selectFilterResults';
import { selectHasFiltersSet } from './selectHasFiltersSet';
const POLLING_INTERVAL = 1000 * 3;
const MAX_POLLING_ATTEMPTS = 3;
export const useSearchAndFilterThreadListMembers = ({
  enablePolling = false,
  filterValues,
  inboxId,
  onFilterValueChange,
  sortOrder
}) => {
  const dispatch = useDispatch();
  const [refetchCount, setRefetchCount] = useState(0);
  const [fetchedMore, setFetchedMore] = useState(false);
  const filterKey = FilterKey({
    filterValues,
    sortOrder
  });
  const previousFilterValues = useRef(undefined);
  const previousSortOrder = useRef(sortOrder);
  const filterResultsState = useSelector(selectFilterResults);
  const isFilterSet = useSelector(selectHasFiltersSet);
  const threadListMemberPage = filterResultsState.data;
  useEffect(() => {
    if ((previousFilterValues.current !== filterValues || previousSortOrder.current !== sortOrder) && isFilterSet) {
      dispatch(fetchSearchAndFilterResults({
        filterKey,
        inboxId
      }));
    }
  }, [dispatch, filterKey, filterValues, inboxId, isFilterSet, previousFilterValues, previousSortOrder, sortOrder]);
  const fetchMore = useCallback(() => {
    const offsetTimestamp = threadListMemberPage && getOffsetTimestamp(threadListMemberPage);
    const offsetId = threadListMemberPage && getOffsetId(threadListMemberPage);
    const hasMore = threadListMemberPage && getHasMore(threadListMemberPage);
    const isLoadingThreadListMembers = filterResultsState.loading;
    if (hasMore && !isLoadingThreadListMembers) {
      setFetchedMore(true);
      dispatch(fetchSearchAndFilterResults({
        filterKey,
        offsetTimestamp,
        offsetId,
        inboxId
      }));
    }
  }, [dispatch, filterKey, filterResultsState.loading, inboxId, setFetchedMore, threadListMemberPage]);
  const pollSearch = useCallback(() => {
    const isLoadingThreadListMembers = filterResultsState.loading;
    if (enablePolling && !isLoadingThreadListMembers && !fetchedMore && refetchCount < MAX_POLLING_ATTEMPTS) {
      setRefetchCount(currentCount => currentCount + 1);
      dispatch(fetchSearchAndFilterResults({
        filterKey,
        inboxId
      }));
    }
  }, [enablePolling, fetchedMore, filterResultsState.loading, refetchCount, dispatch, filterKey, inboxId]);
  const isRefetchingThreadListMembers = refetchCount === 0;
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-implied-eval
    const pollingTimeoutID = setTimeout(pollSearch, POLLING_INTERVAL);
    return () => {
      clearTimeout(pollingTimeoutID);
    };
  }, [pollSearch]);
  const handleOnFilterValueChange = useRef(onFilterValueChange);
  useEffect(() => {
    if (!filterValues.equals(previousFilterValues.current)) {
      setRefetchCount(0);
      if (handleOnFilterValueChange.current) handleOnFilterValueChange.current(filterValues);
    }
  }, [filterValues]);
  useEffect(() => {
    previousFilterValues.current = filterValues;
    previousSortOrder.current = sortOrder;
  }, [filterValues, sortOrder]);
  return {
    fetchMore,
    filterResults: filterResultsState.data,
    loading: filterResultsState.loading,
    isRefetchingThreadListMembers,
    status: filterResultsState.status
  };
};