export const actions = {
  VIEW: 'VIEW',
  VIEW_PROPERTY_HISTORY: 'VIEW_PROPERTY_HISTORY',
  COPY: 'COPY',
  VOID: 'VOID',
  EDIT: 'EDIT',
  CLONE: 'CLONE',
  SEND: 'SEND',
  DOWNLOAD: 'DOWNLOAD',
  EDIT_REMINDERS: 'EDIT_REMINDERS',
  DELETE: 'DELETE',
  RECORD_PAYMENT: 'RECORD_PAYMENT',
  CHARGE_PAYMENT_METHOD: 'CHARGE_PAYMENT_METHOD'
};