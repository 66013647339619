import { createStack } from 'hub-http';
import promiseClient from 'hub-http/adapters/promiseClient';
import { retry } from 'hub-http/middlewares/core';
import { hubapi } from 'hub-http/middlewares/hubapi';
import appAuthStack from 'hub-http/stacks/hubapi';
export const createRetryClient = ({
  failureCondition,
  reason,
  maxRetries = 3,
  delay = 1000
}) => {
  return promiseClient(createStack(retry(failureCondition, {
    reason,
    maxRetries,
    delay
  }), hubapi, appAuthStack));
};