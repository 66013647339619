import { useMemo } from 'react';
import { useHttpClient } from '../../client/HttpClientContext';
import { getPropertyInputComponent } from '../utils/getPropertyInputComponent';
import { useGetAdditionalPropertyValue } from './useGetAdditionalPropertyValue';
import { useInternalPropertyValue } from './useInternalPropertyValue';
import { useObjectId } from './useObjectId';
import { useObjectTypeId } from './useObjectTypeId';
import { usePropertyAccessLevel } from './usePropertyAccessLevel';
import { useProperty } from './useProperty';
import { useGetIsUngated } from './useGetIsUngated';
export const usePropertyInputComponent = ({
  isPropertyReadOnly,
  enableMultipleEmailInput,
  enablePopovers,
  enableCheckboxInput,
  enablePhoneNumberInput,
  use = 'default'
}) => {
  const accessLevel = usePropertyAccessLevel();
  const objectTypeId = useObjectTypeId();
  const property = useProperty();
  const objectId = useObjectId();
  const getAdditionalPropertyValue = useGetAdditionalPropertyValue();
  const getIsUngated = useGetIsUngated();
  const httpClient = useHttpClient();
  const internalValue = useInternalPropertyValue();
  return useMemo(() => {
    return getPropertyInputComponent({
      accessLevel,
      enableMultipleEmailInput,
      enablePopovers,
      enableCheckboxInput,
      enablePhoneNumberInput,
      use,
      getAdditionalPropertyValue,
      getIsUngated,
      httpClient,
      objectTypeId,
      property,
      objectId,
      // We need the raw property value here because some external options
      // components need the raw value of the property to determine the reference
      // type of the property.
      //
      // A specific example of this are Analytics Source properties which can have
      // a value shaped `userId:123` that will usually get parsed into `123` in
      // usePropertyValue but for the purpose of this logic we need the raw value
      // to know it's a user property
      propertyValue: internalValue,
      readOnly: isPropertyReadOnly
    });
  }, [accessLevel, enableMultipleEmailInput, enablePopovers, enableCheckboxInput, enablePhoneNumberInput, use, getAdditionalPropertyValue, getIsUngated, httpClient, objectTypeId, property, objectId, internalValue, isPropertyReadOnly]);
};