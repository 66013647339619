import { createAction } from '@reduxjs/toolkit';
export const bulkUpdateTickets = createAction('realtime/BulkUpdateTickets', ({
  added,
  customViewId,
  updated,
  removed,
  sortState
}) => {
  return {
    payload: {
      added,
      customViewId,
      updated,
      removed,
      sortState
    }
  };
});