import compose from 'transmute/compose';
import get from 'transmute/get';
import { useProductInfo } from './useProductInfo';
const add = (a, b) => a + b;
const sum = arr => arr.reduce(add, 0);
const mapToCurrentSeats = salesProducts => salesProducts.map(salesProduct => get('currentSeatCount', salesProduct));
const getSalesProducts = record => get('salesProducts', record);
const getCurrentSeatCount = asyncData => compose(sum, mapToCurrentSeats, getSalesProducts)(asyncData);
const lengthReducer = (total, current) => {
  return total + current.length;
};
const sumLengths = assignedUserIds => assignedUserIds.reduce(lengthReducer, 0);
const mapToAssignedUserIds = salesProductSeatAssignments => salesProductSeatAssignments.map(assignment => get('assignedUserIds', assignment));
const getSalesProductSeatAssignments = record => get('salesProductSeatAssignments')(record);
const getCurrentAssignedSeatCount = productInfo => compose(sumLengths, mapToAssignedUserIds, getSalesProductSeatAssignments)(productInfo);
const getUnassignedSeatCount = productInfo => getCurrentSeatCount(productInfo) - getCurrentAssignedSeatCount(productInfo);

/* --------- End Helper fns for hook ------------ */

export const useUnassignedSeats = () => {
  const {
    error,
    loading,
    productInfo
  } = useProductInfo();
  const unassignedSeatCount = productInfo ? getUnassignedSeatCount(productInfo) : null;
  return {
    isFailed: !!error,
    isFetching: loading,
    unassignedSeatCount
  };
};