import { useQuery } from 'data-fetching-client';
import { useMemo } from 'react';
import { GET_VALID_CURRENCIES } from '../rest/queries';
import { useHasAllSupportedCurrenciesGate } from './useAuthGate';
export function usePortalCurrencies() {
  const {
    data,
    loading
  } = useQuery(GET_VALID_CURRENCIES);
  const hasAllSupportedCurrenciesGate = useHasAllSupportedCurrenciesGate();
  return useMemo(() => {
    const portalCurrencies = data !== null && data !== void 0 && data.currencyData && Array.isArray(data.currencyData) ? data.currencyData : [];
    const supportedCurrencies = portalCurrencies.filter(currency => currency.isProcessorSupportedCurrency);
    let defaultCurrency = undefined;
    if (hasAllSupportedCurrenciesGate) {
      var _portalCurrencies$fin;
      defaultCurrency = (_portalCurrencies$fin = portalCurrencies.find(currency => currency.isHomeCurrency && currency.isActivePortalCurrency)) === null || _portalCurrencies$fin === void 0 ? void 0 : _portalCurrencies$fin.currencyCode;
    } else {
      var _supportedCurrencies$;
      defaultCurrency = (_supportedCurrencies$ = supportedCurrencies.find(currency => currency.isHomeCurrency && currency.isActivePortalCurrency)) === null || _supportedCurrencies$ === void 0 ? void 0 : _supportedCurrencies$.currencyCode;
    }
    return {
      loading,
      /** @deprecated use loading */
      currenciesLoading: loading,
      supportedCurrencies,
      defaultCurrency,
      allCurrencies: portalCurrencies
    };
  }, [data === null || data === void 0 ? void 0 : data.currencyData, loading, hasAllSupportedCurrenciesGate]);
}

/** @deprecated use usePortalCurrencies */
export function useHasValidPortalCurrency() {
  const {
    currenciesLoading,
    supportedCurrencies
  } = usePortalCurrencies();
  return supportedCurrencies.length > 0 || currenciesLoading;
}