import { registerQuery, useQuery } from 'data-fetching-client';
import { fetchWhatsAppSubscription } from '../clients/fetchWhatsAppSubscription';
const FETCH_WHATSAPP_SUBSCRIPTION_QUERY = registerQuery({
  fieldName: 'whatsAppSubscription',
  args: ['endUserPhoneNumber', 'hubSpotCustomerPhoneNumber'],
  fetcher: fetchWhatsAppSubscription
});
export function useWhatsAppSubscriptionData({
  deferred,
  endUserPhoneNumber,
  hubSpotCustomerPhoneNumber
}) {
  const {
    data,
    loading,
    error
  } = useQuery(FETCH_WHATSAPP_SUBSCRIPTION_QUERY, {
    skip: deferred || !endUserPhoneNumber || !hubSpotCustomerPhoneNumber,
    variables: {
      endUserPhoneNumber,
      hubSpotCustomerPhoneNumber
    }
  });
  const whatsAppSubscription = data && data.whatsAppSubscription;
  return {
    whatsAppSubscription,
    loading,
    error
  };
}