import I18n from 'I18n';
export const objectSummaryParser = action => {
  if (action.objectName) {
    return I18n.text('aiComponentsUiLibrary.actions.actionParsers.objectSummary.tellAbout', {
      objectType: action.objectTypeLabel && action.objectTypeLabel.toLowerCase(),
      objectName: action.objectName
    });
  }
  if (action.label) {
    return I18n.text('aiComponentsUiLibrary.actions.actionParsers.objectSummary.summarize', {
      objectType: action.objectTypeLabel && action.objectTypeLabel.toLowerCase(),
      label: action.label
    });
  }
  return I18n.text('aiComponentsUiLibrary.actions.actionParsers.objectSummary.tellAboutUnknown', {
    objectType: action.objectTypeLabel && action.objectTypeLabel.toLowerCase()
  });
};