import { getDefaultViewType } from '../custom-views/customViewOperators';
import { getInternalName } from '../thread-lists/threadListOperators';

/**
 * @description Returns the view type. If it's a CustomView, this is the defaultViewType,
 * if it is a ThreadList then this is the internalName.
 */
export function getViewInternalType(view) {
  if (!view) return null;
  if ('customViewId' in view) {
    var _ref;
    return (_ref = getDefaultViewType(view)) !== null && _ref !== void 0 ? _ref : null;
  } else {
    var _ref2;
    return (_ref2 = getInternalName(view)) !== null && _ref2 !== void 0 ? _ref2 : null;
  }
}