import createMark from '../../internal/createMark';
const marks = {};
function getMark(key) {
  if (Object.hasOwn(marks, key)) {
    return marks[key];
  }
  const mark = createMark(key);
  marks[key] = mark;
  return mark;
}
function getCleanedMark(key) {
  if (Object.hasOwn(marks, key)) {
    return marks[key];
  }
  const regex = /[^,._-\w\d\s]/g;
  const markName = key.replaceAll(regex, '_').replaceAll(/--+/g, '_');
  const mark = createMark(markName);
  marks[key] = mark;
  return mark;
}
export const getDownloadStart = (cardType, part) => {
  const key = `mark-card-${cardType}-download-${part}-start`;
  return getMark(key);
};
export const getDownloadFinish = (cardType, part) => {
  const key = `mark-card-${cardType}-download-${part}-finish`;
  return getMark(key);
};

// Location
export const getCrmLocationResolverStart = (location, loggingTag) => {
  const key = `mark-crm-location-resolver-start-${location}${loggingTag ? `-${loggingTag}` : ''}`;
  return getMark(key);
};
export const getCrmRecordCardsResolverStart = (location, loggingTag) => {
  const key = `mark-crm-record-cards-resolver-start-${location}${loggingTag ? `-${loggingTag}` : ''}`;
  return getMark(key);
};
export const getCrmCardLocationStart = (location, loggingTag) => {
  const key = `mark-crm-card-location-start-${location}${loggingTag ? `-${loggingTag}` : ''}`;
  return getMark(key);
};

// Location Resolver Query
export const CrmLocationResolverQueryStart = createMark('crm-location-resolver-query-start');
export const CrmLocationResolverQueryFinish = createMark('crm-location-resolver-query-finish');
export const CrmLocationResolverQueryGQLStart = createMark('crm-location-resolver-query-gql-start');
export const CrmLocationResolverQueryGQLFinish = createMark('crm-location-resolver-query-gql-finish');
export const CrmLocationResolverQueryFASStart = createMark('crm-location-resolver-query-fas-start');
export const CrmLocationResolverQueryFASFinish = createMark('crm-location-resolver-query-fas-finish');

// Card Location
export const FetchLegacySidebarSubjectStart = createMark('fetch-legacy-sidebar-subject-start');
export const FetchLegacySidebarSubjectFinish = createMark('fetch-legacy-sidebar-subject-finish');

// Card render
export const getCardWrapperRenderStart = (cardType, cardId) => {
  const key = `mark-crm-card-wrapper-${cardType}-render-start-${cardId}`;
  return getCleanedMark(key); // cardId may have forbidden characters
};
export const getCardRenderStart = (cardType, cardId) => {
  const key = `mark-card-${cardType}-render-start-${cardId}`;
  return getCleanedMark(key); // cardId may have forbidden characters
};
export const getCardRenderFinish = (cardType, cardId) => {
  const key = `mark-card-${cardType}-render-finish-${cardId}`;
  return getCleanedMark(key); // cardId may have forbidden characters
};
export const getCardRenderTimeout = (cardType, cardId) => {
  const key = `mark-card-${cardType}-render-timeout-${cardId}`;
  return getCleanedMark(key); // cardId may have forbidden characters
};
export const getRealCardContentStart = (cardType, cardId) => {
  const key = `mark-card-${cardType}-real-content-start-${cardId}`;
  return getCleanedMark(key); // cardId may have forbidden characters
};