import pipe from 'transmute/pipe';
import { getData } from 'conversations-async-data/async-data/operators/getters';
import EmailMetadata from 'conversations-message-history/common-message-format/records/EmailMetadata';
// @ts-expect-error module not typed

import { buildEmailMetadataWithFromAddress } from './buildEmailMetadataWithFromAddress';
import { buildEmailMetadataWithSubject } from './buildEmailMetadataWithSubject';
export const buildEmailMetadataAttachment = ({
  channelInstances,
  connectedAccounts,
  threadDetails,
  asyncCommonMessage,
  isForward,
  userFullName,
  associatedTicket,
  defaultSendFrom
}) => {
  if (!channelInstances || !connectedAccounts || !threadDetails) {
    return {};
  }
  const inReplyToMessage = getData(asyncCommonMessage);
  const emptyAttachment = {
    forward: isForward
  };
  const builtMetadata = pipe(attachment => buildEmailMetadataWithFromAddress(attachment, inReplyToMessage, {
    channelInstances,
    connectedAccounts,
    defaultSendFrom
  }), attachment => buildEmailMetadataWithSubject(attachment, inReplyToMessage, {
    associatedTicket,
    isForward,
    channelInstances,
    connectedAccounts,
    userFullName
  }))(emptyAttachment);
  return EmailMetadata(builtMetadata);
};