// @ts-expect-error module not typed
import EmailAddressRegex from 'PatternValidationJS/regex/EmailAddressRegex';
// @ts-expect-error module not typed
import UrlRegexFactory from 'PatternValidationJS/regex/factories/UrlRegexFactory';
import { Fragment } from 'prosemirror-model';
const mailtoRegex = `mailto:${EmailAddressRegex.source.replace(/^\^(.*)\$$/, '$1')}`;
const urlSourceWithoutBoundaries = UrlRegexFactory({
  protocolRequired: true
}).source.replace(/^\^(.*)\$$/, '$1');
export function linkifyTextNode(node) {
  var _node$text3;
  const linkified = [];

  // create a new RegExp object as .exec() is stateful in searching for the next match
  const urlRegex = new RegExp(`\\b(${urlSourceWithoutBoundaries})|(${mailtoRegex})\\b`, 'g');
  const schema = node.type.schema;
  let pos = 0;
  let matchedUrlText = node.text && urlRegex.exec(node.text);
  while (matchedUrlText) {
    var _node$text, _node$text2;
    const start = matchedUrlText.index;
    const end = start + matchedUrlText[0].length;
    const link = schema.marks.link;
    const initialText = start > 0 && ((_node$text = node.text) === null || _node$text === void 0 ? void 0 : _node$text.slice(pos, start));
    if (initialText) {
      linkified.push(schema.text(initialText, node.marks));
    }
    const urlText = (_node$text2 = node.text) === null || _node$text2 === void 0 ? void 0 : _node$text2.slice(start, end);
    if (urlText) {
      var _punctuationMatch$gro, _punctuationMatch$gro2;
      const punctuationMatch = urlText.match(/(?<url>.*)(?<endingPunctuation>[?.,]+)$/);
      const url = (punctuationMatch === null || punctuationMatch === void 0 || (_punctuationMatch$gro = punctuationMatch.groups) === null || _punctuationMatch$gro === void 0 ? void 0 : _punctuationMatch$gro.url) || urlText;
      const endingPunctuation = punctuationMatch === null || punctuationMatch === void 0 || (_punctuationMatch$gro2 = punctuationMatch.groups) === null || _punctuationMatch$gro2 === void 0 ? void 0 : _punctuationMatch$gro2.endingPunctuation;
      linkified.push(schema.text(url, link.create({
        href: url
      }).addToSet(node.marks)));
      if (endingPunctuation) {
        linkified.push(schema.text(endingPunctuation, node.marks));
      }
    }
    pos = end;
    matchedUrlText = node.text && urlRegex.exec(node.text);
  }
  const finalText = (_node$text3 = node.text) === null || _node$text3 === void 0 ? void 0 : _node$text3.slice(pos);
  if (finalText) {
    linkified.push(schema.text(finalText, node.marks));
  }
  return linkified;
}
export function linkify(fragment) {
  const linkified = [];
  for (let i = 0; i < fragment.childCount; i++) {
    const child = fragment.child(i);
    if (child.isText) {
      const isAlreadyLinked = child.marks.some(mark => mark.type.name === 'link');
      if (isAlreadyLinked) {
        linkified.push(child);
      } else {
        linkified.push(...linkifyTextNode(child));
      }
    } else {
      linkified.push(child.copy(linkify(child.content)));
    }
  }
  return Fragment.fromArray(linkified);
}