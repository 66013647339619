import { useEffect, useRef, useState } from 'react';
export const useContainerQuery = breakpoints => {
  const [breakpointResults, setBreakpointResults] = useState({});
  const ref = useRef(undefined);
  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      const [resizeObserverEntry] = entries;
      const {
        contentBoxSize: [resizeObserverSize]
      } = resizeObserverEntry;
      const containerWidth = resizeObserverSize.inlineSize;
      setBreakpointResults(previousBreakpointResults => {
        const updatedBreakpointResults = {};
        let statesDiffer = false;
        for (const breakpoint of breakpoints) {
          updatedBreakpointResults[breakpoint] = containerWidth < breakpoint || false;
          if (updatedBreakpointResults[breakpoint] !== previousBreakpointResults[breakpoint]) {
            statesDiffer = true;
          }
        }
        if (statesDiffer) {
          return updatedBreakpointResults;
        }
        // returning the previous state here will cancel this update
        return previousBreakpointResults;
      });
    });
    if (ref.current) {
      resizeObserver.observe(ref.current);
    }
    return () => resizeObserver.disconnect();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return [breakpointResults, ref];
};