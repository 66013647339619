import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { AsyncStatus } from 'find-and-filter-data/common/public';
import { DEFAULT_SEARCH_AND_FILTER_VIEW_PLACEHOLDER } from 'find-and-filter-data/search-and-filter-data/protected';
import { fetchHelpDeskViewMembersService } from './fetchHelpDeskViewMembersService';
import { fetchSearchViewMembersService } from './fetchSearchViewMembersService';
import { selectAreSearchParamsEqual, selectHelpDeskViewMembersPageStatus, selectIsDuplicateRequestPending } from './helpDeskViewMemberSelectors';
export const prefetchViewMembersFulfilled = createAction('filterResults/prefetchViewMembersFulfilled', payload => ({
  payload
}));
export const fetchHelpDeskViewMembers = createAsyncThunk('filterResults/fetchHelpDeskViewMembers', ({
  filterGroups,
  inboxId,
  limit,
  offsetTimestamp,
  offsetId,
  primaryOffsetValue,
  secondaryOffsetValue,
  searchQuery,
  sortDirection,
  sortProperty,
  viewId
}) => fetchHelpDeskViewMembersService(Object.assign({}, viewId !== DEFAULT_SEARCH_AND_FILTER_VIEW_PLACEHOLDER && {
  customViewId: +viewId
}, {
  filterGroups,
  inboxId,
  limit,
  offsetTimestamp,
  offsetId,
  primaryOffsetValue,
  secondaryOffsetValue,
  searchQuery,
  sortDirection,
  sortProperty
})), {
  condition: (params, {
    getState
  }) => {
    var _params$options;
    if ((_params$options = params.options) !== null && _params$options !== void 0 && _params$options.force) {
      return true;
    }
    const state = getState();
    const status = selectHelpDeskViewMembersPageStatus(state);
    if (status === AsyncStatus.PREFETCH) {
      return false;
    }
    const duplicateRequestIsPending = selectIsDuplicateRequestPending(state, params);

    // Do not initiate a new request if equivalent request is pending
    if (duplicateRequestIsPending) {
      return false;
    }

    // If there is pending request that is DIFFERENT from the current request, allow the new request to go through
    if (status === AsyncStatus.INITIAL_FETCH_LOADING || status === AsyncStatus.PAGINATED_FETCH_LOADING) {
      return true;
    }
    const searchParamsAreEqual = selectAreSearchParamsEqual(state, params);

    // If there is no pending request, and the search params are equal to the last request, ignore the request
    return !searchParamsAreEqual;
  }
});
export const fetchSearchViewMembers = createAsyncThunk('filterResults/searchHelpDeskViewMembers', ({
  crmOffset,
  filterGroups,
  inboxId,
  limit,
  offsetTimestamp,
  offsetId,
  primaryOffsetValue,
  secondaryOffsetValue,
  searchQuery,
  sortDirection,
  sortProperty
}) => fetchSearchViewMembersService({
  crmOffset,
  filterGroups,
  inboxId,
  limit,
  offsetTimestamp,
  offsetId,
  primaryOffsetValue,
  secondaryOffsetValue,
  searchQuery,
  sortDirection,
  sortProperty
}), {
  condition: (params, {
    getState
  }) => {
    var _params$options2;
    if ((_params$options2 = params.options) !== null && _params$options2 !== void 0 && _params$options2.force) {
      return true;
    }
    const state = getState();
    const status = selectHelpDeskViewMembersPageStatus(state);
    if (status === AsyncStatus.PREFETCH) {
      return false;
    }
    const duplicateRequestIsPending = selectIsDuplicateRequestPending(state, params);

    // Do not initiate a new request if equivalent request is pending
    if (duplicateRequestIsPending) {
      return false;
    }

    // If there is pending request that is DIFFERENT from the current request, allow the new request to go through
    if (status === AsyncStatus.INITIAL_FETCH_LOADING || status === AsyncStatus.PAGINATED_FETCH_LOADING) {
      return true;
    }
    const searchParamsAreEqual = selectAreSearchParamsEqual(state, params);

    // If there is no pending request, and the search params are equal to the last request, ignore the request
    return !searchParamsAreEqual;
  }
});
export const optimisticUpdate = createAction('optimisticUpdate', payload => ({
  payload
}));
export const bulkOptimisticUpdate = createAction('realtime/BulkOptimisticUpdate', ({
  ids,
  updater,
  sortState,
  viewId
}) => {
  return {
    payload: {
      ids,
      updater,
      sortState,
      viewId
    }
  };
});