import { useCallback, useContext } from 'react';
import { useObjectTypeId } from '../v2/hooks/useObjectTypeId';
import { HighlySensitivePropertyContext } from './HighlySensitivePropertyContext';
import { useObjectId } from '../v2/hooks/useObjectId';
import { usePropertyValueTimestamp } from '../v2/hooks/usePropertyValueTimestamp';
import { useProperty } from '../v2/hooks/useProperty';
export const useFetchHighlySensitivePropertyValue = () => {
  const property = useProperty();
  const objectTypeId = useObjectTypeId();
  const objectId = useObjectId();
  const timestamp = usePropertyValueTimestamp();
  const context = useContext(HighlySensitivePropertyContext);
  const propertyName = property.name;

  // If we are in a preview use case we won't have an objectId
  // in that situation we stub out the fetch function
  const isFetchingDisabled = !context || !objectId;
  const fetchHighlySensitivePropertyValue = useCallback(() => {
    if (isFetchingDisabled) {
      return Promise.resolve('');
    }
    return context.fetchHighlySensitivePropertyValue({
      objectTypeId,
      objectId,
      propertyName,
      timestamp
    });
  }, [context, objectTypeId, objectId, propertyName, isFetchingDisabled, timestamp]);
  if (isFetchingDisabled) {
    return {
      fetchHighlySensitivePropertyValue,
      highlySensitiveValueStatus: undefined
    };
  }
  const propertyValue = context.getHighlySensitivePropertyValue({
    objectTypeId,
    objectId,
    propertyName,
    timestamp
  });
  return {
    fetchHighlySensitivePropertyValue,
    highlySensitiveValueStatus: propertyValue === null || propertyValue === void 0 ? void 0 : propertyValue.status
  };
};