// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck react-dnd@4 code
import hoistNonReactStatics from 'hoist-non-react-statics';
import * as ReactDnd from 'react-dnd';
const FALLBACK_TYPE = 'DO_NOT_USE_react-dnd-compat-default';
function toDragSourceMonitor(monitor) {
  return Object.assign({}, monitor, {
    isDragging() {
      return monitor.isDragging();
    },
    canDrag() {
      return monitor.canDrag();
    },
    getItemType() {
      return monitor.getItemType();
    },
    getItem() {
      return monitor.getItem();
    },
    getDropResult() {
      return monitor.getDropResult();
    },
    didDrop() {
      return monitor.didDrop();
    },
    getInitialClientOffset() {
      return monitor.getInitialClientOffset();
    },
    getInitialSourceClientOffset() {
      return monitor.getInitialSourceClientOffset();
    },
    getClientOffset() {
      return monitor.getClientOffset();
    },
    getDifferenceFromInitialOffset() {
      return monitor.getDifferenceFromInitialOffset();
    },
    getSourceClientOffset() {
      return monitor.getSourceClientOffset();
    }
  });
}
export function DragSource(type, spec, mapMonitorToProps) {
  return function (DecoratedComponent) {
    const DragSourceComponent = ReactDnd.DragSource(type || FALLBACK_TYPE, Object.assign({}, spec), (connect, monitor) => {
      return Object.assign({
        connectDragSource: connect.dragSource(),
        connectDragPreview: connect.dragPreview()
      }, mapMonitorToProps(toDragSourceMonitor(monitor)));
    })(DecoratedComponent);
    DragSourceComponent.DecoratedComponent = DecoratedComponent;
    const displayName = DecoratedComponent.displayName || DecoratedComponent.name || 'Component';
    DragSourceComponent.displayName = `DragSource(${displayName})`;
    hoistNonReactStatics(DragSourceComponent, DecoratedComponent);
    return DragSourceComponent;
  };
}
function toDropTargetMonitor(monitor) {
  return Object.assign({}, monitor, {
    isOver(options) {
      return monitor.isOver(options);
    },
    getInitialClientOffset() {
      return monitor.getInitialClientOffset();
    },
    getClientOffset() {
      return monitor.getClientOffset();
    },
    canDrop() {
      return monitor.canDrop();
    },
    getItemType() {
      return monitor.getItemType();
    },
    getItem() {
      return monitor.getItem();
    },
    getDropResult() {
      return monitor.getDropResult();
    },
    didDrop() {
      return monitor.didDrop();
    },
    getInitialSourceClientOffset() {
      return monitor.getInitialSourceClientOffset();
    },
    getDifferenceFromInitialOffset() {
      return monitor.getDifferenceFromInitialOffset();
    },
    getSourceClientOffset() {
      return monitor.getSourceClientOffset();
    }
  });
}
export function DropTarget(type, spec, mapMonitorToProps) {
  return function (DecoratedComponent) {
    const DropTargetContainer = ReactDnd.DropTarget(type || FALLBACK_TYPE, Object.assign({}, spec), (connect, monitor) => {
      return Object.assign({
        connectDropTarget: connect.dropTarget()
      }, mapMonitorToProps(toDropTargetMonitor(monitor)));
    })(DecoratedComponent);
    DropTargetContainer.DecoratedComponent = DecoratedComponent;
    const displayName = DecoratedComponent.displayName || DecoratedComponent.name || 'Component';
    DropTargetContainer.displayName = `DragSource(${displayName})`;
    hoistNonReactStatics(DropTargetContainer, DecoratedComponent);
    return DropTargetContainer;
  };
}
function toDragLayerMonitor(monitor) {
  return Object.assign({}, monitor, {
    isDragging() {
      return monitor.isDragging();
    },
    getItemType() {
      return monitor.getItemType();
    },
    getItem() {
      return monitor.getItem();
    },
    getInitialClientOffset() {
      return monitor.getInitialClientOffset();
    },
    getClientOffset() {
      return monitor.getClientOffset();
    },
    getDifferenceFromInitialOffset() {
      return monitor.getDifferenceFromInitialOffset();
    },
    getInitialSourceClientOffset() {
      return monitor.getInitialSourceClientOffset();
    },
    getSourceClientOffset() {
      return monitor.getSourceClientOffset();
    }
  });
}
export function DragLayer(mapMonitorToProps) {
  return function (DecoratedComponent) {
    const DragLayerContainer = ReactDnd.DragLayer(monitor => {
      return mapMonitorToProps(toDragLayerMonitor(monitor));
    })(DecoratedComponent);
    DragLayerContainer.DecoratedComponent = DecoratedComponent;
    const displayName = DecoratedComponent.displayName || DecoratedComponent.name || 'Component';
    DragLayerContainer.displayName = `DragLayer(${displayName})`;
    hoistNonReactStatics(DragLayerContainer, DecoratedComponent);
    return DragLayerContainer;
  };
}
export function DragDropContext(backendFactory) {
  // This file runs for `react-dnd@2||3||4`.
  // but only `react-dnd@2` will not have `getContext` defined, which is what this takes care of.
  function createBackend(manager, ...args) {
    if (typeof manager.getContext !== 'function') {
      const currentWindow = typeof window !== 'undefined' ? window : undefined;
      manager.getContext = () => ({
        window: currentWindow
      });
    }
    return backendFactory(manager, ...args);
  }
  return ReactDnd.DragDropContext(createBackend);
}