import { useEffect, useState } from 'react';
import { getFullUrl } from 'hubspot-url-utils';
import { getQuickBooksAppId } from 'accounting-integrations-ui-components/utils/getIntegrationAppIds';
import { fetchAccounts, fetchConnections } from '../api/syncBridgeAPI';
import Raven from 'raven-js';
import { INVOICE_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
import { getConnectionObjectTypeId } from '../utils/connectionUtils';
export function useGetQuickBooksInvoiceDownloadUrl() {
  const [qboInvoiceDownloadUrl, setQBOInvoiceDownloadUrl] = useState({
    url: null,
    invoiceConnectionId: null,
    loading: true
  });
  useEffect(() => {
    const getQBOInvoiceDownloadUrl = async () => {
      let url = null;
      let invoiceConnectionId = null;
      const appId = getQuickBooksAppId();
      const accounts = await fetchAccounts(appId.toString());
      if (accounts.length > 0) {
        const accountId = accounts[0].id;
        const connections = await fetchConnections(appId.toString(), accountId.toString());
        const invoiceConnections = connections.filter(connection => getConnectionObjectTypeId(connection) === INVOICE_TYPE_ID);
        if (invoiceConnections.length > 0) {
          invoiceConnectionId = invoiceConnections[0].id;
          url = `${getFullUrl('api')}/accounting/integration/v1/invoice/${appId}/account/${accountId}/pdf/content`;
        }
      }
      setQBOInvoiceDownloadUrl({
        url,
        invoiceConnectionId,
        loading: false
      });
    };
    getQBOInvoiceDownloadUrl().catch(error => {
      setQBOInvoiceDownloadUrl({
        url: null,
        invoiceConnectionId: null,
        loading: false
      });
      Raven.captureException(error);
    });
  }, []);
  return qboInvoiceDownloadUrl;
}