import http from 'conversations-http/clients/http';
import { getGates } from 'conversations-thread-data/auth/public/selectors';
import { registerQuery, useQuery } from 'data-fetching-client';
import { useSelector } from 'react-redux';
import { gate } from 'hub-http/gates';
const fetchDefaultSendersForUser = ({
  inboxId
}) => {
  return http.get(`conversations-channels/v1/channel-instances/inboxId/${inboxId}/default-senders-for-user`);
};
export const FETCH_DEFAULT_SENDERS_FOR_USER_QUERY = registerQuery({
  fieldName: 'defaultSendersForUser',
  fetcher: fetchDefaultSendersForUser,
  args: ['inboxId']
});
export const useDefaultSendFrom = ({
  inboxId,
  channelId,
  deferred
}) => {
  const gates = useSelector(getGates);
  const isUngatedForDefaultSendFrom = gates.includes(gate('Communication:Composer:DefaultSendFromEmail'));
  const {
    loading,
    error,
    data
  } = useQuery(FETCH_DEFAULT_SENDERS_FOR_USER_QUERY, {
    skip: deferred || !inboxId || !channelId || !isUngatedForDefaultSendFrom,
    variables: {
      inboxId: inboxId
    }
  });
  const defaultSendFrom = channelId && inboxId ? data === null || data === void 0 ? void 0 : data.defaultSendersForUser[channelId] : undefined;
  return {
    loading,
    error,
    defaultSendFrom: defaultSendFrom === null || defaultSendFrom === void 0 ? void 0 : defaultSendFrom.instanceDeliveryIdentifier
  };
};