import { useAccessLevels, getLowestPrivilegeAccessLevel } from '../../accessLevel/AccessLevelContext';
import { useObjectType } from './useObjectType';
import { useObjectTypeId } from './useObjectTypeId';
import { usePropertyAccessLevelOverride } from './usePropertyAccessLevelOverride';
import { useProperty } from './useProperty';
import { isPipelineProperty } from 'customer-data-objects/property/PropertyIdentifier';
import { getPipelinePropertyName, getPipelineStagePropertyName } from '../../input/pipeline/utils/pipelineUtils';
export const usePropertyAccessLevel = () => {
  const objectType = useObjectType();
  const objectTypeId = useObjectTypeId();
  const property = useProperty();
  const accessLevelOverride = usePropertyAccessLevelOverride();
  let propertyNames = [property.name];

  // inputs which change multiple properties adopt the lowest privilege access
  // level for any property they change
  // NOTE: changing pipeline ALWAYS changes both pipeline and stage, so pipelines
  // inherit the lowest privilege level from both. changing stages MAY change
  // pipeline and stage, but is not REQUIRED to - it can also change just the
  // stage. as such, stages don't automatically inherit pipeline permissions;
  // the stage input must apply FLP restrictions internally to only allow valid
  // changes
  if (isPipelineProperty(property, objectTypeId)) {
    propertyNames = [getPipelinePropertyName(objectTypeId), getPipelineStagePropertyName(objectTypeId)];
  }
  const accessLevels = useAccessLevels({
    objectType,
    propertyNames,
    accessLevelsOverride: {
      [property.name]: accessLevelOverride
    }
  });
  if (Object.keys(accessLevels).length > 1) {
    return getLowestPrivilegeAccessLevel(accessLevels);
  }
  return accessLevels[property.name];
};