import { DASHBOARD_GENERATION } from '../../constants/CopilotInAppActionTypes';
import { parseObjectId } from './utils/parseObjectId';
export const dashboardGeneration = action => {
  return {
    data: {
      intent: DASHBOARD_GENERATION,
      crmObject: {
        object_type: action.objectTypeId,
        object_id: parseObjectId(action.objectId)
      }
    }
  };
};