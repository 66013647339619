import { Record, Map as ImmutableMap } from 'immutable';
import { PermissionType } from '../common/PermissionType';
import { generateViewId } from '../common/viewIdOperators';
import { ProcessingState, ParamType } from './customViewTypes';

/**
 * @deprecated Use HydratedCustomView from cv-backend-services
 */

export class CustomView extends Record({
  channel: '',
  createdAt: -1,
  createdByUserId: null,
  customViewId: -1,
  defaultStatus: 'OPEN',
  defaultViewType: null,
  filterBranch: null,
  id: '',
  isUserEditable: false,
  latestListRefreshEndedAt: null,
  listId: -1,
  name: '',
  objectTypeId: '',
  paramProperty: null,
  paramType: ParamType.NONE,
  permissionType: PermissionType.ALL,
  processingState: ProcessingState.Processing,
  sortByProperty: {},
  sortDirection: 'DESC',
  threadCount: null,
  viewShareType: 'ALL',
  unreadThreadCount: null
}, 'CustomView') {
  constructor(props) {
    const propsMap = ImmutableMap(props || {});
    const viewId = generateViewId(props);
    const customViewMap = propsMap.set('id', viewId);
    super(customViewMap);
    this.updateCounts = this.updateCounts.bind(this);
  }
  updateCounts({
    threadCount,
    unreadThreadCount
  }) {
    return this.set('threadCount', threadCount).set('unreadThreadCount', unreadThreadCount);
  }
}