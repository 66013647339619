import { useCallback } from 'react';
import { userInfoSync } from 'hub-http/userInfo';
import { gate } from 'hub-http/gates';
// eslint-disable-next-line no-restricted-imports -- we use http by default
import http from 'hub-http/clients/apiClient';
import { OrderedMap, Map as ImmutableMap, fromJS } from 'immutable';
import getIn from 'transmute/getIn';
import I18n from 'I18n';
import unescapedText from 'I18n/utils/unescapedText';
import formatName from 'I18n/utils/formatName';
import { CONTACT, COMPANY, DEAL, TICKET, ENGAGEMENT, CAMPAIGN } from 'customer-data-objects/constants/ObjectTypes';
import { ObjectTypesToIds, ObjectTypeFromIds, CONTACT_TYPE_ID, COMPANY_TYPE_ID, DEAL_TYPE_ID, TICKET_TYPE_ID, MEETING_EVENT_TYPE_ID, CALL_TYPE_ID, APPOINTMENT_TYPE_ID, CART_TYPE_ID, COURSE_TYPE_ID, LEAD_TYPE_ID, LISTING_TYPE_ID, ORDER_TYPE_ID, PARTNER_CLIENT_TYPE_ID, PARTNER_SERVICE_TYPE_ID, SERVICE_TYPE_ID, CONVERSATION_SESSION_TYPE_ID, CAMPAIGN_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
import sortBy from 'transmute/sortBy';
import memoize from 'transmute/memoize';
import isLegacyHubSpotObject from 'customer-data-objects/crmObject/isLegacyHubSpotObject';
import isPortalSpecificObjectType from 'customer-data-objects/crmObject/isPortalSpecificObjectType';
import { isEngagementTypeId } from 'customer-data-objects/engagement/isEngagementTypeId';

// @ts-expect-error untyped
import UniversalAssociationRecord from '../records/UniversalAssociationRecord';
// @ts-expect-error untyped
import UniversalAssociationOptionRecord from '../records/UniversalAssociationOptionRecord';
import { useFetchUniversalEngagementAssociations } from '../hooks/useFetchUniversalEngagementAssociations';
import { useFetchAutoAssociations } from '../hooks/useFetchAutoAssociations';
import { useFetchNeverLogList } from '../hooks/useFetchNeverLogList';
import { enforceNeverLogList } from './universalAssociationsSelectHelpers';
const CCDT_TYPES = [CONTACT, COMPANY, DEAL, TICKET];
const AUTO_ASSOCIATIONS_HUBSPOT_DEFINED_OBJECT_TYPE_ID_ALLOWLIST = [CONTACT_TYPE_ID, COMPANY_TYPE_ID, DEAL_TYPE_ID, TICKET_TYPE_ID];

// objectTypeIds that are allowed to be associated with engagements
// TODO: Retire this list and replace with an FAS object type allowlist
const HUBSPOT_DEFINED_OBJECT_TYPE_ID_ALLOWLIST = [APPOINTMENT_TYPE_ID, CART_TYPE_ID, COMPANY_TYPE_ID, CONTACT_TYPE_ID, COURSE_TYPE_ID, DEAL_TYPE_ID, LEAD_TYPE_ID, LISTING_TYPE_ID, ORDER_TYPE_ID, PARTNER_CLIENT_TYPE_ID, PARTNER_SERVICE_TYPE_ID, SERVICE_TYPE_ID, TICKET_TYPE_ID, CAMPAIGN_TYPE_ID];
const REQUIRED_GATE_FOR_ASSOCIATION_TYPE_ID = ImmutableMap({
  // EMAIL_TO_CAMPAIGN
  [959]: gate('Campaigns:SalesHubAssociations'),
  // CALL_TO_CAMPAIGN
  [955]: gate('Campaigns:SalesHubAssociations'),
  // MEETING_EVENT_TO_CAMPAIGN
  [957]: gate('Campaigns:SalesHubAssociations')
});
const CCDT_TYPE_IDS = CCDT_TYPES.map(objectType => {
  return ObjectTypesToIds[objectType];
});
const MEETING_TITLE = 'hs_meeting_title';
const MEETING_START_TIME = 'hs_meeting_start_time';
const CALL_START_TIME = 'hs_timestamp';
export const NULL_LABEL_VALUE = '--';

// TODO(TS): many callers do not fetch `userPermissions` under the engagement.
//           was fetching that accidental in our query? do we use it? omitting it seems to cause no TS errors
//           this should just be: fetchUniversalEngagementAssociationsData['engagement']
const isObjectTypeIdCCDT = objectTypeId => objectTypeId && CCDT_TYPE_IDS.includes(objectTypeId);
const isAllowedHubSpotDefinedObjectTypeId = objectTypeId => objectTypeId && HUBSPOT_DEFINED_OBJECT_TYPE_ID_ALLOWLIST.includes(objectTypeId);

// CONVERSATION_SESSION_TYPE_ID is not yet included in CDO's ENGAGEMENT_TYPE_IDS: https://git.hubteam.com/HubSpot/customer-data-objects/blob/master/static/js/engagement/isEngagementTypeId.ts
const _isEngagementTypeId = objectTypeId => objectTypeId && (isEngagementTypeId(objectTypeId) || objectTypeId === CONVERSATION_SESSION_TYPE_ID);

// isDefault is the catch-all field that encompasses hasAllAssociatedObjects and one-to-one default associations
// inverse definitions of isPrimary definitions are not isPrimary but rather are isInversePrimary, and both should be allowed
// many callers do not yet pass these fields because they are not fetching them yet: https://git.hubteam.com/HubSpot/CreateAndAssociateFrontendTeam/issues/628
const isAllowedAssociationDefinition = definition => definition.isDefault || definition.isPrimary || definition.isInversePrimary;
const universalAssociationOptionComparator = memoize((optionA, optionB, includeIsSelected = true) => {
  if (includeIsSelected) {
    const aIsSelected = optionA.get('isSelected');
    const bIsSelected = optionB.get('isSelected');
    if (aIsSelected && !bIsSelected) {
      return -1;
    }
    if (!aIsSelected && bIsSelected) {
      return 1;
    }
  }
  const aPrimaryDisplayLabel = optionA.get('primaryDisplayLabel').toLowerCase();
  const bPrimaryDisplayLabel = optionB.get('primaryDisplayLabel').toLowerCase();
  if (aPrimaryDisplayLabel > bPrimaryDisplayLabel) {
    return 1;
  }
  if (aPrimaryDisplayLabel < bPrimaryDisplayLabel) {
    return -1;
  }
  const aSecondaryDisplayLabel = optionA.get('secondaryDisplayLabel');
  const bSecondaryDisplayLabel = optionB.get('secondaryDisplayLabel');

  // Secondary display labels are not guaranteed to exist, return the option without one first
  if (aSecondaryDisplayLabel && !bSecondaryDisplayLabel) {
    return 1;
  }
  if (!aSecondaryDisplayLabel && bSecondaryDisplayLabel) {
    return -1;
  }
  if (!aSecondaryDisplayLabel && !bSecondaryDisplayLabel) {
    return 0;
  }
  const lowerCaseASecondaryDisplayLabel = optionA.get('secondaryDisplayLabel').toLowerCase();
  const lowerCaseBSecondaryDisplayLabel = optionB.get('secondaryDisplayLabel').toLowerCase();
  if (lowerCaseASecondaryDisplayLabel > lowerCaseBSecondaryDisplayLabel) {
    return 1;
  }
  if (lowerCaseASecondaryDisplayLabel < lowerCaseBSecondaryDisplayLabel) {
    return -1;
  }
  return 0;
});
const creationDateGetter = value => I18n.moment.userTz(Number(value)).format('lll');
function primaryDisplayLabelGetterWithDefault(propertyName) {
  return properties => {
    const primaryDisplayLabel = getIn(['properties', propertyName, 'value'], properties);
    if (!primaryDisplayLabel && propertyName === MEETING_TITLE) {
      return unescapedText('universalAssociationsSelect.emptyDisplayLabel.loggedMeeting');
    }
    return primaryDisplayLabel || NULL_LABEL_VALUE;
  };
}
function propertyValueGetter(propertyName) {
  return getIn(['properties', propertyName, 'value']);
}
function convertToValidEngagementAssociation(definition, associationTypeIdMap) {
  const toObjectTypeId = definition.associationDefinition.toObjectTypeId;
  const associationCategory = definition.associationDefinition.associationCategory;
  if (isLegacyHubSpotObject(toObjectTypeId) && associationCategory === 'USER_DEFINED') {
    const definitionMap = fromJS(definition);
    const newAssociationTypeId = associationTypeIdMap.get(toObjectTypeId);
    return definitionMap.setIn(['associationDefinition', 'associationCategory'], 'HUBSPOT_DEFINED').setIn(['associationDefinition', 'associationTypeId'], newAssociationTypeId).setIn(['associationDefinition', 'subjectAssociationCategory'], associationCategory).toJS();
  }
  return definition;
}
function getIsValidEngagementAssociation(definition) {
  const toObjectTypeId = definition.toObjectTypeId;
  const fromObjectTypeId = definition.fromObjectTypeId;
  const associationCategory = definition.associationCategory;
  const isToCompanyContactDealOrTicket = isObjectTypeIdCCDT(toObjectTypeId);
  const isFromCompanyContactDealOrTicket = isObjectTypeIdCCDT(fromObjectTypeId);
  const isToCobject =
  // @ts-expect-error TODO(TS): possible bug if toObjectTypeId is null
  isPortalSpecificObjectType(toObjectTypeId);
  // @ts-expect-error TODO(TS): possible bug if fromObjectTypeId is null
  const isFromCobject = isPortalSpecificObjectType(fromObjectTypeId);
  const isToAppObject = toObjectTypeId === null || toObjectTypeId === void 0 ? void 0 : toObjectTypeId.startsWith('1-');

  // This encompasses all flexible associations
  if (associationCategory === 'USER_DEFINED' && (isFromCompanyContactDealOrTicket || isFromCobject) && (isToCompanyContactDealOrTicket || isToCobject)) {
    return true;
  }
  if (isToCobject || isToAppObject) {
    // all associations TO portal-specific and app objects are valid for engagements
    // e.g. note --> vendor, task --> vendor, meeting --> vendor
    return true;
  }
  const isHubSpotDefinedAssociation = associationCategory === 'HUBSPOT_DEFINED';
  if (isHubSpotDefinedAssociation) {
    const isFromObjectTypeIdAllowed = isAllowedHubSpotDefinedObjectTypeId(fromObjectTypeId) || _isEngagementTypeId(fromObjectTypeId);
    const isToObjectTypeIdAllowed = isAllowedHubSpotDefinedObjectTypeId(toObjectTypeId) ||
    // toObjectType can only be an engagement if fromObjectType is an engagement
    // i.e. we allow CALL_TO_MEETING_EVENT and its inverse, but not DEAL_TO_CALL
    _isEngagementTypeId(fromObjectTypeId) && _isEngagementTypeId(toObjectTypeId);
    const isAssociationDefinitionAllowed = isAllowedAssociationDefinition(definition) && isFromObjectTypeIdAllowed && isToObjectTypeIdAllowed;
    let gates = [];
    try {
      const {
        gates: userGates
      } = userInfoSync();
      gates = userGates || [];
    } catch (e) {
      // userInfoSync can throw an error if not mocked in a test environment.
      // It should always be available in an app environment.
    }
    const isUngatedForAssociation = !REQUIRED_GATE_FOR_ASSOCIATION_TYPE_ID.has(`${definition.associationTypeId}`) || gates.includes(REQUIRED_GATE_FOR_ASSOCIATION_TYPE_ID.get(`${definition.associationTypeId}`));
    return isAssociationDefinitionAllowed && isUngatedForAssociation;
  }
  return false;
}
function getFormattedPrimaryAndSecondaryDisplayLabels({
  toObjectTypeId,
  primaryDisplayLabel,
  secondaryDisplayLabels,
  properties
}) {
  if (ObjectTypeFromIds[toObjectTypeId] === CONTACT) {
    var _properties$find, _properties$find2;
    const firstName = primaryDisplayLabel;
    // @ts-expect-error TODO(TS): possible bug if secondaryDisplayLabels is null
    const lastName = secondaryDisplayLabels[0];
    // @ts-expect-error TODO(TS): possible bug if secondaryDisplayLabels is null
    const email = secondaryDisplayLabels[1];
    const formattedName = formatName({
      firstName,
      lastName
    });
    const emailIfContact = properties === null || properties === void 0 || (_properties$find = properties.find(property => property.name === 'email')) === null || _properties$find === void 0 ? void 0 : _properties$find.value;
    const legalBasis = properties === null || properties === void 0 || (_properties$find2 = properties.find(property => property.name === 'hs_legal_basis')) === null || _properties$find2 === void 0 ? void 0 : _properties$find2.value;
    const secondaryDisplayLabel = email ? unescapedText('universalAssociationsSelect.optionFormatting.emailWrapper', {
      secondaryDisplayLabel: email
    }) : undefined;
    return {
      emailIfContact,
      legalBasis,
      primaryDisplayLabel: formattedName || NULL_LABEL_VALUE,
      secondaryDisplayLabel
    };
  }
  if (ObjectTypeFromIds[toObjectTypeId] === TICKET) {
    // @ts-expect-error TODO(TS): possible bug if properties is null
    const content = properties.find(property => property.name === 'content');
    const secondaryDisplayLabel = content && content.value ? unescapedText('universalAssociationsSelect.optionFormatting.parentheses', {
      secondaryDisplayLabel: content.value
    }) : undefined;
    return {
      emailIfContact: undefined,
      legalBasis: undefined,
      primaryDisplayLabel: primaryDisplayLabel || NULL_LABEL_VALUE,
      secondaryDisplayLabel
    };
  }
  if (toObjectTypeId === MEETING_EVENT_TYPE_ID) {
    const secondaryDisplayLabel =
    // @ts-expect-error TODO(TS): possible bug if secondaryDisplayLabels is null
    secondaryDisplayLabels[0] &&
    // @ts-expect-error TODO(TS): possible bug if secondaryDisplayLabels is null
    creationDateGetter(Number(secondaryDisplayLabels[0]));
    return {
      emailIfContact: undefined,
      legalBasis: undefined,
      primaryDisplayLabel: primaryDisplayLabel || unescapedText('universalAssociationsSelect.emptyDisplayLabel.loggedMeeting'),
      secondaryDisplayLabel: secondaryDisplayLabel ? unescapedText('universalAssociationsSelect.optionFormatting.parentheses', {
        secondaryDisplayLabel
      }) : undefined
    };
  }
  if (toObjectTypeId === CALL_TYPE_ID) {
    // @ts-expect-error TODO(TS): possible bug if properties is null
    const callCreateDate = properties.find(property => property.name === CALL_START_TIME);
    const secondaryDisplayLabel = callCreateDate && creationDateGetter(Number(callCreateDate.value));
    return {
      emailIfContact: undefined,
      legalBasis: undefined,
      primaryDisplayLabel: primaryDisplayLabel || NULL_LABEL_VALUE,
      secondaryDisplayLabel: secondaryDisplayLabel ? unescapedText('universalAssociationsSelect.optionFormatting.parentheses', {
        secondaryDisplayLabel,
        defaultValue: ''
      }) : ''
    };
  }
  const hasSecondaryDisplayLabel = ObjectTypeFromIds[toObjectTypeId] !== DEAL && secondaryDisplayLabels && !!secondaryDisplayLabels.length && !!secondaryDisplayLabels[0];
  const secondaryDisplayLabel = hasSecondaryDisplayLabel ? unescapedText('universalAssociationsSelect.optionFormatting.parentheses', {
    secondaryDisplayLabel: secondaryDisplayLabels[0]
  }) : undefined;
  return {
    emailIfContact: undefined,
    legalBasis: undefined,
    primaryDisplayLabel: primaryDisplayLabel || NULL_LABEL_VALUE,
    secondaryDisplayLabel
  };
}
function getObjectDisplayLabelGettersAndObjectName({
  associationCategory,
  associationTypeId,
  pluralForm,
  singularForm,
  primaryDisplayLabelPropertyName,
  secondaryDisplayLabelPropertyNames,
  toObjectTypeId
}) {
  switch (toObjectTypeId) {
    case CONTACT_TYPE_ID:
      {
        const contactPrimaryDisplayLabelGetter = optionRecord => {
          const firstName = propertyValueGetter('firstname')(optionRecord);
          const lastName = propertyValueGetter('lastname')(optionRecord);
          return formatName({
            firstName,
            lastName
          }) || NULL_LABEL_VALUE;
        };
        return {
          associationCategory: 'HUBSPOT_DEFINED',
          associationTypeId,
          pluralObjectName: pluralForm,
          singularObjectName: singularForm,
          primaryDisplayLabelGetter: contactPrimaryDisplayLabelGetter,
          secondaryDisplayLabelGetter: propertyValueGetter('email'),
          toObjectType: CONTACT,
          cardinality: undefined,
          legalBasis: propertyValueGetter('hs_legal_basis')
        };
      }
    case COMPANY_TYPE_ID:
      return {
        associationCategory: 'HUBSPOT_DEFINED',
        associationTypeId,
        pluralObjectName: pluralForm,
        singularObjectName: singularForm,
        primaryDisplayLabelGetter: primaryDisplayLabelGetterWithDefault('name'),
        secondaryDisplayLabelGetter: propertyValueGetter('domain'),
        toObjectType: COMPANY,
        cardinality: undefined
      };
    case DEAL_TYPE_ID:
      return {
        associationCategory: 'HUBSPOT_DEFINED',
        associationTypeId,
        pluralObjectName: pluralForm,
        singularObjectName: singularForm,
        primaryDisplayLabelGetter: primaryDisplayLabelGetterWithDefault('dealname'),
        secondaryDisplayLabelGetter: undefined,
        toObjectType: DEAL,
        cardinality: undefined
      };
    case TICKET_TYPE_ID:
      return {
        associationCategory: 'HUBSPOT_DEFINED',
        associationTypeId,
        pluralObjectName: pluralForm,
        singularObjectName: singularForm,
        primaryDisplayLabelGetter: primaryDisplayLabelGetterWithDefault('subject'),
        secondaryDisplayLabelGetter: propertyValueGetter('content'),
        toObjectType: TICKET,
        cardinality: undefined
      };
    case MEETING_EVENT_TYPE_ID:
      {
        return {
          associationCategory: 'HUBSPOT_DEFINED',
          associationTypeId,
          pluralObjectName: pluralForm,
          singularObjectName: singularForm,
          primaryDisplayLabelGetter: primaryDisplayLabelGetterWithDefault(
          // @ts-expect-error TODO(TS): possible bug if primaryDisplayLabelPropertyName is null
          primaryDisplayLabelPropertyName),
          secondaryDisplayLabelGetter: properties => {
            return creationDateGetter(propertyValueGetter(MEETING_START_TIME)(properties));
          },
          toObjectType: ENGAGEMENT,
          cardinality: undefined
        };
      }
    case CALL_TYPE_ID:
      {
        return {
          associationCategory: 'HUBSPOT_DEFINED',
          associationTypeId,
          pluralObjectName: pluralForm,
          singularObjectName: singularForm,
          primaryDisplayLabelGetter: primaryDisplayLabelGetterWithDefault(
          // @ts-expect-error TODO(TS): possible bug if primaryDisplayLabelPropertyName is null
          primaryDisplayLabelPropertyName),
          secondaryDisplayLabelGetter: properties => {
            return creationDateGetter(propertyValueGetter(CALL_START_TIME)(properties));
          },
          toObjectType: ENGAGEMENT,
          cardinality: undefined
        };
      }
    case CAMPAIGN_TYPE_ID:
      {
        return {
          associationCategory: 'HUBSPOT_DEFINED',
          associationTypeId,
          pluralObjectName: pluralForm,
          singularObjectName: singularForm,
          primaryDisplayLabelGetter: primaryDisplayLabelGetterWithDefault(
          // @ts-expect-error TODO(TS): possible bug if primaryDisplayLabelPropertyName is null
          primaryDisplayLabelPropertyName),
          secondaryDisplayLabelGetter: undefined,
          toObjectType: CAMPAIGN,
          cardinality: 'ONE_TO_ONE'
        };
      }
    default:
      {
        // Custom object
        const secondaryDisplayLabelGetter = secondaryDisplayLabelPropertyNames && secondaryDisplayLabelPropertyNames.length ? propertyValueGetter(secondaryDisplayLabelPropertyNames[0]) : undefined;
        return {
          associationCategory,
          associationTypeId,
          pluralObjectName: pluralForm,
          singularObjectName: singularForm,
          primaryDisplayLabelGetter: primaryDisplayLabelGetterWithDefault(
          // @ts-expect-error TODO(TS): possible bug if primaryDisplayLabelPropertyName is null
          primaryDisplayLabelPropertyName),
          secondaryDisplayLabelGetter
        };
      }
  }
}
const getUniversalAssociationRecord = memoize(({
  associationCategory: associationDefinitionCategory,
  associationTypeId: associationDefinitionTypeId,
  toObjectTypeDefinition,
  toObjectTypeId
}) => {
  const {
    associationCategory,
    associationTypeId,
    pluralObjectName,
    singularObjectName,
    primaryDisplayLabelGetter,
    secondaryDisplayLabelGetter,
    toObjectType,
    cardinality
  } = getObjectDisplayLabelGettersAndObjectName({
    toObjectTypeId,
    primaryDisplayLabelPropertyName: toObjectTypeDefinition === null || toObjectTypeDefinition === void 0 ? void 0 : toObjectTypeDefinition.primaryDisplayLabelPropertyName,
    secondaryDisplayLabelPropertyNames: toObjectTypeDefinition === null || toObjectTypeDefinition === void 0 ? void 0 : toObjectTypeDefinition.secondaryDisplayLabelPropertyNames,
    pluralForm: toObjectTypeDefinition === null || toObjectTypeDefinition === void 0 ? void 0 : toObjectTypeDefinition.pluralForm,
    singularForm: toObjectTypeDefinition === null || toObjectTypeDefinition === void 0 ? void 0 : toObjectTypeDefinition.singularForm,
    associationCategory: associationDefinitionCategory,
    associationTypeId: associationDefinitionTypeId
  });
  return UniversalAssociationRecord({
    associationCategory,
    associationTypeId,
    toObjectTypeId,
    singularObjectName,
    pluralObjectName,
    primaryDisplayLabelGetter,
    secondaryDisplayLabelGetter,
    toObjectType,
    cardinality
  });
});
function getSubjectAssociationRecord(crmObject, subjectObjectId, subjectObjectTypeId, isExistingEngagement) {
  // We only build the subject association if the crmObject is the subject
  // @ts-expect-error TODO(TS): possible bug if subjectObjectId is undefined (we end up comparing number !== NaN, so we always return an empty map - maybe we could just make that explicit?)
  if (crmObject.id !== +subjectObjectId) {
    return ImmutableMap();
  }
  const subjectRecord = getUniversalAssociationRecord({
    toObjectTypeDefinition: crmObject.objectTypeDefinition,
    // @ts-expect-error TODO(TS): possible bug if subjectObjectTypeId is undefined, but we would have failed the subjectObjectId check above already
    toObjectTypeId: subjectObjectTypeId
  });
  const {
    emailIfContact,
    legalBasis,
    primaryDisplayLabel,
    secondaryDisplayLabel
  } = getFormattedPrimaryAndSecondaryDisplayLabels({
    // @ts-expect-error TODO(TS): possible bug if subjectObjectTypeId is undefined, but we would have failed the subjectObjectId check above already
    toObjectTypeId: subjectObjectTypeId,
    primaryDisplayLabel: crmObject.primaryDisplayLabel,
    secondaryDisplayLabels: crmObject.secondaryDisplayLabels,
    properties: crmObject.properties
  });

  // Whether or not the subject is selected is only relevant when we are evaluating
  // a new engagement. Otherwise that information is pulled from the engagement itself.
  const subjectOption = OrderedMap({
    // @ts-expect-error TODO(TS): possible bug if subjectObjectId is undefined (see above - we should bail out early)
    [subjectObjectId]: UniversalAssociationOptionRecord({
      emailIfContact,
      legalBasis,
      isDefaultAssociation: true,
      isSelected: !isExistingEngagement,
      // @ts-expect-error TODO(TS): possible bug if subjectObjectId is undefined (see above - we should bail out early)
      objectId: +subjectObjectId,
      objectTypeId: subjectObjectTypeId,
      primaryDisplayLabel,
      secondaryDisplayLabel,
      currentUserCanCommunicate: crmObject.userPermissions ? crmObject.userPermissions.currentUserCanCommunicate : undefined
    })
  });
  return ImmutableMap({
    // @ts-expect-error TODO(TS): possible bug if subjectObjectTypeId is undefined, but we would have failed the subjectObjectId check above already
    [subjectObjectTypeId]: subjectRecord.set('associationOptionRecords', subjectOption)
  });
}
const getShouldPreselectDefaultAssociationOnLegacyHubspotObject = ({
  isPrimary,
  isSubjectCrmObject,
  engagementId,
  preselectedDefaultDealCount,
  properties,
  subjectAssociationCategory,
  subjectObjectTypeId,
  toObjectTypeId,
  autoAssociationsData
}) => {
  if (!isSubjectCrmObject) {
    return false;
  }

  // If running auto-associations behavior, use auto-associations data to pre-select association records
  if (autoAssociationsData) {
    const autoAssociations = autoAssociationsData.autoAssociations[toObjectTypeId];
    return autoAssociations ? autoAssociationsData.autoAssociations[toObjectTypeId].some(autoAssociation => autoAssociation.objectId === engagementId && autoAssociation.canUserView) : false;
  }

  // If not running auto-associations behavior, these are the supported subject object types for association pre-selection
  const isSubjectLegacyHubspotObject = subjectObjectTypeId && [CONTACT_TYPE_ID, COMPANY_TYPE_ID, DEAL_TYPE_ID, TICKET_TYPE_ID].includes(subjectObjectTypeId);

  // Below is a link to the spread sheet where we define our expected behaviors for default associations and preselecting.
  // There are no general rules for contacts or tickets that can be applied here.
  // https://docs.google.com/spreadsheets/d/1iUzusStqf2u8c7BG9Yre76AfZKRsv9t4UCsxNlZTwqU/edit#gid=913464275
  const isToObjectTypeIdContactOrTicket = [CONTACT_TYPE_ID, TICKET_TYPE_ID].includes(toObjectTypeId);
  if (!isSubjectLegacyHubspotObject || isToObjectTypeIdContactOrTicket) {
    return false;
  }

  // Ensures that only primary companies are selected by default
  // and not flexible associated companies.
  if (toObjectTypeId === COMPANY_TYPE_ID && subjectAssociationCategory !== 'USER_DEFINED' && isPrimary) {
    return true;
  }
  if (subjectObjectTypeId === TICKET_TYPE_ID) {
    return false;
  }
  const isDealOpen = properties && properties.find(property => property.name === 'hs_is_closed' && property.value === 'false');
  return !!isDealOpen && preselectedDefaultDealCount < 5;
};
const getIsDefaultAssociationOptionSelected = ({
  crmObjectId,
  isExistingEngagement,
  isPrimary,
  node,
  preselectedDefaultDealCount,
  subjectAssociationCategory,
  subjectObjectId,
  subjectObjectTypeId,
  toObjectTypeId,
  autoAssociationsData
}) => {
  if (isExistingEngagement || node.userPermissions && !node.userPermissions.currentUserCanCommunicate) {
    return false;
  }

  // @ts-expect-error TODO(TS): possible bug if subjectObjectId is undefined (we end up comparing number === NaN, so isSubjectCrmObject will be false - maybe we could just make that explicit?)
  const isSubjectCrmObject = crmObjectId === +subjectObjectId;
  const shouldPreselectOnLegacyHubSpotObjects = getShouldPreselectDefaultAssociationOnLegacyHubspotObject({
    isPrimary,
    isSubjectCrmObject,
    engagementId: node.id,
    subjectObjectTypeId,
    toObjectTypeId,
    properties: node.properties,
    preselectedDefaultDealCount,
    subjectAssociationCategory,
    autoAssociationsData
  });
  return shouldPreselectOnLegacyHubSpotObjects;
};
const getShouldHideOption = ({
  node
}) => !!node.userPermissions && !node.userPermissions.currentUserCanView;
const getUniversalAssociationOptionRecord = ({
  toObjectTypeId,
  node,
  isDefaultAssociation,
  isSelected
}) => {
  const {
    emailIfContact,
    legalBasis,
    primaryDisplayLabel,
    secondaryDisplayLabel
  } = getFormattedPrimaryAndSecondaryDisplayLabels({
    toObjectTypeId,
    primaryDisplayLabel: node.primaryDisplayLabel,
    secondaryDisplayLabels: node.secondaryDisplayLabels,
    properties: node.properties
  });
  return UniversalAssociationOptionRecord({
    emailIfContact,
    legalBasis,
    isDefaultAssociation,
    isSelected,
    objectId: node.id,
    objectTypeId: toObjectTypeId,
    primaryDisplayLabel,
    secondaryDisplayLabel,
    currentUserCanCommunicate: node.userPermissions ? node.userPermissions.currentUserCanCommunicate : undefined
  });
};

// Format an association record for engagement associations
// (aka associations that are already selected)
const getUniversalAssociationRecordWithSelectedOptions = memoize(({
  associatedObjects,
  associationRecord
}) => {
  if (!associatedObjects) {
    return associationRecord;
  }
  const {
    edges
  } = associatedObjects;
  const toObjectTypeId = associationRecord.get('toObjectTypeId');
  const options = edges.reduce((acc, {
    node
  }) => {
    // skip any association options the user does not have permission to view
    if (getShouldHideOption({
      node
    })) {
      return acc;
    }
    const optionRecord = getUniversalAssociationOptionRecord({
      toObjectTypeId,
      node,
      isSelected: true,
      isDefaultAssociation: false
    });
    const id = `${node.id}`;
    return acc.set(id, optionRecord);
  }, OrderedMap());
  const isSelectedAToZSortedOptions = options.sort(universalAssociationOptionComparator);
  return associationRecord.set('associationOptionRecords', isSelectedAToZSortedOptions);
});

// Format an association record for all other associations
const getUniversalAssociationRecordWithAllOptions = memoize(({
  associatedObjects,
  associationRecord,
  crmObjectId,
  engagementAssociationRecords,
  isExistingEngagement,
  isPrimary,
  subjectAssociationCategory,
  subjectObjectId,
  subjectObjectTypeId,
  autoAssociationsData
}) => {
  if (!associatedObjects) {
    return associationRecord;
  }
  const {
    edges
  } = associatedObjects;
  const toObjectTypeId = associationRecord.get('toObjectTypeId');
  // We will need this to mark previously selected associations as default associations.
  let engagementAssociationRecordsToReturn = engagementAssociationRecords;
  // Helps determine whether deal options should be preselected when creating an engagement.
  let preselectedDefaultDealCount = 0;

  // default options are the objects associated with the engagement's subject,
  // but are not necessarily selected
  const defaultOptions = edges.reduce((acc, {
    node
  }) => {
    // Skip any association options the user does not have permission to view
    if (getShouldHideOption({
      node
    })) {
      return acc;
    }

    // If the option is already available in engagementAssociationRecordsToReturn,
    // that means it's already been selected and that an UniversalAssociationOptionRecord
    // already exists for it. We do not want to generate a duplicate
    // UniversalAssociationOptionRecord, so we skip it and mark the existing
    // UniversalAssociationOptionRecord as a default association.
    const pathToOption = [toObjectTypeId, 'associationOptionRecords', `${node.id}`];
    const isAlreadyAssociated = engagementAssociationRecordsToReturn.getIn([...pathToOption, 'isSelected']);
    if (isAlreadyAssociated) {
      engagementAssociationRecordsToReturn = engagementAssociationRecordsToReturn.setIn([...pathToOption, 'isDefaultAssociation'], true);
      if (toObjectTypeId === DEAL_TYPE_ID) preselectedDefaultDealCount++;
      return acc;
    }

    // We only evaluate whether or not the default association
    // should be preselected when creating a new engagement.
    const isSelected = getIsDefaultAssociationOptionSelected({
      crmObjectId,
      isExistingEngagement,
      isPrimary,
      node,
      preselectedDefaultDealCount,
      subjectAssociationCategory,
      subjectObjectId,
      subjectObjectTypeId,
      toObjectTypeId,
      autoAssociationsData
    });
    if (isSelected && toObjectTypeId === DEAL_TYPE_ID) {
      preselectedDefaultDealCount++;
    }
    const optionRecord = getUniversalAssociationOptionRecord({
      toObjectTypeId,
      node,
      isSelected,
      isDefaultAssociation: true
    });
    const id = `${node.id}`;
    return acc.set(id, optionRecord);
  }, OrderedMap());
  const selectedAssociationOptionRecords = engagementAssociationRecordsToReturn.getIn([toObjectTypeId, 'associationOptionRecords']);
  const existingDefaultAssociationOptionRecords = associationRecord.get('associationOptionRecords');

  // We want to combine and sort the selected engagement associations and the
  // default associations.
  const sortedOptions = defaultOptions.mergeDeepWith((oldValue, newValue) => newValue || oldValue, existingDefaultAssociationOptionRecords, selectedAssociationOptionRecords).sort(universalAssociationOptionComparator);
  return associationRecord.set('associationOptionRecords', sortedOptions);
});
const getRecordsFromAssociationDefinitions = memoize(allAssociationTypesFromObjectType => {
  if (!allAssociationTypesFromObjectType) {
    return ImmutableMap();
  }
  const validAssociationDefinitions = allAssociationTypesFromObjectType.filter(getIsValidEngagementAssociation);
  return ImmutableMap(validAssociationDefinitions.map(associationTypeFromObject => {
    const associationRecord = getUniversalAssociationRecord(associationTypeFromObject);
    return [associationRecord.get('toObjectTypeId'), associationRecord];
  }));
});

// Retrieves and parses the association records that are already selected/associated
const getEngagementAssociationRecords = memoize(engagement => {
  if (!engagement || !engagement.allAssociations) {
    return ImmutableMap();
  }
  const validAssociations = engagement.allAssociations.filter(({
    associationDefinition
  }) => getIsValidEngagementAssociation(associationDefinition));
  return ImmutableMap(validAssociations.map(({
    associationDefinition,
    associatedObjects
  }) => {
    const associationRecord = getUniversalAssociationRecord(associationDefinition);
    const associationRecordWithOptions = getUniversalAssociationRecordWithSelectedOptions({
      associatedObjects,
      associationRecord
    });
    return [associationRecordWithOptions.get('toObjectTypeId'), associationRecordWithOptions];
  }));
});

// Retrieves/parses all association records
const getAllAssociationRecords = memoize((crmObject, subjectObjectId, subjectObjectTypeId, isExistingEngagement, associationRecordsFromDefinitions, engagementAssociationRecords, associationTypeIdMap = ImmutableMap(), autoAssociationsData) => {
  // no subject is passed in for tasks, for example, so we just
  // want to return existing associations or the associations from definitions
  if (!crmObject) {
    if (associationRecordsFromDefinitions) {
      return associationRecordsFromDefinitions.mergeDeepWith((oldValue, newValue) => newValue || oldValue, engagementAssociationRecords);
    }
    if (engagementAssociationRecords) return engagementAssociationRecords;
    return ImmutableMap();
  }
  // @ts-expect-error TODO(TS): possible bug if allAssociations is null
  const validAssociations = crmObject.allAssociations.filter(({
    associationDefinition
  }) => getIsValidEngagementAssociation(associationDefinition));
  const formattedValidAssociations = validAssociations.map(definition => convertToValidEngagementAssociation(definition, associationTypeIdMap));

  // Subject association isSelected if !isExistingEngagement
  const subjectAssociation = getSubjectAssociationRecord(crmObject, subjectObjectId, subjectObjectTypeId, isExistingEngagement);
  const associations = formattedValidAssociations.reduce((acc, {
    associationDefinition,
    associatedObjects
  }) => {
    const toObjectTypeId = associationDefinition.toObjectTypeId;
    // @ts-expect-error TODO(TS): possible bug if toObjectTypeId is null
    const existingRecord = acc.get(toObjectTypeId);
    const associationRecord = existingRecord || getUniversalAssociationRecord(associationDefinition);
    const associationRecordWithOptions = getUniversalAssociationRecordWithAllOptions({
      associatedObjects,
      associationRecord,
      crmObjectId: crmObject.id,
      engagementAssociationRecords,
      isExistingEngagement,
      isPrimary: !!associationDefinition.isPrimary,
      subjectAssociationCategory:
      // @ts-expect-error TODO(TS): `associationDefinition` does not have a `subjectAssociationCategory` field?
      associationDefinition.subjectAssociationCategory,
      subjectObjectId,
      subjectObjectTypeId,
      // @ts-expect-error TODO(TS): possible bug if toObjectTypeId is null
      toObjectTypeId,
      autoAssociationsData
    });

    // @ts-expect-error TODO(TS): possible bug if toObjectTypeId is null
    return acc.set(toObjectTypeId, associationRecordWithOptions);
  }, ImmutableMap());
  return associations
  // Merging these records provides more complete
  // association data.
  .mergeDeepWith((oldValue, newValue) => newValue || oldValue, associationRecordsFromDefinitions, engagementAssociationRecords, subjectAssociation);
});

// formats data needed for UAS association parser helpers
const getParserData = ({
  allAssociationTypesFromObjectType,
  engagement
}) => {
  // If an engagement exists, it is not currently being created in the communicator
  const isExistingEngagement = !!engagement;

  // creates a map of all possible association type ids for this engagement
  // organized as { [fromObjectTypeId]: { [toObjectTypeId]: ### } }.
  // This is needed to convert flexible associations to engagement associations.
  const associationTypeIdMap = engagement && engagement.allAssociations ? engagement.allAssociations.reduce((acc, {
    associationDefinition
  }) => {
    const {
      fromObjectTypeId,
      toObjectTypeId,
      associationTypeId
    } = associationDefinition;
    return acc.setIn([fromObjectTypeId, toObjectTypeId], associationTypeId);
  }, ImmutableMap()) : ImmutableMap();
  const associationRecordsFromDefinitions = getRecordsFromAssociationDefinitions(allAssociationTypesFromObjectType);

  // The records already associated with the given engagement.
  // These are the records with associations already "checked"
  const engagementAssociationRecords = getEngagementAssociationRecords(engagement);
  return {
    isExistingEngagement,
    associationTypeIdMap,
    associationRecordsFromDefinitions,
    engagementAssociationRecords
  };
};
const getAllAutoAssociations = (allAssociations, autoAssociationsData) => {
  // Generates and appends options for each auto-association that we don't have associations data for
  // to ensure that they are included even if we failed to fetch their data
  return allAssociations.reduce((acc, associationRecord) => {
    const toObjectTypeId = associationRecord.get('toObjectTypeId');
    const autoAssociations = autoAssociationsData ? autoAssociationsData.autoAssociations[toObjectTypeId] : null;
    if (autoAssociations) {
      const associationOptionRecords = associationRecord.get('associationOptionRecords');
      const autoAssociationOptionRecords = autoAssociations.reduce((autoAcc, autoAssociation) => {
        const associationOptionRecord = associationOptionRecords.get(`${autoAssociation.objectId}`);
        if (!associationOptionRecord && autoAssociation.hydratedAutoAssociatedObject) {
          var _autoAssociation$hydr;
          const legalBasis = (_autoAssociation$hydr = autoAssociation.hydratedAutoAssociatedObject.properties.find(property => property.name === 'hs_legal_basis')) === null || _autoAssociation$hydr === void 0 ? void 0 : _autoAssociation$hydr.value;
          return autoAcc.set(`${autoAssociation.objectId}`, getUniversalAssociationOptionRecord({
            toObjectTypeId,
            node: {
              id: autoAssociation.objectId,
              primaryDisplayLabel: autoAssociation.hydratedAutoAssociatedObject.primaryDisplayLabel,
              secondaryDisplayLabels: autoAssociation.hydratedAutoAssociatedObject.secondaryDisplayLabels,
              // @ts-expect-error we're constructing a fake `node`, properties is missing the `__typename` field that the GQL client-types expect; this should be safe to ignore!
              properties: autoAssociation.hydratedAutoAssociatedObject.properties,
              // @ts-expect-error TODO(TS): possible bug - we should be providing `currentUserCanView`? autoAssociation seems to have a `canUserView` field - is that the same thing?
              userPermissions: {
                currentUserCanCommunicate: autoAssociation.currentUserCanCommunicate
              }
            },
            isSelected: autoAssociation.canUserView,
            isDefaultAssociation: true,
            legalBasis
          }));
        }
        return autoAcc;
      }, associationOptionRecords);
      return acc.setIn([toObjectTypeId, 'associationOptionRecords'], associationOptionRecords.mergeDeepWith((oldValue, newValue) => newValue || oldValue, autoAssociationOptionRecords));
    }
    return acc.set(toObjectTypeId, associationRecord);
  }, allAssociations);
};
function parseUniversalEngagementAssociations({
  data: {
    allAssociationTypesFromObjectType,
    engagement,
    subject
  },
  variables
}) {
  const {
    subjectObjectId,
    subjectObjectTypeId
  } = variables !== null && variables !== void 0 ? variables : {};
  const {
    isExistingEngagement,
    associationTypeIdMap,
    associationRecordsFromDefinitions,
    engagementAssociationRecords
  } = getParserData({
    allAssociationTypesFromObjectType,
    engagement
  });

  // The combined result of the engagement associations,
  // the default associations, and the association records
  // from definitions.
  const allAssociations = getAllAssociationRecords(subject,
  // @ts-expect-error TODO(TS): possible bug if subjectObjectId is a number
  subjectObjectId, subjectObjectTypeId, isExistingEngagement, associationRecordsFromDefinitions, engagementAssociationRecords, associationTypeIdMap, null);
  return sortBy(association => association.get('pluralObjectName'), allAssociations);
}
const useParseAutoAssociations = ({
  engagementOrInteractionType,
  subjectObjectId,
  subjectObjectTypeId,
  shouldPreselectConfiguredAutoAssociations = false,
  apiClient = http
}) => {
  const isSupportedSubjectObjectType = subjectObjectTypeId && (isPortalSpecificObjectType(subjectObjectTypeId) || AUTO_ASSOCIATIONS_HUBSPOT_DEFINED_OBJECT_TYPE_ID_ALLOWLIST.includes(subjectObjectTypeId));
  const {
    data: associationsData,
    loading: associationsLoading,
    error: associationsError
  } = useFetchUniversalEngagementAssociations({
    engagementOrInteractionType,
    fetchPolicyOverride: 'cache-and-network',
    // @ts-expect-error TODO(TS): possible bug if subjectObjectTypeId is undefined (but in that case we will `skip`)
    objectTypeId: subjectObjectTypeId,
    // @ts-expect-error TODO(TS): possible bug if subjectObjectId is a number or undefined (but in that case we will `skip`)
    subjectId: subjectObjectId,
    skip: Boolean(!subjectObjectTypeId || !subjectObjectId || !engagementOrInteractionType)
  });
  const clearAllCachedValues = useCallback(() => {
    // Nothing to do; here for backwards-compatibility
  }, []);
  const allAssociationTypesFromObjectType = associationsData === null || associationsData === void 0 ? void 0 : associationsData.allAssociationTypesFromObjectType;
  const engagement = associationsData === null || associationsData === void 0 ? void 0 : associationsData.engagement;
  const subject = associationsData === null || associationsData === void 0 ? void 0 : associationsData.subject;
  const {
    isExistingEngagement,
    associationTypeIdMap,
    associationRecordsFromDefinitions,
    engagementAssociationRecords
  } = getParserData({
    allAssociationTypesFromObjectType,
    engagement
  });
  const {
    data: autoAssociationsData,
    loading: autoAssociationsLoading,
    error: autoAssociationsError
  } = useFetchAutoAssociations({
    subjectObjectTypeId,
    // @ts-expect-error TODO(TS): possible bug if subjectObjectId is a number
    subjectObjectId,
    engagementType: engagementOrInteractionType,
    apiClient,
    skip: Boolean(!shouldPreselectConfiguredAutoAssociations || !isSupportedSubjectObjectType || !subjectObjectTypeId || !subjectObjectId || !engagementOrInteractionType)
  });
  const {
    neverLogList,
    neverLogListLoading
  } = useFetchNeverLogList({
    engagementOrInteractionType,
    apiClient
  });
  if (!shouldPreselectConfiguredAutoAssociations) {
    return {
      parsedAutoAssociatedRecords: OrderedMap(),
      loading: false,
      error: false,
      clearAllCachedValues
    };
  } else if (!isSupportedSubjectObjectType) {
    // TODO: Clean up how `parseUniversalEngagementAssociations` handles missing values
    // Issue: https://git.hubteam.com/HubSpot/CreateAndAssociateFrontendTeam/issues/492

    // if there is a never log list error, we will just ignore the never log list
    const isReadyToParse = associationsData && !associationsLoading && !associationsError && !neverLogListLoading && subjectObjectId && subjectObjectTypeId;
    return {
      parsedAutoAssociatedRecords: isReadyToParse ? enforceNeverLogList({
        parsedAssociations: parseUniversalEngagementAssociations({
          data: associationsData,
          variables: {
            subjectObjectId,
            subjectObjectTypeId
          }
        }),
        neverLogList
      }) : OrderedMap(),
      loading: !isReadyToParse,
      error: Boolean(associationsError),
      clearAllCachedValues
    };
  }

  // The combined result of the engagement associations,
  // the default associations, and the association records
  // from definitions.
  const allAssociations = getAllAssociationRecords(subject,
  // @ts-expect-error TODO(TS): possible bug if subjectObjectId is a number
  subjectObjectId, subjectObjectTypeId, isExistingEngagement, associationRecordsFromDefinitions, engagementAssociationRecords, associationTypeIdMap, autoAssociationsData === null || autoAssociationsData === void 0 ? void 0 : autoAssociationsData.autoAssociationsData);
  const allAutoAssociations = enforceNeverLogList({
    parsedAssociations: getAllAutoAssociations(allAssociations, autoAssociationsData === null || autoAssociationsData === void 0 ? void 0 : autoAssociationsData.autoAssociationsData),
    neverLogList
  });

  // if there is a never log list error, we will just ignore the never log list
  const error = Boolean(associationsError || autoAssociationsError);
  const loading = associationsLoading || autoAssociationsLoading || neverLogListLoading;
  const parsedAutoAssociatedRecords = loading || error ? OrderedMap() : sortBy(association => association.get('pluralObjectName'), allAutoAssociations);
  return {
    parsedAutoAssociatedRecords,
    loading,
    error,
    // So far only needed in calling-widget-ui to address the UAS never truly unmounting
    clearAllCachedValues
  };
};
export { getAllAutoAssociations, getAllAssociationRecords, getEngagementAssociationRecords, getFormattedPrimaryAndSecondaryDisplayLabels, getIsDefaultAssociationOptionSelected, getIsValidEngagementAssociation, getObjectDisplayLabelGettersAndObjectName, getParserData, getRecordsFromAssociationDefinitions, getShouldHideOption, getShouldPreselectDefaultAssociationOnLegacyHubspotObject, getSubjectAssociationRecord, getUniversalAssociationOptionRecord, getUniversalAssociationRecord, getUniversalAssociationRecordWithAllOptions, getUniversalAssociationRecordWithSelectedOptions, CCDT_TYPES, HUBSPOT_DEFINED_OBJECT_TYPE_ID_ALLOWLIST, AUTO_ASSOCIATIONS_HUBSPOT_DEFINED_OBJECT_TYPE_ID_ALLOWLIST, parseUniversalEngagementAssociations, useParseAutoAssociations, primaryDisplayLabelGetterWithDefault, propertyValueGetter, universalAssociationOptionComparator, convertToValidEngagementAssociation };