import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import getMessageIdFromLocation from '../common-message/operators/getMessageIdFromLocation';
export const useDeepLinkHighlight = ({
  messageId,
  highlight = false
}) => {
  const location = useLocation();
  const deepLinkedMessageId = getMessageIdFromLocation(location);
  const isMessageDeepLinked = deepLinkedMessageId === messageId;
  const [clearDeepLinkHighlight, setClearDeepLinkHighlight] = useState(false);
  const shouldHighlightDeepLinkedMessage = isMessageDeepLinked && !clearDeepLinkHighlight;
  const shouldHighlight = highlight || shouldHighlightDeepLinkedMessage;

  // TODO: improve a11y by making the container focusable, see this issue: https://git.hubteam.com/HubSpot/conversations-inbox-message-history/issues/1884
  useEffect(() => {
    const onClickRemoveHighlight = () => setClearDeepLinkHighlight(true);
    if (isMessageDeepLinked) {
      // timeout is needed to ensure the click event is not immediately triggered
      setTimeout(() => window.addEventListener('click', onClickRemoveHighlight, {
        once: true
      }));
    }
    return () => window.removeEventListener('click', onClickRemoveHighlight);
  }, [isMessageDeepLinked]);
  return shouldHighlight;
};