import _isEqual from 'hs-lodash/isEqual';
import get from 'hs-lodash/get';
export function isImmutable(value) {
  return value && typeof value === 'object' && 'setIn' in value && typeof value.setIn === 'function';
}

// Uses of this function can be replaced with .length once we remove Immutable.js
export function getLength(list) {
  if (isImmutable(list)) {
    return list.size;
  }
  return list.length;
}

// Uses of this function can be replaced with [0] once we remove Immutable.js
export function getFirst(value) {
  if (isImmutable(value)) {
    return value.first();
  }
  return value[0];
}

// Uses of this function can be replaced with value[key] once we remove Immutable.js
export const getValue = (value, keys) => {
  if (isImmutable(value)) {
    return value.getIn(Array.isArray(keys) ? keys : [keys]);
  }
  return get(value, keys);
};

// Uses of this function can be replaced with .isEqual() once we remove Immutable.js
export function isEqual(value1, value2) {
  if (isImmutable(value1) && isImmutable(value2)) {
    return value1.equals(value2);
  }
  return _isEqual(value1, value2);
}
export function isImmutableDeal(deal) {
  return isImmutable(deal);
}
export function isImmutableContact(contact) {
  return isImmutable(contact);
}
export function isImmutableContacts(contacts) {
  return isImmutable(contacts);
}
export function isImmutableCompany(company) {
  return isImmutable(company);
}
export function isImmutableAdditionalFees(additionalFees) {
  return isImmutable(additionalFees);
}
export function isImmutableQuoteSigner(contactSigner) {
  return isImmutable(contactSigner);
}
export function isImmutableQuoteSigners(contactSigners) {
  return isImmutable(contactSigners);
}
export function isImmutableLineItems(lineItems) {
  return isImmutable(lineItems);
}
export function isImmutableLineItem(lineItem) {
  return isImmutable(lineItem);
}
export function isImmutableQuoteAssociation(association) {
  return isImmutable(association);
}