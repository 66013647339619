import { useQuery } from 'data-fetching-client';
import { fetchProductInfoQuery } from '../queries/product-info.queries';
export const useProductInfo = () => {
  // NOTE: by using DFC, if users are assigned seats _after_ the initial fetch, the data will not be updated
  // to get an accurate count, we would need to refetch the data
  const {
    data,
    error,
    loading
  } = useQuery(fetchProductInfoQuery);
  return {
    error,
    loading,
    productInfo: data === null || data === void 0 ? void 0 : data.productInfo
  };
};