import { useState, useCallback } from 'react';
import { deleteInvoice } from '../api/InvoicesAPI';
const useDeleteInvoice = (invoice, setIsModalOpen, onSuccess, onFailure, onFailureGeneric) => {
  const [isLoading, setIsLoading] = useState(false);
  const onConfirmDeleteInvoice = useCallback(() => {
    if (!invoice.objectId) {
      onFailureGeneric();
      setIsModalOpen(false);
      return;
    }
    setIsLoading(true);
    deleteInvoice(invoice.objectId).then(() => {
      return new Promise(resolve => setTimeout(() => {
        onSuccess();
        resolve();
      }, 3000));
    }).catch(() => {
      onFailure();
    }).finally(() => {
      setIsLoading(false);
      setIsModalOpen(false);
    });
  }, [invoice.objectId, onFailure, onFailureGeneric, onSuccess, setIsModalOpen]);
  return {
    deleteLoading: isLoading,
    onConfirmDeleteInvoice
  };
};
export default useDeleteInvoice;