import { useCallback, useMemo } from 'react';
import { getOwnProperty } from '../utils/getOwnProperty';
import { useHighlySensitivePropertyValues } from '../dataSensitivity/useHighlySensitivePropertyValues';
const MISS = Symbol('MISS');
const DEFAULT_SENSITIVE_VALUES = {};
export function useGetFormValue({
  cachedPropertyValues,
  getSavedValue,
  objectId,
  objectTypeId,
  values
}) {
  const sensitiveValues = useHighlySensitivePropertyValues({
    objectTypeId,
    objectId,
    defaultValues: DEFAULT_SENSITIVE_VALUES
  });

  // We check the following sources in order to find the current value for a property:
  // 1. Draft values from the host app
  // 2. Sensitive values fetched internally
  // 3. Additional values from the host app via `getSavedValue`
  // 4. Cached values fetched internally
  const valueResolvers = useMemo(() => [name => getOwnProperty(values, name, MISS), name => getOwnProperty(sensitiveValues, name, MISS), name => {
    const savedValue = getSavedValue(name, objectId, objectTypeId);
    return savedValue === undefined ? MISS : savedValue;
  }, name => getOwnProperty(cachedPropertyValues, name, MISS)], [cachedPropertyValues, getSavedValue, objectId, objectTypeId, sensitiveValues, values]);
  return useCallback(propertyName => {
    for (const valueResolver of valueResolvers) {
      const value = valueResolver(propertyName);
      if (value !== MISS) {
        return value;
      }
    }
    return undefined;
  }, [valueResolvers]);
}