import { Fragment } from 'prosemirror-model';
import { getLinkText } from '../plugins/link/LinkUtils';
import { schema as defaultSchema } from '../ProsemirrorSchema';
import { isList, isOrderedList } from './listUtils';
import { getMarkRange } from './markUtils';
import { findAllNodes, replaceAllNodes } from './nodeUtils';
import { fontPluginKey } from '../plugins/FontPlugin';
export function transformEmptyParagraphs(editorState) {
  const {
    schema
  } = editorState;
  const isEmptyParagraph = node => node.type === schema.nodes.paragraph && node.content.size === 0;
  return replaceAllNodes(editorState, isEmptyParagraph, (np, tr) => {
    const {
      node,
      pos
    } = np;
    return tr.replaceWith(tr.mapping.map(pos), tr.mapping.map(pos + node.nodeSize), schema.nodes.paragraph.create(null, schema.text('\t')));
  });
}
export function transformHeadingToParagraph(editorState) {
  const {
    schema
  } = editorState;
  const isHeading = node => node.type === schema.nodes.heading;
  return replaceAllNodes(editorState, isHeading, (np, tr) => {
    const {
      node,
      pos
    } = np;
    return tr.replaceWith(tr.mapping.map(pos), tr.mapping.map(pos + node.nodeSize), schema.nodes.paragraph.create(null, schema.text((node === null || node === void 0 ? void 0 : node.textContent) || '\t')));
  });
}
export function transformListItems(editorState) {
  const {
    schema
  } = editorState;
  const isListParagraph = (node, parentNode) => node.type === schema.nodes.paragraph && parentNode.type === schema.nodes.list_item;
  return replaceAllNodes(editorState, node => isList(schema, node), (np, tr) => {
    const pNodes = findAllNodes(np.node, isListParagraph);
    const plainFragment = [];
    pNodes.forEach((pNode, index) => {
      const textPrefix = isOrderedList(schema, np.node) ? schema.text(`${index + 1}. `) : schema.text('- ');
      plainFragment.push(pNode.node.copy(Fragment.from(textPrefix).append(pNode.node.content)));
    });
    return tr.replaceWith(tr.mapping.map(np.pos), tr.mapping.map(np.pos + np.node.nodeSize), plainFragment);
  });
}
export function transformAtMentions(editorState) {
  const {
    schema
  } = editorState;
  const isAtMention = node => node.type === schema.nodes.atmention;
  return replaceAllNodes(editorState, isAtMention, (np, tr) => {
    const {
      node,
      pos
    } = np;
    return tr.insertText(`@${node.attrs.name}`, tr.mapping.map(pos), tr.mapping.map(pos + 1));
  });
}
function isLinkTextSameAsHref(linkText, href) {
  if (`mailto:${linkText}` === href) return true;
  try {
    const url1 = new URL(linkText);
    const url2 = new URL(href);
    return url1.href === url2.href;
  } catch (e) {
    return false;
  }
}
export function transformLinks(editorState) {
  const {
    schema
  } = editorState;
  const isLink = node => {
    const linkMark = schema.marks.link;
    return !!linkMark.isInSet(node.marks);
  };
  return replaceAllNodes(editorState, isLink, (np, tr) => {
    const {
      node,
      pos
    } = np;
    const linkMark = schema.marks.link;
    const mark = linkMark.isInSet(node.marks);
    if (mark) {
      const markBounds = getMarkRange(tr.doc.resolve(tr.mapping.map(pos)), mark);
      const linkText = getLinkText(tr.doc, markBounds);
      const isTextSameAsHref = isLinkTextSameAsHref(linkText, mark.attrs.href);
      tr = tr.removeMark(markBounds.from, markBounds.to, schema.marks.link).insertText(isTextSameAsHref ? linkText : `${linkText} (${mark.attrs.href})`, markBounds.from, markBounds.to);
    }
    return tr;
  });
}
export function transformHardBreak(editorState) {
  const {
    schema
  } = editorState;
  const isHardBreak = node => node.type === schema.nodes.hard_break;
  return replaceAllNodes(editorState, isHardBreak, (np, tr) => {
    const {
      node,
      pos
    } = np;
    return tr.replaceWith(tr.mapping.map(pos), tr.mapping.map(pos + node.nodeSize), schema.text('\n'));
  });
}
export function removeAllMarks(editorState, options) {
  const {
    schema
  } = editorState;
  const markAllowlist = new Set(options && options.allowBasicFormatting ? [schema.marks.strong, schema.marks.em] : []);
  let tempState = editorState;
  for (const markName in schema.marks) {
    if (!markAllowlist.has(schema.marks[markName])) {
      tempState = tempState.apply(tempState.tr.removeMark(0, tempState.doc.content.size, schema.marks[markName]).setMeta('addToHistory', false));
    }
  }
  return tempState;
}
export function removeNonPlaintextNodes(editorState) {
  const {
    schema
  } = editorState;
  const nodeAllowlist = new Set([schema.nodes.doc, schema.nodes.paragraph, schema.nodes.text]);
  const isNonPlaintextNode = node => !nodeAllowlist.has(node.type);
  return replaceAllNodes(editorState, isNonPlaintextNode, (np, tr) => {
    const {
      node,
      pos
    } = np;
    const text = node === null || node === void 0 ? void 0 : node.textContent;
    return text || text === '' ? tr.insertText(text, tr.mapping.map(pos), tr.mapping.map(pos + 1)) : tr;
  });
}
export function convertTabsToSpaces(text, marks, tabSize = 4) {
  const fontMark = defaultSchema.marks.font_style.isInSet(marks);
  const hasTabSupport = text.includes('\t') && fontMark && fontMark.attrs.style === 'monospace';
  if (!hasTabSupport) return text;
  let newText = '';
  let index = 0;
  for (const char of text) {
    if (char === '\t') {
      // get distance from current index to nearest tab marker of tabSize
      const distFromTab = tabSize * (Math.floor(index / tabSize) + 1) - index;
      newText += ' '.repeat(distFromTab);
      index += distFromTab;
    } else {
      newText += char;
      index++;
    }
  }
  return newText;
}
const removeFontPlugin = state => {
  return state.reconfigure({
    plugins: state.plugins.filter(p => p.spec.key !== fontPluginKey)
  });
};
const plaintextTransformers = [removeFontPlugin, transformAtMentions, transformLinks, transformHeadingToParagraph, transformEmptyParagraphs, transformListItems, transformHardBreak, removeAllMarks];
export function convertToPlaintextState(editorState, options) {
  let plainState = editorState;
  plaintextTransformers.forEach(transformer => {
    plainState = transformer(plainState, options);
  });
  return plainState;
}