'use es6';

import I18n from 'I18n';
import { COMPANY, CONTACT } from 'customer-data-objects/constants/ObjectTypes';
import { COMPANY_TYPE_ID, CONTACT_TYPE_ID, COMMERCE_PAYMENT_TYPE_ID, INVOICE_TYPE_ID, normalizeTypeId } from 'customer-data-objects/constants/ObjectTypeIds';
import formatName from 'I18n/utils/formatName';
import { getDisplayedValue } from 'customer-data-property-utils/PropertyValueDisplay';
const EMPTY_LABEL = '--';
const getPropertyValue = (allProperties, propName) => {
  const foundProperty = allProperties.find(property => property.name === propName);
  return foundProperty && foundProperty.value;
};
const getCrmObjectPrimaryDisplayLabel = (objectType, crmObject) => {
  const allProperties = crmObject.allProperties;
  const primaryPropertyInfo = crmObject.primaryDisplayProperty;
  if (objectType === CONTACT || objectType === CONTACT_TYPE_ID) {
    const firstName = getPropertyValue(allProperties, 'firstname');
    const lastName = getPropertyValue(allProperties, 'lastname');
    const email = getPropertyValue(allProperties, 'email');
    return formatName({
      firstName,
      lastName
    }) || email || EMPTY_LABEL;
  }
  if (objectType === COMPANY || objectType === COMPANY_TYPE_ID) {
    const name = getPropertyValue(allProperties, 'name');
    const domain = getPropertyValue(allProperties, 'domain');
    return name || domain || EMPTY_LABEL;
  }
  if (objectType === COMMERCE_PAYMENT_TYPE_ID) {
    const currencyCode = getPropertyValue(allProperties, 'hs_currency_code');
    const amount = getPropertyValue(allProperties, 'hs_initial_amount');
    return I18n.formatIntlCurrency(amount, {
      currencyCode
    }) || EMPTY_LABEL;
  }
  if (objectType === INVOICE_TYPE_ID) {
    const secondaryPropertyInfo = crmObject.secondaryDisplayProperties && crmObject.secondaryDisplayProperties.find(property => !!property.value);
    return primaryPropertyInfo && primaryPropertyInfo.value || secondaryPropertyInfo && secondaryPropertyInfo.value || EMPTY_LABEL;
  }
  const displayLabel = primaryPropertyInfo && getDisplayedValue(primaryPropertyInfo.definition, primaryPropertyInfo.value, undefined, {}, normalizeTypeId(objectType));
  return displayLabel || EMPTY_LABEL;
};
export default getCrmObjectPrimaryDisplayLabel;