import http from 'hub-http/clients/apiClient';
export const fetchWhatsAppSubscription = ({
  hubSpotCustomerPhoneNumber,
  endUserPhoneNumber
}) => {
  return http.post('/short-messages/app/v1/recipient-status/whatsapp', {
    data: {
      hubSpotCustomerPhoneNumber,
      endUserPhoneNumber
    }
  });
};