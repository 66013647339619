import { List as ImmutableList } from 'immutable';
import { ATTACHMENT_TYPE_ID, MENTIONS } from 'conversations-message-history/comment-message/constants/attachmentTypes';
export const buildAtMentionAttachments = ({
  html
}) => {
  // mentions can be deleted after being added so we need to parse the message here.
  const mentionsMatches = html.match(/data-at-mention data-user-id="([0-9]+)/g) || [];
  const prosemirrorMatches = html.match(/data-mention-id="([0-9]+)/g) || [];
  const userIds = mentionsMatches.concat(prosemirrorMatches).map(text => parseInt(text.replace(/[^0-9]/g, ''), 10));
  return mentionsMatches.length + prosemirrorMatches.length ? ImmutableList([{
    [ATTACHMENT_TYPE_ID]: MENTIONS,
    userIds,
    teamIds: []
  }]) : ImmutableList();
};