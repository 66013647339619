import http from 'conversations-http/clients/http';
import { CustomView } from 'find-and-filter-data/views-schema/public';

/**
 * @description Fetches the hydrated custom view for the given `customViewId`
 */

export function fetchCustomViewService({
  customViewId,
  convertToCustomView = true
}) {
  return http.get(`conversations-custom-views/v1/custom-views/${customViewId}/hydrated`).then(customView => convertToCustomView ? new CustomView(customView) : customView);
}