import { createInvalidResult } from '../utils/result';
export const requiredValueValidator = {
  validate: (node, request) => {
    const {
      getFormValue
    } = request;
    const {
      propertyName,
      isRequired
    } = node;
    const hasValue = Boolean(getFormValue(propertyName));
    if (!isRequired) {
      return {
        status: 'VALID'
      };
    }
    return hasValue ? {
      status: 'VALID'
    } : createInvalidResult('MISSING_REQUIRED_PROPERTY', propertyName);
  }
};