'use es6';

import { Record } from 'immutable';
const UniversalAssociationOptionRecord = Record({
  isDefaultAssociation: false,
  isSelected: false,
  isNeverLogged: false,
  objectId: undefined,
  primaryDisplayLabel: undefined,
  secondaryDisplayLabel: undefined,
  emailIfContact: undefined,
  objectTypeId: undefined,
  currentUserCanCommunicate: true,
  legalBasis: undefined,
  isDisabled: false,
  isIndeterminate: false,
  isNewContact: undefined
}, 'UniversalAssociationOptionRecord');
export default UniversalAssociationOptionRecord;