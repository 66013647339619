import mark from 'crm-fe-perf/timing/mark';
import measure from 'crm-fe-perf/timing/measure';
import { getDownloadStart, getDownloadFinish } from 'crm-fe-perf/timing/marks/crm-cards-sdk/performanceMarks';
import { getDownloadMeasure } from 'crm-fe-perf/timing/measurements/crm-cards-sdk/performanceMeasurements';
const loaderCache = new Map();
function wrapWithMarks(
/*
 * We don't get the benefit of TypeScript types in the public interface (JS),
 * so just use a loose type for `cardType`. It should actually be CardType from crm-cards-sdk.
 */
cardType, part, loader) {
  const key = `${cardType}-${part}`;
  if (loaderCache.has(key)) {
    return loaderCache.get(key).loader;
  } else {
    const cacheEntry = {
      // @ts-expect-error This is a placeholder, loader is assigned before return
      loader: undefined,
      startMarkFired: false,
      endMarkFired: false
    };
    const loaderWithMarks = () => new Promise((resolve, reject) => {
      if (!cacheEntry.startMarkFired) {
        cacheEntry.startMarkFired = true;
        mark(getDownloadStart(cardType, part), {
          cardType,
          part
        }, true);
      }
      loader().then(result => {
        if (!cacheEntry.endMarkFired) {
          cacheEntry.endMarkFired = true;
          mark(getDownloadFinish(cardType, part), {
            cardType,
            part
          }, true);
          measure(getDownloadMeasure(cardType, part), {
            cardType,
            part
          });
        }
        resolve(result);
      }, reject);
    });
    cacheEntry.loader = loaderWithMarks;
    loaderCache.set(key, cacheEntry);
    return cacheEntry.loader;
  }
}
export default wrapWithMarks;