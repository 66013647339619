'use es6';

import styled from 'styled-components';
import { EERIE, OLAF, OBSIDIAN } from 'HubStyleTokens/colors';
import { WEB_FONT_FAMILY } from 'HubStyleTokens/misc';
import SVGBase from './SVGBase';
import { AVATAR_SIZES } from '../../Constants';
export default styled(SVGBase).withConfig({
  displayName: "SVGWrapper",
  componentId: "tnvtt5-0"
})(["background-size:cover;background-position:center;display:block;fill:", ";font-family:", ";font-size:", "%;text-anchor:middle;width:100%;height:", ";", ";", ";"], props => props._isHasMore ? OBSIDIAN : 'currentColor', WEB_FONT_FAMILY, props => props._isHasMore ? '340' : '290', props => props.size && props.size !== AVATAR_SIZES.responsive ? `${AVATAR_SIZES[props.size]}px` : 'inherit', props => props.src ? `background-image: url('${props.src}');` : `background-color: ${props._isHasMore ? OLAF : EERIE};`, props => /hubfs\/defaults\/company\.png/.test(props.src) && props._isReversed && 'background-position: -2px;');