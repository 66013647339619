/* hs-eslint ignored failing-rules */

import { getApolloContext, useQuery } from '@apollo/client';
import { useContext } from 'react';
import { apolloClientInvariant } from '../../../graphql/invariant/apolloClientInvariant';
import { getAllRequiredValues } from '../operators/pipelineGetters';
import { getObjectId } from '../operators/ticketGetters';
import { fetchThreadTickets } from '../queries/fetchThreadTickets';
import { useTicketPipelines } from './useTicketPipelines';
import { useUpdateCrmObjectPropertiesMutation } from './useUpdateCrmObjectPropertiesMutation';
import { getTicketFromQueryResponse } from '../utils/getTicketFromQueryResponse';

/**
 * Returns the associated tickets for the given thread, and provides
 * mechanisms to refresh and update the ticket data.
 */
export const useAssociatedTickets = ({
  apolloClient,
  deferred,
  conversationId,
  queryOptions
}) => {
  const apolloClientFromProvider = useContext(getApolloContext()).client;
  apolloClientInvariant(apolloClient || apolloClientFromProvider);
  const {
    pipelines
  } = useTicketPipelines();
  const {
    loading,
    data,
    error,
    refetch,
    observable
  } = useQuery(fetchThreadTickets, Object.assign({}, queryOptions, {
    variables: {
      conversationId: Number(conversationId),
      propertyNames: getAllRequiredValues(pipelines)
    },
    client: apolloClient || apolloClientFromProvider,
    notifyOnNetworkStatusChange: true,
    skip: deferred || !conversationId || conversationId <= 0 || !pipelines,
    context: {
      dangerouslyDisableAllGlobalErrorReporting: true
    }
  }));
  const associatedTicket = getTicketFromQueryResponse(data);
  const ticketId = associatedTicket && getObjectId(associatedTicket);
  const {
    updateCrmObjectProperties: updateTicketProperties
  } = useUpdateCrmObjectPropertiesMutation({
    apolloClient,
    objectType: 'TICKET',
    objectId: ticketId || undefined
  });
  const refetchTickets = variables => {
    var _variables$conversati, _observable$variables;
    const targetConversationId = (_variables$conversati = variables === null || variables === void 0 ? void 0 : variables.conversationId) !== null && _variables$conversati !== void 0 ? _variables$conversati : observable === null || observable === void 0 || (_observable$variables = observable.variables) === null || _observable$variables === void 0 ? void 0 : _observable$variables.conversationId;
    if (targetConversationId && targetConversationId > 0) {
      return refetch(Object.assign({}, variables, {
        conversationId: targetConversationId
      }));
    }
    return Promise.resolve();
  };
  return {
    associatedTicket,
    error,
    loading,
    refetchTickets,
    updateTicketProperties
  };
};