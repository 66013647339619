import { useDataFetchingClient } from 'data-fetching-client';
import { useAblyChannel } from '../../pubsub/hooks/useAblyChannel';
import { getCurrentPortalChannel } from '../../pubsub/utils';
import { USER_CAPACITY_LIMIT_V2_UPDATE } from '../constants';
import Raven from 'raven-js';
import { useCallback, useRef } from 'react';
import { updateCachedCapacityLimit } from '../clients/cache';
import { GET_USER_CAPACITY_DATA_QUERY } from '../../../__generated__/chirp/com/hubspot/cv/assignments/rpc/UserCapacityLimitsV2Service.dfc';

// This hook is meant to be used alongside useUserLevelCapacityLimits, or with fetch calls to GET_USER_CAPACITY_DATA_QUERY
export const useRealtimeUserLevelCapacityLimits = agentId => {
  const latestUpdateTimestampRef = useRef(0);
  const portalChannel = getCurrentPortalChannel();
  const dfcClient = useDataFetchingClient();
  const onMessage = useCallback(message => {
    const lastUpdated = latestUpdateTimestampRef.current;
    const isLatestMessage = message.timestamp > lastUpdated;
    const isCurrentAgent = agentId === message.userId;
    if (isCurrentAgent && isLatestMessage) {
      updateCachedCapacityLimit({
        capacityLimitMessage: message,
        client: dfcClient
      });
    }
  }, [dfcClient, agentId]);
  const onReconnect = useCallback(() => {
    dfcClient.refetchQueries({
      include: [GET_USER_CAPACITY_DATA_QUERY]
    }).catch(e => Raven.captureException(e));
  }, [dfcClient]);
  const onPlayback = useCallback(messages => {
    messages.forEach(onMessage);
  }, [onMessage]);
  useAblyChannel({
    channel: portalChannel,
    type: USER_CAPACITY_LIMIT_V2_UPDATE,
    onMessage,
    onPlayback,
    onReconnect
  });
};