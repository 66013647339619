import { getFrameworkDataSchemasClient } from 'framework-data-schema-resolvers';
import { useCallback } from 'react';
import { useAsyncFunction } from '../../utils/hooks/useAsyncFunction';
import { useHttpClient } from '../../client/HttpClientContext';
/**
 * Patches up a property for internal use in the
 * property input and formatter components. Some upstream callers fail to
 * pass in complete properties, so this hook is used to fill in the
 * gaps. Ideally we would fix the upstream callers, but at the moment it is too
 * widespread and we need to make things work.
 *
 * NOTE: this is a non-blocking solution that will continue using the given
 * property until we get a response from the server to patch it.
 */
export const usePatchedProperty = ({
  property,
  objectTypeId
}) => {
  var _result$data;
  const httpClient = useHttpClient();
  const getPatchedProperty = useCallback(() => {
    return getFrameworkDataSchemasClient({
      httpClient
    }).then(client => client.properties.getProperty({
      frameworkTypeIdentifier: objectTypeId,
      propertyName: property.name,
      query: {
        showHighlySensitiveProperties: true
      }
    })).then(definition => Object.assign({}, property, {
      isMultiValued: definition.property.isMultiValued,
      dataSensitivity: definition.property.dataSensitivity
    }));
  }, [httpClient, objectTypeId, property]);

  // We include a somewhat wide set of checks here since the `Property` defintion from CDO
  // allows optional fields for these, even though we want to ensure they are always present.
  const missingFields = !Object.prototype.hasOwnProperty.call(property, 'isMultiValued') || property.isMultiValued === undefined || property.isMultiValued === null || !Object.prototype.hasOwnProperty.call(property, 'dataSensitivity') || property.dataSensitivity === null || property.dataSensitivity === undefined;
  const result = useAsyncFunction(getPatchedProperty, {
    skip: !missingFields
  });
  return (_result$data = result.data) !== null && _result$data !== void 0 ? _result$data : property;
};