import http from 'hub-http/clients/apiClient';
import PortalIdParser from 'PortalIdParser';
import { CONTACT_TYPE_ID, INVOICE_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
import { createRetryClient } from './retryClient';
const CRM_SEARCH_URL = 'crm-search/search';
const paidNativeInvoicesQuery = {
  data: {
    sorts: [],
    offset: 0,
    count: 1,
    filterGroups: [{
      filters: [{
        operator: 'EQ',
        property: 'hs_invoice_source',
        value: 'native_invoice'
      }, {
        operator: 'EQ',
        property: 'hs_invoice_status',
        value: 'paid'
      }]
    }],
    objectTypeId: INVOICE_TYPE_ID,
    query: '',
    properties: [],
    portalId: PortalIdParser.get()
  }
};
const finalizedNativeInvoicesQuery = {
  data: {
    sorts: [],
    offset: 0,
    count: 1,
    filterGroups: [{
      filters: [{
        operator: 'EQ',
        property: 'hs_invoice_source',
        value: 'native_invoice'
      }, {
        operator: 'NEQ',
        property: 'hs_invoice_status',
        value: 'draft'
      }]
    }],
    objectTypeId: INVOICE_TYPE_ID,
    query: '',
    properties: [],
    portalId: PortalIdParser.get()
  }
};
const getOpenNativeInvoicesQueryByIdAndAmount = (id, amountBilled) => ({
  data: {
    sorts: [],
    offset: 0,
    count: 1,
    filterGroups: [{
      filters: [{
        operator: 'EQ',
        property: 'hs_invoice_source',
        value: 'native_invoice'
      }, {
        operator: 'EQ',
        property: 'hs_invoice_status',
        value: 'open'
      }, {
        operator: 'EQ',
        property: 'hs_object_id',
        value: id
      }, {
        operator: 'EQ',
        property: 'hs_amount_billed',
        value: amountBilled
      }]
    }],
    objectTypeId: INVOICE_TYPE_ID,
    query: '',
    properties: [],
    portalId: PortalIdParser.get()
  }
});
const pollingClientForFinalizedIndexedInvoice = createRetryClient({
  failureCondition: response => {
    var _response$responseJSO;
    return (response === null || response === void 0 || (_response$responseJSO = response.responseJSON) === null || _response$responseJSO === void 0 ? void 0 : _response$responseJSO.total) !== 1;
  },
  reason: 'Finalized invoice not yet indexed',
  maxRetries: 6
});
export const fetchEmailContactOptions = input => http.post(CRM_SEARCH_URL, {
  data: {
    count: 20,
    objectTypeId: CONTACT_TYPE_ID,
    offset: 0,
    portalId: PortalIdParser.get(),
    query: input,
    requestOptions: {
      properties: ['email', 'firstname', 'lastname']
    }
  }
}).then(response => response.results.map(result => ({
  objectId: result.objectId,
  portalId: result.portalId,
  properties: Object.keys(result.properties).reduce((propertiesObject, propertyKey) => Object.assign({}, propertiesObject, {
    [propertyKey]: {
      name: propertyKey,
      value: result.properties[propertyKey].value
    }
  }), {}),
  objectTypeId: result.objectTypeId
})));
export const fetchHasAnyPaidInvoices = async () => {
  try {
    const response = await http.post(CRM_SEARCH_URL, paidNativeInvoicesQuery);
    return response.results.length > 0;
  } catch (error) {
    return false;
  }
};
export const fetchHasAnyFinalizedInvoices = async () => {
  try {
    const response = await http.post(CRM_SEARCH_URL, finalizedNativeInvoicesQuery);
    return response.results.length > 0;
  } catch (error) {
    return false;
  }
};
export const fetchIsOpenInvoiceIndexed = async (id, amountBilled) => {
  return await pollingClientForFinalizedIndexedInvoice.post(CRM_SEARCH_URL, getOpenNativeInvoicesQueryByIdAndAmount(id.toString(), amountBilled));
};