import { getDownloadFinish, getDownloadStart, CrmLocationResolverQueryStart, CrmLocationResolverQueryFinish, CrmLocationResolverQueryGQLStart, CrmLocationResolverQueryGQLFinish, CrmLocationResolverQueryFASStart, CrmLocationResolverQueryFASFinish, FetchLegacySidebarSubjectStart, FetchLegacySidebarSubjectFinish, getCardRenderStart, getRealCardContentStart, getCrmLocationResolverStart, getCardWrapperRenderStart, getCardRenderFinish, getCardRenderTimeout, getCrmCardLocationStart, getCrmRecordCardsResolverStart } from '../../marks/crm-cards-sdk/performanceMarks';
import { getCrmLocationInitialCardsReady } from '../../marks/crm-cards/performanceMarks';
import { AppRenderApplicationStart, AppStart } from '../../marks/standard/performanceMarks';
const downloadMeasures = {};
export const getDownloadMeasure = (cardType, part) => {
  const key = `${cardType}-${part}`;
  if (Object.hasOwn(downloadMeasures, key)) {
    return downloadMeasures[key];
  }
  const measure = {
    name: `card-download-${part}-${cardType}`,
    metricName: `card-download-${part}`,
    start: getDownloadStart(cardType, part),
    end: getDownloadFinish(cardType, part)
  };
  downloadMeasures[key] = measure;
  return measure;
};
const measures = {};
const getMeasure = (measureGroup, key, getNewMeasure) => {
  if (Object.hasOwn(measures, measureGroup) && Object.hasOwn(measures[measureGroup], key)) {
    return measures[measureGroup][key];
  }
  const newMeasure = getNewMeasure();
  if (!Object.hasOwn(measures, measureGroup)) {
    measures[measureGroup] = {};
  }
  measures[measureGroup][key] = newMeasure;
  return newMeasure;
};
export const getWrapperRenderToCardRender = (cardType, cardId) => {
  const key = `${cardType}-${cardId}`;
  const measureGroup = 'card-wrapper-to-card-render';
  const makeNewMeasure = () => ({
    name: `card-wrapper-to-card-render-${cardType}-${cardId}`,
    metricName: 'card-wrapper-to-card-render',
    start: getCardWrapperRenderStart(cardType, cardId),
    end: getCardRenderStart(cardType, cardId)
  });
  return getMeasure(measureGroup, key, makeNewMeasure);
};
export const getCardRenderStartToRealContentStart = (cardType, cardId) => {
  const key = `${cardType}-${cardId}`;
  const measureGroup = 'card-render-to-real-content-start';
  const makeNewMeasure = () => ({
    name: `card-render-to-real-content-start-${cardType}-${cardId}`,
    metricName: 'card-render-to-real-content-start',
    start: getCardRenderStart(cardType, cardId),
    end: getRealCardContentStart(cardType, cardId)
  });
  return getMeasure(measureGroup, key, makeNewMeasure);
};
export const getRealContentStartToFinish = (cardType, cardId) => {
  const key = `${cardType}-${cardId}`;
  const measureGroup = 'real-content-start-to-card-render-finish';
  const makeNewMeasure = () => ({
    name: `real-content-start-to-card-render-finish-${cardType}-${cardId}`,
    metricName: 'real-content-start-to-card-render-finish',
    start: getRealCardContentStart(cardType, cardId),
    end: getCardRenderFinish(cardType, cardId)
  });
  return getMeasure(measureGroup, key, makeNewMeasure);
};
export const getRealContentStartToTimeout = (cardType, cardId) => {
  const key = `${cardType}-${cardId}`;
  const measureGroup = 'real-content-start-to-card-render-timeout';
  const makeNewMeasure = () => ({
    name: `real-content-start-to-card-render-timeout-${cardType}-${cardId}`,
    metricName: 'real-content-start-to-card-render-timeout',
    start: getRealCardContentStart(cardType, cardId),
    end: getCardRenderTimeout(cardType, cardId)
  });
  return getMeasure(measureGroup, key, makeNewMeasure);
};
export const CrmLocationResolverQuery = {
  name: 'crm-location-resolver-query',
  start: CrmLocationResolverQueryStart,
  end: CrmLocationResolverQueryFinish
};
export const CrmLocationResolverGQLQuery = {
  name: 'crm-location-resolver-gql-query',
  start: CrmLocationResolverQueryGQLStart,
  end: CrmLocationResolverQueryGQLFinish
};
export const CrmLocationResolverFASQuery = {
  name: 'crm-location-resolver-fas-query',
  start: CrmLocationResolverQueryFASStart,
  end: CrmLocationResolverQueryFASFinish
};
export const FetchLegacySidebarSubject = {
  name: 'fetch-legacy-sidebar-subject',
  start: FetchLegacySidebarSubjectStart,
  end: FetchLegacySidebarSubjectFinish
};
export const getAppStartToLocationResolverStart = (location, loggingTag) => ({
  name: `AppStartToResolverStart-${location}${loggingTag ? `-${loggingTag}` : ''}`,
  metricName: 'AppStartToResolverStart',
  start: AppStart,
  end: getCrmLocationResolverStart(location, loggingTag)
});
export const getAppStartToRecordCardsResolverStart = (location, loggingTag) => ({
  name: `AppStartToResolverStart-${location}${loggingTag ? `-${loggingTag}` : ''}`,
  metricName: 'AppStartToResolverStart',
  start: AppStart,
  end: getCrmRecordCardsResolverStart(location, loggingTag)
});
export const getRenderApplicationToLocationResolverStart = (location, loggingTag) => ({
  name: `RenderApplicationToResolverStart-${location}${loggingTag ? `-${loggingTag}` : ''}`,
  metricName: 'RenderApplicationToResolverStart',
  start: AppRenderApplicationStart,
  end: getCrmLocationResolverStart(location, loggingTag)
});
export const getRenderApplicationToRecordCardsResolverStart = (location, loggingTag) => ({
  name: `RenderApplicationToResolverStart-${location}${loggingTag ? `-${loggingTag}` : ''}`,
  metricName: 'RenderApplicationToResolverStart',
  start: AppRenderApplicationStart,
  end: getCrmRecordCardsResolverStart(location, loggingTag)
});
export const getLocationResolverToInitialCardsSettled = (location, loggingTag) => ({
  name: `ResolverStartToInitialCardsSettled-${location}${loggingTag ? `-${loggingTag}` : ''}`,
  metricName: 'ResolverStartToInitialCardsSettled',
  start: getCrmLocationResolverStart(location, loggingTag),
  end: getCrmLocationInitialCardsReady(location)
});
export const getRecordCardsResolverToInitialCardsSettled = (location, loggingTag) => ({
  name: `ResolverStartToInitialCardsSettled-${location}${loggingTag ? `-${loggingTag}` : ''}`,
  metricName: 'ResolverStartToInitialCardsSettled',
  start: getCrmRecordCardsResolverStart(location, loggingTag),
  end: getCrmLocationInitialCardsReady(location)
});
export const getLocationResolverToCrmCardLocationStart = (location, loggingTag) => ({
  name: `ResolverStartToCardLocationStart-${location}${loggingTag ? `-${loggingTag}` : ''}`,
  metricName: 'ResolverStartToCardLocationStart',
  start: getCrmLocationResolverStart(location, loggingTag),
  end: getCrmCardLocationStart(location, loggingTag)
});
export const getRecordCardsResolverToCrmCardLocationStart = (location, loggingTag) => ({
  name: `ResolverStartToCardLocationStart-${location}${loggingTag ? `-${loggingTag}` : ''}`,
  metricName: 'ResolverStartToCardLocationStart',
  start: getCrmRecordCardsResolverStart(location, loggingTag),
  end: getCrmCardLocationStart(location, loggingTag)
});
export const getCrmCardLocationToSettled = (location, loggingTag) => ({
  name: `CardLocationStartToInitialCardsSettled-${location}${loggingTag ? `-${loggingTag}` : ''}`,
  metricName: 'CardLocationStartToInitialCardsSettled',
  start: getCrmCardLocationStart(location, loggingTag),
  end: getCrmLocationInitialCardsReady(location)
});