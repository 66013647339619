/**
 * @description The supported sorting properties on a Conversation object
 */
export let ConversationSortByProperties;

/**
 * @deprecated Use HydratedCustomView['processingState'] instead
 * @description The processing state of the ListSeg list associated with the CustomView.
 * This is distinct from CustomViewStatus - the list only needs to be re-processed
 * if there are changes to a CustomView's filters.
 */
(function (ConversationSortByProperties) {
  ConversationSortByProperties["LatestSentTimestamp"] = "hs_last_message_sent_at";
  ConversationSortByProperties["LatestReceivedTimestamp"] = "hs_last_message_received_at";
  ConversationSortByProperties["LatestMessageTimestamp"] = "hs_last_message_at";
})(ConversationSortByProperties || (ConversationSortByProperties = {}));
export let ProcessingState;

/**
 * @deprecated Use HydratedCustomView['paramType'] instead
 */
(function (ProcessingState) {
  ProcessingState["Processing"] = "PROCESSING";
  ProcessingState["Completed"] = "COMPLETED";
})(ProcessingState || (ProcessingState = {}));
export let ParamType;

/**
 * @deprecated Use HydratedCustomView['paramPropertyType'] instead
 */
(function (ParamType) {
  ParamType["NONE"] = "NONE";
  ParamType["USER"] = "USER";
  ParamType["TEAM"] = "TEAM";
})(ParamType || (ParamType = {}));
/**
 * @description The current state of the CustomView. This is distinct from
 * ProcessingState - CustomViewStatus changes for any changes on a CustomView.
 */
export let CustomViewStatus;

/**
 * @description The different default custom views we provision
 */
(function (CustomViewStatus) {
  CustomViewStatus["CreatingAndProcessing"] = "CREATED_PROCESSING";
  CustomViewStatus["CreatedAndComplete"] = "CREATED_COMPLETED";
  CustomViewStatus["UpdatingAndProcessing"] = "UPDATED_PROCESSING";
  CustomViewStatus["UpdatedAndComplete"] = "UPDATED_COMPLETED";
  CustomViewStatus["Deleted"] = "DELETED";
})(CustomViewStatus || (CustomViewStatus = {}));
export const ALL_CLOSED = 'ALL_CLOSED';
export const CALLS = 'CALLS';

/**
 * @description The different default custom views we provision for tickets
 */

// Default Ticket View Types
export const UNASSIGNED_TICKETS = 'UNASSIGNED_TICKETS';
export const ALL_OPEN_TICKETS = 'ALL_OPEN_TICKETS';
export const ALL_CLOSED_TICKETS = 'ALL_CLOSED_TICKETS';
export const SENT_TICKETS = 'SENT_TICKETS';
export const ASSIGNED_TO_ME_TICKETS = 'ASSIGNED_TO_ME_TICKETS';
export const MY_TEAM_OPEN_TICKETS = 'MY_TEAM_OPEN_TICKETS';
export const MY_TEAM_UNASSIGNED_TICKETS = 'MY_TEAM_UNASSIGNED_TICKETS';
export const MY_MENTIONS_TICKETS = 'MY_MENTIONS_TICKETS';
export const MY_FOLLOWED_TICKETS = 'MY_FOLLOWED_TICKETS';
export const OPEN_SPACE_TICKETS = 'OPEN_SPACE_TICKETS';
export const UNASSIGNED_SPACE_TICKETS = 'UNASSIGNED_SPACE_TICKETS';
export const CLOSED_SPACE_TICKETS = 'CLOSED_SPACE_TICKETS';