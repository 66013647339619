export const OBJECT_SUMMARY = 'object_summary';
export const OBJECT_UPDATE = 'object_update';
export const COMPANY_NEWS = 'news';
export const COMPANY_TECH = 'company_tech';
export const COMMUNICATION_SUMMARY = 'communication_summary';
export const OBJECT_ENGAGEMENTS_SUMMARY = 'object_engagements_summary';
export const TASK_CREATE = 'task_create';
export const SUMMARIZE_ACTIVITY_FEED = 'summarize_activity_feed';
export const WORKFLOW_CREATE = 'workflow_create';
export const OBJECT_ASSOCIATIONS = 'object_associations';
export const REPORTING_INSIGHTS_COPILOT_GENERATION = 'reporting_insights_copilot_generation';
export const DASHBOARD_GENERATION = 'dashboard_generation';