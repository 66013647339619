export const replyRecEdited = ({
  inResponseToMessageId,
  confidenceScore,
  hasEditorContent,
  displayedAfterLoad
}) => ({
  key: 'editReplyRecommendation',
  properties: {
    action: 'Clicks to edit reply recommendation',
    platform: 'Helpdesk',
    inResponseToMessageId,
    confidenceScore,
    type: `displayedAfterLoad: ${displayedAfterLoad}, hasEditorContent: ${hasEditorContent}`
  }
});
export const replyRecSeen = ({
  inResponseToMessageId,
  confidenceScore,
  hasEditorContent,
  displayedAfterLoad
}) => ({
  key: 'renderReplyRecommendation',
  properties: {
    action: 'Systems displays reply recommendation',
    platform: 'Helpdesk',
    inResponseToMessageId,
    confidenceScore,
    type: `displayedAfterLoad: ${displayedAfterLoad}, hasEditorContent: ${hasEditorContent}`
  }
});
export const replyRecVisibility = type => ({
  key: 'toggleReplyRecommendationVisibility',
  properties: {
    action: 'Toggle reply recommendation visibility',
    platform: 'Helpdesk',
    type
  }
});