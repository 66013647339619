import { chainCommands, joinForward, selectNodeForward } from 'prosemirror-commands';
import { Slice, Fragment } from 'prosemirror-model';
import { TextSelection } from 'prosemirror-state';
import memoize from 'transmute/memoize';
import { schema } from '../ProsemirrorSchema';
import { FocusPlugin } from '../plugins/FocusPlugin';
import { ScrollViewPlugin } from '../plugins/ScrollViewPlugin';
import { getDomParser } from './domparser';
import { findNode } from './nodeUtils';
import { replaceHardBreaksWithParagraphs } from './replaceHardBreaksWithParagraphs';
const checkGroup = memoize((spec, group) => {
  if (!spec.group) {
    return false;
  }
  return spec.group.split(' ').includes(group);
});

/**
 * Find the first direct child of the given node which is a member of
 * the given group, and return the position of that child. Does not
 * recurse into descendants.
 */
function findFirstNodeInGroup(node, group) {
  let result;
  node.forEach((descendant, pos) => {
    const isStillLookingForFirstNodeOfGroup = !result;
    if (isStillLookingForFirstNodeOfGroup && checkGroup(descendant.type.spec, group)) {
      result = result || pos;
    }
  });
  return result;
}

/**
 * Find the position of the first "footer" child of the given node.
 *
 * The main use case is the content of email reply histories, which are a
 * special node that should be ignored when we try to find the "end" of a
 * document. Users don't usually want to automatically move the cursor there.
 */
function findPosOfFooterNodes(node) {
  return findFirstNodeInGroup(node, 'footer');
}

// If is not passed in, selection will be set to the end of regular content
export const setTrSelectionPosition = (tr, pos) => {
  try {
    let newSelection;
    if (pos) {
      const resolvedPos = tr.doc.resolve(pos);
      newSelection = TextSelection.near(resolvedPos);
      return tr.setSelection(newSelection);
    }
    const posBeforeFooterNodes = findPosOfFooterNodes(tr.doc);
    if (posBeforeFooterNodes !== undefined) {
      return tr.setSelection(TextSelection.near(tr.doc.resolve(posBeforeFooterNodes), -1));
    } else {
      return tr.setSelection(TextSelection.atEnd(tr.doc));
    }
  } catch (e) {
    // return basic tr, no selection changes if we error out
    return tr;
  }
};
export function insertLink({
  htmlBody,
  editorState,
  from,
  to
}) {
  var _parsedDoc$content$fi;
  let tr = editorState.tr;
  const domparser = getDomParser(editorState.schema);
  const element = stringToDomNode(htmlBody);
  const parsedDoc = domparser.parse(element);
  const linkNode = (_parsedDoc$content$fi = parsedDoc.content.firstChild) === null || _parsedDoc$content$fi === void 0 ? void 0 : _parsedDoc$content$fi.firstChild;
  if (linkNode) {
    tr = tr.replaceRangeWith(from, to, linkNode);
  }
  tr.setMeta(FocusPlugin, {
    focusView: true,
    focusViewPos: tr.mapping.map(to)
  });
  return tr;
}

/**
 * Parse a string of HTML safely and return the resulting DOM node
 * @param htmlString
 */
function stringToDomNode(htmlString) {
  const domparser = new DOMParser();
  const doc = domparser.parseFromString(htmlString, 'text/html').body;
  return doc;
}
export function insertSignature({
  htmlBody,
  editorState
}) {
  const unsubscribeNode = findNode(editorState.doc, n => n.type.name === 'unsubscribe');
  const brandingNode = findNode(editorState.doc, n => n.type.name === 'branding');
  const insertPosition = (unsubscribeNode === null || unsubscribeNode === void 0 ? void 0 : unsubscribeNode.pos) || (brandingNode === null || brandingNode === void 0 ? void 0 : brandingNode.pos) || editorState.tr.doc.content.size;
  const signatureNode = schema.nodes.signature.create({
    signature: htmlBody
  });
  const transaction = editorState.tr.insert(insertPosition, signatureNode);
  return editorState.apply(transaction);
}
export function buildMeetingTr({
  meetingURL,
  meetingName,
  editorState
}) {
  let tr = editorState.tr;
  const meetingsNode = editorState.schema.nodes.meetings.create({
    meetingURL,
    meetingName
  });
  tr = tr.replaceSelection(new Slice(Fragment.fromArray([meetingsNode]), 0, 0)).setMeta(FocusPlugin, {
    focusView: true,
    focusViewPos: editorState.selection.$head
  });
  return tr;
}
export function insertContent({
  htmlBody,
  editorState,
  from,
  to
}) {
  const insertionTransactions = [];
  const tr = editorState.tr;
  const domparser = getDomParser(editorState.schema);
  const element = stringToDomNode(htmlBody);
  const snippetNode = domparser.parse(element, {
    preserveWhitespace: true
  });
  const shouldAdjustFrom = from === 1;
  tr.replaceRangeWith(shouldAdjustFrom ? from - 1 : from, to, snippetNode);
  insertionTransactions.push(tr);
  let newState = editorState.apply(tr);
  if (!shouldAdjustFrom) {
    chainCommands(joinForward, selectNodeForward)(newState, cmdTr => {
      insertionTransactions.push(cmdTr);
      newState = newState.apply(cmdTr);
    });
  }
  const adjustedPositon = insertionTransactions.reduce((position, transaction) => transaction.mapping.map(position), to + 1);
  const selectionAdjustedTr = setTrSelectionPosition(newState.tr, adjustedPositon).setMeta(ScrollViewPlugin, {
    scrollToPos: from
  }).setMeta(FocusPlugin, {
    focusView: true,
    focusViewPos: adjustedPositon
  });
  return newState.apply(selectionAdjustedTr);
}

/**
 * If a slice has only one child node, unwrap the child and return it. Otherwise,
 * return null.
 *
 * @param slice
 * @returns the solitary child of a slice, or null if there isn't one
 */
function sliceSingleNode(slice) {
  return slice.openStart === 0 && slice.openEnd === 0 && slice.content.childCount === 1 ? slice.content.firstChild : null;
}

/**
 * Insert a piece of HTML content as a Slice, replacing the range given as $to through $from.
 *
 * As opposed to {@see insertContent }, this will not cause content to split blocks or insert
 * new paragraphs. Inserted content will behave as if it were pasted into the document.
 */
export function insertSlice({
  htmlBody,
  editorState,
  $from,
  $to
}) {
  const dom = stringToDomNode(htmlBody);
  replaceHardBreaksWithParagraphs(dom);
  const slice = getDomParser(editorState.schema).parseSlice(dom, {
    preserveWhitespace: true
  });
  if (!slice) return editorState;
  const tr = editorState.tr.setSelection(new TextSelection($from, $to));
  const singleNode = sliceSingleNode(slice);
  if (singleNode) {
    tr.replaceSelectionWith(singleNode, false);
  } else {
    tr.replaceSelection(slice);
  }
  tr.setMeta(ScrollViewPlugin, {
    scrollToPos: $from.pos
  });
  tr.setMeta(FocusPlugin, {
    focusView: true
  });
  return editorState.apply(tr);
}