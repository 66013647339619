import { isInboxHelpdesk } from 'conversations-internal-schema/inbox/operators/inboxGetters';
import { buildChannelRoutingRules, getChannelRoutingDistribution } from 'conversations-routing-lib/routing-rules/utils';
import { useMoveAssignAndPublish } from 'conversations-thread-data/move-to-inbox/public/hooks';
import { useCallback, useMemo, useState } from 'react';
import { KEEP_ASSIGNEE, ROUTING_RULE_TYPES } from 'routing-ui/routing-rules/constants/RoutingTypes';
import { DISTRIBUTIONS } from 'routing-ui/routing-rules/constants/distributions';
import { isValid } from 'routing-ui/routing-rules/operators/isValid';
import { checkIsRoutingRuleConfig, getDistribution } from 'routing-ui/routing-rules/operators/routingRulesGetters';
import { setDistribution } from 'routing-ui/routing-rules/operators/routingRulesSetters';
import { useUpdateCrmObjectPropertiesMutation } from 'svh-tickets-lib/tickets/public/hooks';
import isEmpty from 'transmute/isEmpty';
import { usePipelineInformation } from './usePipelineInformation';
const checkUpdateDistribution = (routingRule, distribution) => getDistribution(routingRule) !== distribution;
export const useConversationHandoffForm = ({
  associatedTicketIds,
  genericChannelId,
  options,
  threadId,
  threadCurrentlyInHelpdesk = false
}) => {
  const [inbox, setInbox] = useState();
  const [routingRuleDefinition, setRoutingRuleDefinition] = useState(() => buildChannelRoutingRules(genericChannelId, ROUTING_RULE_TYPES.INBOX_USERS));
  const [comment, setComment] = useState('');
  const [subject, setSubject] = useState('');
  const {
    onError,
    onCompleted
  } = options;
  const ticketId = associatedTicketIds.get(0);
  const {
    pipelineInformation,
    setPipelineInformation
  } = usePipelineInformation({
    ticketId,
    shouldDefaultPipelineStageToClose: threadCurrentlyInHelpdesk
  });
  const updatedProperties = Object.keys(pipelineInformation).map(propertyName => ({
    name: propertyName,
    value: pipelineInformation[propertyName] || undefined
  }));
  const {
    updateCrmObjectProperties: updateTicketProperties
  } = useUpdateCrmObjectPropertiesMutation({
    objectType: 'TICKET',
    objectId: ticketId
  });
  const inboxId = inbox && inbox.id;
  const inboxName = inbox && inbox.name;
  const routingRule = routingRuleDefinition !== KEEP_ASSIGNEE ? routingRuleDefinition : null;
  const inboxIsValid = !isEmpty(inbox);
  const routingRulesDefinitionIsValid = routingRuleDefinition === KEEP_ASSIGNEE || isValid(routingRuleDefinition);
  const isToHelpdesk = inbox ? isInboxHelpdesk(inbox) : false;
  const shouldCreateNewTicket = isToHelpdesk && associatedTicketIds.isEmpty();
  const subjectIsValid = !shouldCreateNewTicket || subject.length > 0;
  const valid = inboxIsValid && routingRulesDefinitionIsValid && subjectIsValid;
  const {
    moveAssignAndPublish,
    moveAssignAndPublishLoading
  } = useMoveAssignAndPublish({
    variables: {
      routingRule,
      threadId,
      subject,
      comment,
      inboxId,
      inboxName,
      pipelineInformation
    },
    onError,
    onCompleted: () => {
      if (!associatedTicketIds.isEmpty() && (isToHelpdesk || threadCurrentlyInHelpdesk)) {
        updateTicketProperties({
          onSuccess: () => {},
          properties: updatedProperties
        }).then(onCompleted).catch(onError);
      } else {
        onCompleted();
      }
    }
  });
  const selectedInboxId = useMemo(() => {
    if (!inbox) {
      return undefined;
    } else {
      const id = inbox.id;
      return id === null ? undefined : id;
    }
  }, [inbox]);
  const checkAndSetRoutingRuleDefinition = useCallback(newRoutingRule => {
    // when moving to Help Desk, we should always use a balanced distribution
    const distribution = isToHelpdesk ? DISTRIBUTIONS.BALANCED : getChannelRoutingDistribution(genericChannelId);
    let update = newRoutingRule;
    if (checkIsRoutingRuleConfig(newRoutingRule) && checkUpdateDistribution(newRoutingRule, distribution)) {
      update = setDistribution(distribution, newRoutingRule);
    }
    setRoutingRuleDefinition(update);
  }, [genericChannelId, isToHelpdesk]);
  return {
    isLoading: moveAssignAndPublishLoading,
    isValid: valid,
    setFieldValue: {
      setComment,
      setInbox,
      setRoutingRuleDefinition: checkAndSetRoutingRuleDefinition,
      setSubject,
      setPipelineInformation
    },
    submitForm: moveAssignAndPublish,
    comment,
    subject,
    pipelineInformation,
    selectedInboxId,
    routingRuleDefinition,
    isToHelpdesk,
    shouldCreateNewTicket
  };
};