import { OrderedMap, Map as ImmutableMap, Set as ImmutableSet } from 'immutable';
import memoize from 'transmute/memoize';

// eslint-disable-next-line no-restricted-imports -- we use http by default
import http from 'hub-http/clients/apiClient';
import { CONTACT_TYPE_ID, TICKET_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
import isPortalSpecificObjectType from 'customer-data-objects/crmObject/isPortalSpecificObjectType';
import { FETCH_UNIVERSAL_ENGAGEMENT_ASSOCIATIONS_DATA } from 'universal-associations-select/hooks/useFetchUniversalEngagementAssociations';
import { fetch } from 'universal-associations-select/api/apolloClient';
import { parseUniversalEngagementAssociations, AUTO_ASSOCIATIONS_HUBSPOT_DEFINED_OBJECT_TYPE_ID_ALLOWLIST } from './parseUniversalEngagementAssociations';
// @ts-expect-error untyped
import EngagementsV2UniversalAssociationRecord from '../records/EngagementsV2UniversalAssociationRecord';
// @ts-expect-error untyped

// @ts-expect-error untyped

const getSelectedAssociations = memoize(associations => associations.reduce((acc, record) => {
  const selectedAssociationOptionRecords = record.get('associationOptionRecords').filter(option => option.get('isSelected'));
  if (!selectedAssociationOptionRecords.size) {
    return acc;
  }
  const toObjectTypeId = record.get('toObjectTypeId');
  const objectIds = selectedAssociationOptionRecords ? ImmutableSet(selectedAssociationOptionRecords.keySeq()) : ImmutableSet();
  const recordsMap = selectedAssociationOptionRecords || OrderedMap();
  const engagementsV2UniversalAssociation = ImmutableMap({
    [toObjectTypeId]: EngagementsV2UniversalAssociationRecord({
      associationOptionRecordsMap: recordsMap,
      associationSpec: ImmutableMap({
        associationCategory: record.get('associationCategory'),
        associationTypeId: record.get('associationTypeId')
      }),
      objectIds
    })
  });

  /* eslint-disable-next-line hubspot-dev/no-reduce-accumulator-copy */
  return acc.concat(engagementsV2UniversalAssociation);
}, ImmutableMap()));

// Promise version of useUniversalAssociations that is only used for Calls in calling-lifecycle-library
// Return value is in a different shape than useUniversalAssociations' return value
// Note: This function does not handle never log list enforcement for Emails
const fetchAndParseAutoAssociations = ({
  engagementOrInteractionType,
  subjectObjectId,
  subjectObjectTypeId,
  shouldPreselectConfiguredAutoAssociations,
  apiClient = http
}) => {
  if (!shouldPreselectConfiguredAutoAssociations) {
    return fetch({
      query: FETCH_UNIVERSAL_ENGAGEMENT_ASSOCIATIONS_DATA,
      fetchPolicyOverride: 'cache-and-network',
      variables: {
        engagementObjectTypeId: engagementOrInteractionType,
        engagementExists: false,
        isTicketOrContact: subjectObjectTypeId === TICKET_TYPE_ID || subjectObjectTypeId === CONTACT_TYPE_ID,
        objectTypeId: subjectObjectTypeId,
        subjectExists: true,
        subjectId: subjectObjectId
      }
    }).then(({
      data
    }) => {
      const associations = parseUniversalEngagementAssociations({
        data,
        variables: {
          subjectObjectId,
          subjectObjectTypeId
        }
      });
      return getSelectedAssociations(associations).map(association => {
        return association.get('objectIds');
      }).toJS();
    }).catch(error => {
      throw error;
    });
  }
  const isSupportedObjectType = AUTO_ASSOCIATIONS_HUBSPOT_DEFINED_OBJECT_TYPE_ID_ALLOWLIST.includes(subjectObjectTypeId) || isPortalSpecificObjectType(subjectObjectTypeId);
  if (!isSupportedObjectType) {
    return Promise.resolve({
      [subjectObjectTypeId]: [subjectObjectId]
    });
  }
  return apiClient.post('/crm-engagement-auto-associations/v3', {
    data: {
      subjectObjectTypeId,
      subjectObjectId,
      engagementType: engagementOrInteractionType,
      includedObjects: {}
    }
  }).then(data => {
    const {
      autoAssociations
    } = data;
    return Object.entries(autoAssociations).reduce((acc, [objectTypeId, associations]) => {
      const objectIds = associations.reduce((validAssociations, association) => {
        if (association.canUserView && association.currentUserCanCommunicate) {
          validAssociations.push(String(association.objectId));
        }
        return validAssociations;
      }, []);
      if (objectIds.length) {
        acc[objectTypeId] = objectIds;
      }
      return acc;
    }, {});
  }).catch(error => {
    throw error;
  });
};
const isContactOptionOnNeverLogList = ({
  associationOptionRecord,
  neverLogList
}) => {
  var _associationOptionRec;
  const {
    neverLogDomains,
    neverLogAddresses
  } = neverLogList;
  const email = (_associationOptionRec = associationOptionRecord.get('emailIfContact')) === null || _associationOptionRec === void 0 ? void 0 : _associationOptionRec.toLowerCase();
  const isDomainBlocklisted = neverLogDomains.some(domain => email === null || email === void 0 ? void 0 : email.endsWith(`@${domain}`));
  const isEmailBlocklisted = (email === null || email === void 0 ? void 0 : email.length) && neverLogAddresses.includes(email);
  return isDomainBlocklisted || isEmailBlocklisted;
};
const enforceNeverLogList = ({
  parsedAssociations,
  neverLogList
}) => {
  if (!neverLogList) {
    return parsedAssociations;
  }
  return parsedAssociations.map(association => {
    const toObjectTypeId = association.get('toObjectTypeId');
    const associationOptionRecords = association.get('associationOptionRecords').map(associationOptionRecord => {
      if (toObjectTypeId === CONTACT_TYPE_ID) {
        const isNeverLogged = isContactOptionOnNeverLogList({
          associationOptionRecord,
          neverLogList
        });
        return associationOptionRecord.merge({
          isNeverLogged,
          isSelected: isNeverLogged ? false : associationOptionRecord.get('isSelected')
        });
      }
      return associationOptionRecord;
    });
    return association.set('associationOptionRecords', associationOptionRecords);
  });
};
export { enforceNeverLogList, fetchAndParseAutoAssociations, getSelectedAssociations, isContactOptionOnNeverLogList };