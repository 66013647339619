import { useMemo } from 'react';
import { getData } from 'conversations-async-data/async-data/operators/getters';
import { isLoading } from 'conversations-async-data/async-data/operators/statusComparators';
import { useChannelInstancesForChannel } from 'conversations-thread-data/channel-instances/public/hooks';
import { useConnectedAccounts } from 'conversations-thread-data/connected-accounts/public';
import { getChannelInstanceOptions } from '../operators/getChannelInstanceOptions';
import { useDefaultSendFrom } from 'composer-data/senders/public/hooks';
const DEFERRED_GENERIC_CHANNEL_ID = 0;
export const useHydratedChannelInstanceOptions = ({
  inboxId,
  genericChannelId,
  checkConnectedAccounts,
  defaultSendFromEnabled
}) => {
  const {
    isLoading: loadingInstances,
    channelInstances
  } = useChannelInstancesForChannel({
    inboxId,
    channelId: genericChannelId || DEFERRED_GENERIC_CHANNEL_ID,
    deferred: !genericChannelId
  });
  const {
    asyncConnectedAccounts
  } = useConnectedAccounts({
    inboxId,
    deferred: !checkConnectedAccounts
  });
  const {
    defaultSendFrom
  } = useDefaultSendFrom({
    inboxId,
    channelId: genericChannelId,
    deferred: !defaultSendFromEnabled
  });
  return useMemo(() => {
    const loadingData = loadingInstances || checkConnectedAccounts && isLoading(asyncConnectedAccounts);
    const connectedAccounts = getData(asyncConnectedAccounts);
    if (loadingData) {
      return {
        connectedAccounts,
        instanceOptions: [],
        loadingData
      };
    }
    return {
      connectedAccounts,
      instanceOptions: getChannelInstanceOptions({
        connectedAccounts,
        channelInstances,
        defaultSendFrom
      }),
      loadingData
    };
  }, [asyncConnectedAccounts, channelInstances, checkConnectedAccounts, loadingInstances, defaultSendFrom]);
};