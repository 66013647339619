import { isInvalidValidationResult, isPendingValidationResult } from '../types/result';

// Helper to build invalid results with proper sourcing
export const createInvalidResult = (error, sourcePropertyName) => ({
  status: 'INVALID',
  errors: [error],
  errorSources: [{
    error,
    sourcePropertyName
  }]
});
export const aggregateValidatorResults = results => {
  if (results.some(isPendingValidationResult)) {
    return {
      status: 'PENDING'
    };
  }
  const invalidResults = results.filter(isInvalidValidationResult);
  if (invalidResults.length === 0) {
    return {
      status: 'VALID'
    };
  }
  return {
    status: 'INVALID',
    errors: Array.from(new Set(invalidResults.flatMap(r => r.errors))),
    errorSources: invalidResults.flatMap(r => r.errorSources)
  };
};