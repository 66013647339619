import I18n from 'I18n';
export const objectUpdateParser = action => {
  if (action.label) {
    return action.label;
  }
  const propertiesMessages = Object.keys(action.properties).map(property => {
    return I18n.text('aiComponentsUiLibrary.actions.actionParsers.objectUpdate', {
      property,
      objectType: action.objectTypeLabel && action.objectTypeLabel.toLowerCase(),
      value: action.properties[property]
    });
  });
  return propertiesMessages.join('\n');
};