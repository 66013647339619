import { combineReducers } from 'redux';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'Redu... Remove this comment to see the full error message
import Notifications from 'ReduxMessenger/reducers/Notifications';
import auth from '../../auth/reducers/auth';
import { capacityLimits } from 'conversations-inbox-lib/capacity-limits/public/reducers';
import connectedAccounts from '../../connected-accounts/reducers/connectedAccounts';
import contacts from '../../contacts/reducers/contacts';
import crmIntegration from '../../crm-integration/reducers/crmIntegration';
import { omnibusErrors } from 'conversations-inbox-lib/omnibus/public/reducers';
import { initialThreadView } from 'conversations-inbox-lib/initialThreadView/public/reducers';
import publishing from 'conversations-thread-data/pubsub/public/reducers/publishing';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'conv... Remove this comment to see the full error message
import { pubSubClient } from 'conversations-internal-pub-sub/redux/reducers/pubSubClient';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'conv... Remove this comment to see the full error message
import { subscriptions } from 'conversations-internal-pub-sub/redux/reducers/subscriptions';
import inboxAndViewLocalstorage from '../../sticky-inbox-views/reducers/inboxAndViewLocalstorage';
import incomingThreadId from '../../performance-metrics/reducers/incomingThreadId';
import focusedViewMember from '../../focused-view-member/reducers/focusedViewMember';
import threadListMembersBatchUpdates from '../../thread-list-member-sidebar/reducers/threadListMembersBatchUpdates';
import { currentViewId } from '../../thread-list/reducers/currentViewId';
import { unifiedInbox } from 'conversations-inbox-lib/unified-inbox/public/reducers';
import verifyFbmInstall from '../../verify-fbm-install/reducers/verifyFbmInstall';
import installCodePaneStatus from '../../verify-install/reducers/installCodePaneStatus';
import verifyInstallStatus from '../../verify-install/reducers/verifyInstallStatus';
import zeroState from '../../zero-state/reducers/zeroState';
import visitorIdentification from '../../visitor-identification/reducers/identification';
import zorseCrmComponents from 'zorse-crm-components-lib/reducers/zorseCrmComponents';
import messengerConnectionsReducer from '../../messenger-connections/reducers/messengerConnectionsReducer';
import pastConversations from '../../crm-sidebar-cards/reducers/pastConversations';
import agents from '../../crm-sidebar-cards/reducers/agents';
import { searchAndFiltering, searchAndFilteringMode } from 'conversations-inbox-lib/thread-search-and-filtering/public/reducers';
import sortOrder from '../../thread-list-member-sidebar/reducers/sortOrder';
import statusToggle from '../../thread-list-member-sidebar/reducers/statusToggle';
import { findAndFilterData } from 'find-and-filter-data/app-state/public';
import { assignmentStatuses, moveStatuses } from 'conversations-thread-view/thread-details-feature/public/reducers';
import { threadListMemberDetails } from 'conversations-thread-data/thread-details/public/reducers';
import { threadPresence } from 'conversations-thread-data/presence/public/reducers';
import { quickFiltersSlice } from '../../thread-list-member-sidebar/reducers/quickFiltersSlice';
const adminRootReducer = combineReducers({
  agents,
  assignmentStatuses,
  moveStatuses,
  threadListMemberDetails,
  threadPresence,
  auth,
  capacityLimits,
  connectedAccounts,
  crmIntegration,
  currentViewId,
  contacts,
  findAndFilterData,
  focusedViewMember,
  incomingThreadId,
  initialThreadView,
  installCodePaneStatus,
  notifications: Notifications,
  omnibusErrors,
  pastConversations,
  publishing,
  pubSubClient,
  quickFilters: quickFiltersSlice.reducer,
  searchAndFiltering,
  searchAndFilteringMode,
  sortOrder,
  subscriptions,
  statusToggle,
  inboxAndViewLocalstorage,
  threadListMembersBatchUpdates,
  unifiedInbox,
  verifyFbmInstall,
  verifyInstallStatus,
  visitorIdentification,
  zeroState,
  messengerConnections: messengerConnectionsReducer,
  zorseCrmComponents
});
export default adminRootReducer;