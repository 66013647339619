import { ChannelTypes } from 'conversations-internal-schema/constants/ChannelTypes';
import { CALLING_GENERIC_CHANNEL_ID, CUSTOMER_PORTAL_GENERIC_CHANNEL_ID, EMAIL_GENERIC_CHANNEL_ID, FB_MESSENGER_GENERIC_CHANNEL_ID, FORMS_GENERIC_CHANNEL_ID, LIVE_CHAT_GENERIC_CHANNEL_ID, WHATSAPP_GENERIC_CHANNEL_ID } from 'conversations-message-history/common-message-format/constants/genericChannelIds';
export const getChannelTypeByGenericId = genericChannelId => {
  switch (genericChannelId) {
    case LIVE_CHAT_GENERIC_CHANNEL_ID:
      return ChannelTypes.CHAT;
    case FB_MESSENGER_GENERIC_CHANNEL_ID:
      return ChannelTypes.FACEBOOK_MESSENGER;
    case EMAIL_GENERIC_CHANNEL_ID:
      return ChannelTypes.EMAIL;
    case FORMS_GENERIC_CHANNEL_ID:
      return ChannelTypes.FORMS;
    case CUSTOMER_PORTAL_GENERIC_CHANNEL_ID:
      return ChannelTypes.CALLING;
    case WHATSAPP_GENERIC_CHANNEL_ID:
      return ChannelTypes.WHATSAPP;
    case CALLING_GENERIC_CHANNEL_ID:
      return ChannelTypes.CALLING;
    default:
      return null;
  }
};