import { isDependentPropertyValid } from '../../../v2/conditionalPropertyOptionsRules/utils';
import { createInvalidResult } from '../utils/result';
export const conditionalOptionsValidator = {
  validate: (node, request) => {
    const {
      propertyName
    } = node;
    const {
      activeRules,
      changedProperties,
      alwaysShowConditionalPropertyOptionsWithErrors,
      getFormValue,
      properties
    } = request;
    const relevantRules = activeRules.filter(rule => rule.dependentFieldName === propertyName);
    if (relevantRules.length === 0) {
      return {
        status: 'VALID'
      };
    }
    const controllingFields = relevantRules.map(rule => rule.controllingField.fieldName);
    const shouldValidate = alwaysShowConditionalPropertyOptionsWithErrors || controllingFields.some(field => changedProperties.has(field));
    if (!shouldValidate) {
      return {
        status: 'VALID'
      };
    }
    const rulesByControllingField = relevantRules.reduce((acc, rule) => {
      const controllingField = rule.controllingField.fieldName;
      if (!acc[controllingField]) {
        acc[controllingField] = [];
      }
      acc[controllingField].push(rule);
      return acc;
    }, {});
    for (const controllingField of Object.keys(rulesByControllingField)) {
      const rulesForField = rulesByControllingField[controllingField];
      const isValidForControllingField = isDependentPropertyValid({
        dependentProperty: properties[propertyName],
        dependentValue: getFormValue(propertyName),
        conditionalPropertyOptionsRules: rulesForField
      });

      // It is possible that the dependent property for multi-select fields is tied to multiple controlling fields,
      // and we only need to satisfy one of those rules.
      if (isValidForControllingField) {
        return {
          status: 'VALID'
        };
      }
    }
    return createInvalidResult('INVALID_PROPERTIES', propertyName);
  }
};