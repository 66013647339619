import isPortalSpecificObjectType from 'customer-data-objects/crmObject/isPortalSpecificObjectType';
import { createMetricsFactory } from 'metrics-js';
export const Metrics = createMetricsFactory('crm-links', {
  library: 'crm-links'
});
export function trackLinkNavigation({
  external = false,
  linkType,
  linkTypeProps
}) {
  const metricsDimensions = {
    linkType
  };
  if ('objectTypeId' in linkTypeProps) {
    const simplifiedObjectTypeId = isPortalSpecificObjectType(linkTypeProps.objectTypeId) ? 'custom_object' : linkTypeProps.objectTypeId;
    metricsDimensions.objectTypeId = simplifiedObjectTypeId;
  }
  if (external) {
    metricsDimensions.external = true;
  }
  Metrics.counter('link_navigation', metricsDimensions).increment();
}