import { useEffect, useState } from 'react';
import { fetchSyncHealthRecords } from '../api/syncHealthAPI';
import Raven from 'raven-js';
export function useIsRecordInSync(connectionId, objectId, skip) {
  const [hasSyncHealthRecord, setHasSyncHealthRecord] = useState({
    hasRecord: false,
    loading: !skip
  });
  useEffect(() => {
    if (skip || !connectionId || !objectId) {
      return;
    }
    const getHasSyncHealthRecord = async () => {
      const syncHealthRecords = await fetchSyncHealthRecords([connectionId], 'IN_SYNC', 'LATEST_ACTIVITY', 'DESC', 1, objectId.toString());
      setHasSyncHealthRecord({
        hasRecord: syncHealthRecords.length > 0,
        loading: false
      });
    };
    getHasSyncHealthRecord().catch(error => {
      setHasSyncHealthRecord({
        hasRecord: false,
        loading: false
      });
      Raven.captureException(error);
    });
  }, [connectionId, objectId, skip]);
  return hasSyncHealthRecord;
}