import I18n from 'I18n';
const getTimeFrame = action => {
  if (action.activityFromDate && action.activityToDate) {
    return I18n.text('aiComponentsUiLibrary.actions.actionParsers.summarizeActivityFeed.fromUntil', {
      activityFromDate: action.activityFromDate,
      activityToDate: action.activityToDate
    });
  }
  if (action.activityFromDate) {
    return I18n.text('aiComponentsUiLibrary.actions.actionParsers.summarizeActivityFeed.fromDate', {
      activityFromDate: action.activityFromDate
    });
  }
  if (action.activityToDate) {
    return I18n.text('aiComponentsUiLibrary.actions.actionParsers.summarizeActivityFeed.untilDate', {
      activityToDate: action.activityToDate
    });
  }
  return '';
};
export const summarizeActivityFeedParser = action => {
  const timeFrame = getTimeFrame(action);
  return I18n.text('aiComponentsUiLibrary.actions.actionParsers.summarizeActivityFeed.baseText', {
    objectTypeLabel: action.objectTypeLabel.toLowerCase(),
    objectName: action.objectName,
    timeFrame
  });
};