import { List, fromJS } from 'immutable';
import { produce } from 'immer';
import PortalIdParser from 'PortalIdParser';
import transmuteGet from 'transmute/get';
import get from 'hs-lodash/get';
import set from 'hs-lodash/set';
import merge from 'hs-lodash/merge';
import { getId, getProperty, setProperty } from 'customer-data-objects/model/ImmutableModel';
import QuoteContact from 'customer-data-objects/quote/QuoteContactRecord';
import QuoteCompany from 'customer-data-objects/quote/QuoteCompanyRecord';
import { COMPANY_RECORD_TO_SENDER_COMPANY_MAP, DEFAULT_QUOTE_ASSOCIATED_OBJECTS, QUOTE_ASSOCIATIONS } from 'customer-data-objects/quote/constants/properties';
import { QUOTE_BOOLEAN_PROPERTIES, QUOTE_NUMBER_PROPERTIES } from '../constants/quoteProperties';
import { COMPANY, CONTACT, DEAL, LINE_ITEM, QUOTE_TEMPLATE } from 'customer-data-objects/constants/ObjectTypes';
import { isImmutable, isImmutableCompany, isImmutableQuoteAssociation } from './isImmutable';
export function isImmutableQuote(quote) {
  return typeof quote.setIn === 'function';
}
export function makePojoQuote(quote) {
  return isImmutableQuote(quote) ? quote.toJS() : quote;
}
export function denullifyProperties(properties) {
  if (!properties) {
    return {};
  }
  return Object.entries(properties).reduce((newProperties, [propertyKey, property]) => {
    newProperties[propertyKey] = Object.fromEntries(Object.entries(property).filter(([__, value]) => value !== null && value !== undefined));
    return newProperties;
  }, {});
}
export function makeQuoteFromQuoteAssociatedObjects(quoteWithAssociatedObjects) {
  const {
    quote,
    associations,
    quoteAssociatedObjects
  } = quoteWithAssociatedObjects;
  return {
    quote: Object.assign({}, quote, {
      properties: denullifyProperties(quote.properties)
    }),
    associations,
    quoteAssociatedObjects: Object.assign({}, quoteAssociatedObjects, {
      deal: quoteAssociatedObjects.deal ? makeQuoteAssociatedDeal(quoteAssociatedObjects.deal) : undefined,
      lineItems: quoteAssociatedObjects.lineItems.map(makeQuoteAssociatedLineItem),
      contactSigners: quoteAssociatedObjects.contactSigners.map(makeQuoteSigner),
      userSigners: quoteAssociatedObjects.userSigners.map(makeQuoteSigner),
      recipientCompany: quoteAssociatedObjects.recipientCompany ? makeQuoteAssociatedCompany(quoteAssociatedObjects.recipientCompany) : null,
      recipientContacts: quoteAssociatedObjects.recipientContacts.map(makeQuoteAssociatedContact),
      paymentSchedule: quoteAssociatedObjects.paymentSchedule ? Object.assign({}, quoteAssociatedObjects.paymentSchedule, {
        properties: quoteAssociatedObjects.paymentSchedule.properties,
        associatedObjects: Object.assign({}, quoteAssociatedObjects.paymentSchedule.associatedObjects, {
          PAYMENT_SCHEDULE_INSTALLMENT: quoteAssociatedObjects.paymentSchedule.associatedObjects.PAYMENT_SCHEDULE_INSTALLMENT.map(installment => Object.assign({}, installment, {
            properties: installment.properties
          }))
        })
      }) : null,
      quoteTemplate: quoteAssociatedObjects.quoteTemplate ? makeQuoteAssociatedTemplate(quoteAssociatedObjects.quoteTemplate) : null,
      quoteFields: []
    })
  };
}
export function makeQuoteAssociatedDeal(partialDeal) {
  return Object.assign({
    associations: {
      associatedVids: [],
      associatedCompanyIds: [],
      associatedTicketIds: []
    },
    dealId: partialDeal.objectId,
    isDeleted: false,
    imports: [],
    objectType: DEAL
  }, partialDeal, {
    properties: denullifyProperties(partialDeal.properties)
  });
}
export function makeQuoteAssociatedTemplate(partialQuoteTemplate) {
  return Object.assign({
    objectTypeId: 'QUOTE_TEMPLATE',
    associatedObjects: {},
    isDeleted: false,
    objectType: QUOTE_TEMPLATE
  }, partialQuoteTemplate, {
    properties: denullifyProperties(partialQuoteTemplate.properties)
  });
}
export function makeQuoteAssociatedLineItem(partialLineItem) {
  return Object.assign({
    isNew: false,
    isDeleted: false,
    objectType: LINE_ITEM
  }, partialLineItem, {
    properties: denullifyProperties(partialLineItem.properties)
  });
}
export function makeInboundDbQuote(partialInboundDbQuote = {}) {
  return Object.assign({
    objectId: null,
    portalId: PortalIdParser.get(),
    properties: {}
  }, partialInboundDbQuote);
}
export function makeQuoteAssociatedCompany(partialQuoteCompany) {
  var _partialQuoteCompany$;
  return Object.assign({
    companyId: null,
    isDeleted: false,
    additionalDomains: [],
    objectType: (_partialQuoteCompany$ = partialQuoteCompany.objectType) !== null && _partialQuoteCompany$ !== void 0 ? _partialQuoteCompany$ : COMPANY
  }, partialQuoteCompany, {
    properties: denullifyProperties(partialQuoteCompany.properties)
  });
}
export function makeQuoteAssociatedAdditionalFee(partialAdditionalFee) {
  return Object.assign({
    name: '',
    amount: 0,
    category: 'FEE',
    isPercentage: false,
    createdAt: partialAdditionalFee.createdAt,
    id: partialAdditionalFee.id,
    portalId: PortalIdParser.get()
  }, partialAdditionalFee);
}
export function makeQuoteAssociatedContact(partialQuoteContact) {
  var _partialQuoteContact$;
  return Object.assign({
    'associated-company': undefined,
    'canonical-vid': null,
    deleted: false,
    'form-submissions': [],
    'identity-profiles': [],
    'is-contact': true,
    'list-memberships': [],
    'merge-audits': [],
    'merged-vids': [],
    'portal-id': null,
    'profile-token': null,
    'profile-url': null,
    vid: partialQuoteContact.objectId,
    isDeleted: false,
    objectType: (_partialQuoteContact$ = partialQuoteContact.objectType) !== null && _partialQuoteContact$ !== void 0 ? _partialQuoteContact$ : CONTACT
  }, partialQuoteContact, {
    properties: denullifyProperties(partialQuoteContact.properties)
  });
}
export function makeQuoteSigner(partialQuoteSigner = {}) {
  return Object.assign({
    firstName: '',
    lastName: '',
    email: '',
    signedAt: null,
    userId: null,
    avatarUrl: null,
    vid: null
  }, partialQuoteSigner);
}
export function makeQuote(partialQuote = {}) {
  const defaultQuote = {
    quote: makeInboundDbQuote(),
    associations: {
      QUOTE_TO_DEAL: [],
      QUOTE_TO_LINE_ITEM: [],
      QUOTE_TO_CONTACT: [],
      QUOTE_TO_COMPANY: [],
      QUOTE_TO_QUOTE_TEMPLATE: [],
      QUOTE_TO_PAYMENT_SCHEDULE: []
    },
    quoteAssociatedObjects: {
      additionalFees: [],
      lineItems: [],
      contactSigners: [],
      userSigners: [],
      recipientCompany: null,
      recipientContacts: [],
      paymentSchedule: null,
      quoteTemplate: null,
      quoteFields: []
    }
  };
  return merge(defaultQuote, partialQuote);
}
function makeQuoteProperty(property) {
  return Object.assign({
    name: undefined,
    value: undefined,
    source: undefined,
    sourceId: undefined,
    timestamp: undefined,
    updatedByUserId: undefined
  }, property);
}
export function getHasDeal(quote) {
  if (isImmutableQuote(quote)) {
    const isDealIdInAssocationsArray = !!getQuoteAssociation(quote, QUOTE_ASSOCIATIONS.QUOTE_TO_DEAL).first();
    const isDealOnQuoteAssociatedObject = !!quote.getIn(['quote', 'associatedObjects', QUOTE_ASSOCIATIONS.QUOTE_TO_DEAL]);
    return isDealIdInAssocationsArray || isDealOnQuoteAssociatedObject;
  } else {
    const isDealIdInAssocationsArray = !!getQuoteAssociation(quote, QUOTE_ASSOCIATIONS.QUOTE_TO_DEAL)[0];
    const isDealOnQuoteAssociatedObject = !!get(quote, ['quote', 'associatedObjects', QUOTE_ASSOCIATIONS.QUOTE_TO_DEAL]);
    return isDealIdInAssocationsArray || isDealOnQuoteAssociatedObject;
  }
}
export function inboundPropertiesToQuoteObject(inboundDbRecord, propertyMapper, constructor) {
  const defaultProperties = new constructor();
  const isList = List.isList(propertyMapper);
  return propertyMapper.reduce((quoteProperties, value, key) => {
    const propertyKey = isList ? value : key;
    const inboundPropertyValue = getProperty(inboundDbRecord, value);
    return quoteProperties.set(propertyKey, inboundPropertyValue === undefined ? defaultProperties.get(propertyKey) : inboundPropertyValue);
  }, defaultProperties);
}
export function getQuoteCompany(CRMCompany) {
  return inboundPropertiesToQuoteObject(CRMCompany, COMPANY_RECORD_TO_SENDER_COMPANY_MAP.keySeq().toList(), QuoteCompany).set('companyId', String(getId(CRMCompany)));
}
export function getQuoteSenderContact(quote) {
  // Returns a QuoteContact record by extracting the inboundDB sender contact
  // properties from a quote
  if (isImmutableQuote(quote)) {
    return QuoteContact({
      firstname: getQuoteProperty(quote, 'hs_sender_firstname') || '',
      lastname: getQuoteProperty(quote, 'hs_sender_lastname') || '',
      email: getQuoteProperty(quote, 'hs_sender_email') || '',
      phone: getQuoteProperty(quote, 'hs_sender_phone') || '',
      jobtitle: getQuoteProperty(quote, 'hs_sender_jobtitle') || ''
    });
  } else {
    return {
      firstname: getQuoteProperty(quote, 'hs_sender_firstname') || '',
      lastname: getQuoteProperty(quote, 'hs_sender_lastname') || '',
      email: getQuoteProperty(quote, 'hs_sender_email') || '',
      phone: getQuoteProperty(quote, 'hs_sender_phone') || '',
      jobtitle: getQuoteProperty(quote, 'hs_sender_jobtitle') || ''
    };
  }
}
export function getQuoteId(quote) {
  if (isImmutableQuote(quote)) {
    return getId(quote.quote);
  }
  return quote.quote.objectId;
}
export function getQuoteCompanyId(company) {
  if (!company) {
    return undefined;
  }
  if (isImmutableCompany(company)) {
    return getId(company);
  }
  return company.objectId;
}
export function getDealId(quote) {
  if (isImmutableQuote(quote)) {
    return quote.associations[QUOTE_ASSOCIATIONS.QUOTE_TO_DEAL].first();
  }
  return quote.associations[QUOTE_ASSOCIATIONS.QUOTE_TO_DEAL][0];
}
export function getQuoteProperty(quote, property) {
  const isAssociationProperty = Object.prototype.hasOwnProperty.call(DEFAULT_QUOTE_ASSOCIATED_OBJECTS, property);

  // If it's an association property, return the associated object directly
  if (isAssociationProperty) {
    return isImmutableQuote(quote) ? transmuteGet(property, quote.quoteAssociatedObjects) : get(quote.quoteAssociatedObjects, property);
  }

  // Otherwise, fetch the property value from the quote
  const value = isImmutableQuote(quote) ? getProperty(quote.quote, property) : get(quote.quote, ['properties', property, 'value']);

  // Handle Number properties
  if (QUOTE_NUMBER_PROPERTIES.has(property)) {
    const parsedValue = value || value === 0 ? parseInt(value, 10) : null;
    return parsedValue;
  }

  // Handle Boolean properties
  if (QUOTE_BOOLEAN_PROPERTIES.has(property)) {
    const booleanValue = value === 'true' || value === true;
    return booleanValue;
  }
  return value;
}
export function getQuoteAssociation(quote, associationType) {
  if (isImmutableQuote(quote)) {
    return quote.getIn(['associations', associationType]);
  }
  return get(quote, ['associations', associationType]);
}
export function setQuoteAssociations(quote, associations) {
  if (isImmutableQuote(quote)) {
    const updatedAssociations = Object.fromEntries(Object.entries(associations).map(([key, value]) => [key, isImmutable(value) ? value : List(value)]));
    return quote.update('associations', currentAssociations => currentAssociations.merge(updatedAssociations));
  } else {
    return produce(quote, draft => {
      draft.associations = Object.assign({}, draft.associations, associations);
    });
  }
}
export function setQuoteProperty(quote, propertyPath, value, source, sourceId) {
  // propertyPath can be a String or Array - to set nested properties within the
  // quoteAssociatedObjects object
  const isArray = Array.isArray(propertyPath);
  const topLevelProperty = isArray ? transmuteGet(0, propertyPath) : propertyPath;
  const isAssociationProperty = Object.prototype.hasOwnProperty.call(DEFAULT_QUOTE_ASSOCIATED_OBJECTS, topLevelProperty);
  if (isImmutableQuote(quote)) {
    if (isAssociationProperty) {
      const additionalPropertiesUpdatePath = isArray ? ['quoteAssociatedObjects'].concat(propertyPath) : ['quoteAssociatedObjects', propertyPath];
      return quote.setIn(additionalPropertiesUpdatePath, value);
    }
    if (isArray) {
      throw new Error('Use a string for propertyPath to set quote inboundDB properties');
    }
    return quote.update('quote', currentQuote => setProperty(currentQuote, propertyPath, value, source, sourceId));
  } else {
    if (isAssociationProperty) {
      const additionalPropertiesUpdatePath = isArray ? ['quoteAssociatedObjects'].concat(propertyPath) : ['quoteAssociatedObjects', propertyPath];
      return produce(quote, draft => {
        set(draft, additionalPropertiesUpdatePath, value);
      });
    }
    if (isArray) {
      throw new Error('Use a string for propertyPath to set quote inboundDB properties');
    }
    return produce(quote, draft => {
      if (draft.quote.properties[propertyPath]) {
        draft.quote.properties[propertyPath].value = value;
      } else {
        draft.quote.properties[propertyPath] = makeQuoteProperty({
          name: propertyPath,
          value
        });
      }
      if (source !== undefined) {
        draft.quote.properties[propertyPath].source = source;
      }
      if (sourceId !== undefined) {
        draft.quote.properties[propertyPath].sourceId = sourceId;
      }
    });
  }
}
export function mergeQuoteProperties(quote, updates) {
  if (isImmutableQuote(quote)) {
    const updatesMap = fromJS(updates);
    const inboundDbUpdates = updatesMap.filterNot((value, key) => Object.prototype.hasOwnProperty.call(DEFAULT_QUOTE_ASSOCIATED_OBJECTS, key));
    const additionalPropertyUpdates = updatesMap.filter((value, key) => Object.prototype.hasOwnProperty.call(DEFAULT_QUOTE_ASSOCIATED_OBJECTS, key));
    const updatedQuote = inboundDbUpdates.reduce((currentQuote, updatedValue, key) => {
      return setProperty(currentQuote, key, updatedValue);
    }, quote.quote);
    const updatedQuoteAdditionalProperties = quote.quoteAssociatedObjects.merge(additionalPropertyUpdates);
    return quote.merge({
      quote: updatedQuote,
      quoteAssociatedObjects: updatedQuoteAdditionalProperties
    });
  } else {
    return Object.entries(updates).reduce((acc, [propertyPath, value]) => {
      return setQuoteProperty(acc, propertyPath, value);
    }, quote);
  }
}
export function getQuoteAssociationProperty(association, propertyName) {
  if (isImmutableQuoteAssociation(association)) {
    return getProperty(association, propertyName);
  }
  return get(association, ['properties', propertyName, 'value']);
}