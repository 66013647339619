import { SUBSCRIPTION_TYPE_ID } from '../constants/ObjectTypeIds';
const EDITABLE_PROPERTIES = new Set(['hs_name', 'hs_recipient_billing_address', 'hs_recipient_billing_address2', 'hs_recipient_billing_city', 'hs_recipient_billing_country', 'hs_recipient_billing_state', 'hs_recipient_billing_zip', 'hubspot_owner_id']);
const MANUAL_ONLY_EDITABLE_PROPERTIES = new Set([...EDITABLE_PROPERTIES, 'hs_allowed_payment_methods', 'hs_automatically_email_invoices', 'hs_collect_address_types', 'hs_invoice_comments', 'hs_language', 'hs_locale', 'hs_net_payment_terms', 'hs_payment_enabled', 'hs_tax_ids']);
export function isReadOnlySubscriptionProperty({
  objectTypeId,
  propertyName,
  hubspotDefined,
  invoiceCreation,
  collectionProcess
}) {
  if (objectTypeId !== SUBSCRIPTION_TYPE_ID || !hubspotDefined) {
    // if this is not a subscription or it's a custom property everything is editable
    return false;
  }
  if (!invoiceCreation || !propertyName) {
    // default to read only if we cannot determine invoiceCreation value
    return true;
  }
  const isBillable = invoiceCreation === 'on';
  if (!isBillable) {
    // if the subscription isn't billable everything is editable (it's unmanaged by HS)
    return false;
  }
  switch (collectionProcess) {
    case 'automatic_payments':
      return !EDITABLE_PROPERTIES.has(propertyName);
    case 'manual_payments':
      return !MANUAL_ONLY_EDITABLE_PROPERTIES.has(propertyName);
    default:
      return true;
  }
}