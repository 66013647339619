import PropertyRecord from 'customer-data-objects/property/PropertyRecord';
// We use some heuristics to make type guards for the different "PropertyLike" types here.
// The goal is to identify the different types so we can handle them accordingly below.
const isPropertyRecord = property => property instanceof PropertyRecord;
const isGqlProperty = property => '__typename' in property;

/**
 * Converts from the union type of PropertyLike to Property. We use this to
 * allow multiple data shapes in our public API but keep everything internal using
 * a single type. In the future, it would be ideal to move to the client types
 * generated type here, but there are a lot of places that expect the CDO type.
 *
 * @param property - The property definition to normalize
 * @returns A property definition normalized to the CDO Property type
 */
export const normalizeProperty = property => {
  if (isPropertyRecord(property)) {
    return property.toJS();
  }
  if (isGqlProperty(property)) {
    var _property$deleted, _property$hubspotDefi;
    return Object.assign({}, property, {
      // Update key names
      isCustomizedDefault: property.customizedDefault,
      isMultiValued: property.multiValued,
      // Correct the GQL null type to undefined or a fallback.
      deleted: (_property$deleted = property.deleted) !== null && _property$deleted !== void 0 ? _property$deleted : false,
      hubspotDefined: (_property$hubspotDefi = property.hubspotDefined) !== null && _property$hubspotDefi !== void 0 ? _property$hubspotDefi : undefined,
      options: property.options.map(option => {
        var _option$hidden, _option$readOnly;
        return Object.assign({}, option, {
          hidden: (_option$hidden = option.hidden) !== null && _option$hidden !== void 0 ? _option$hidden : false,
          readOnly: (_option$readOnly = option.readOnly) !== null && _option$readOnly !== void 0 ? _option$readOnly : undefined
        });
      }),
      // Cast wide types where GQL types are not as specific
      dateDisplayHint: property.dateDisplayHint,
      displayMode: property.displayMode,
      fieldType: property.fieldType,
      numberDisplayHint: property.numberDisplayHint,
      sensitiveDataCategories: property.sensitiveDataCategories,
      type: property.type
    });
  }

  // CDO and client types definition should be compatible. There are a few cases where the
  // client type has wider types for string enums. Also, the client type has some required
  // fields where CDO is optional.
  return property;
};