import http from 'hub-http/clients/apiClient';
import { QUOTE_SETTINGS } from '../constants/quoteSettings';
import { getQuickFetchOrMakeRequest } from '../earlyRequester/getQuickFetchOrMakeRequest';
import { registerQuery } from 'data-fetching-client';
import { isTrue } from '../utils/isTrue';
export function fetchQuoteSettings() {
  return http.get(`/inbounddbquotes/v1/quote-settings`);
}
export async function fetchQuoteSetting(quoteSetting, earlyRequesterKey = '') {
  const fetch = () => http.get(`/inbounddbquotes/v1/quote-settings/setting-value/${quoteSetting}`);
  let data;
  if (earlyRequesterKey.length) {
    data = await getQuickFetchOrMakeRequest(earlyRequesterKey, fetch);
  } else {
    data = await fetch();
  }
  return data.quoteSettingValue;
}
export function updateQuoteSetting({
  key,
  value
}) {
  return http.put('/inbounddbquotes/v1/quote-settings', {
    data: {
      quoteSettingKey: key,
      value
    }
  });
}
const getQuoteEmailSettings = () => {
  return fetchQuoteSettings().then(quoteSettings => {
    return {
      defaultFromEmail: quoteSettings[QUOTE_SETTINGS.DEFAULT_FROM_EMAIL],
      canSendFromOtherEmails: isTrue(quoteSettings[QUOTE_SETTINGS.ALLOW_OTHER_EMAILS])
    };
  });
};
export const fetchQuoteEmailSettings = registerQuery({
  fieldName: 'emailSettings',
  fetcher: getQuoteEmailSettings
});