import { useMemo } from 'react';
import { buildPropertiesFormInitializationFailedState, buildPropertiesFormInitializingState, buildPropertiesFormInvalidState, buildPropertiesFormPendingState, buildPropertiesFormValidState } from '../utils/status';
import { conditionalOptionsValidator } from '../validators/conditionalOptionsValidator';
import { requiredValueValidator } from '../validators/requiredValueValidator';
import { propertyStateValidator } from '../validators/propertyStateValidator';
import { isInvalidValidationResult } from '../types/result';
import { validateNode } from '../utils/validation';
const initializingState = buildPropertiesFormInitializingState();
const failedInitializingState = buildPropertiesFormInitializationFailedState();
const validators = [requiredValueValidator, conditionalOptionsValidator, propertyStateValidator];
const createErrorMap = (validationResults, properties) => {
  // Group property names by error type as set to avoid duplicates,
  // else we risk multiple instances of a property in the form being counted for the same error.
  const errorToPropertyNames = validationResults.filter(isInvalidValidationResult).reduce((acc, {
    errorSources
  }) => {
    errorSources.forEach(({
      error,
      sourcePropertyName
    }) => {
      if (!acc.has(error)) {
        acc.set(error, new Set());
      }
      acc.get(error).add(sourcePropertyName);
    });
    return acc;
  }, new Map());
  return new Map(Array.from(errorToPropertyNames.entries()).map(([error, propertyNames]) => [error, Array.from(propertyNames).map(name => properties[name])]));
};
export function useFormValidation({
  activeRules,
  alwaysShowConditionalPropertyOptionsWithErrors,
  changedProperties,
  formLoading,
  formError,
  getFormValue,
  nodes,
  properties,
  propertyValidations
}) {
  const validationState = useMemo(() => {
    if (formLoading) {
      return initializingState;
    }
    if (formError) {
      return failedInitializingState;
    }
    const request = {
      activeRules,
      alwaysShowConditionalPropertyOptionsWithErrors,
      changedProperties,
      getFormValue,
      properties,
      propertyValidations
    };
    const validationResults = nodes.map(node => validateNode(node, request, validators));
    if (validationResults.some(result => result.status === 'PENDING')) {
      return buildPropertiesFormPendingState();
    }
    const errors = createErrorMap(validationResults, properties);
    return errors.size > 0 ? buildPropertiesFormInvalidState(errors) : buildPropertiesFormValidState();
  }, [formLoading, formError, properties, propertyValidations, activeRules, changedProperties, alwaysShowConditionalPropertyOptionsWithErrors, getFormValue, nodes]);
  return {
    validationState
  };
}