// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'hubspot.bender' or its corresp... Remove this comment to see the full error message
import bender from 'hubspot.bender';
import { useState, useEffect, useRef } from 'react';
import promiseDone from 'hs-promise-utils/promiseDone';
import { fetchWorkspaceMinVersion } from '../clients/fetchWorkspaceMinVersion';
import { reportError } from 'conversations-error-reporting/error-reporting/reportError';
const THIRTY_MINUTES = 1000 * 60 * 30;

/**
 * https://overreacted.io/making-setinterval-declarative-with-react-hooks/
 */
export const useInterval = (func, delay) => {
  const intervalFunc = useRef(undefined);
  useEffect(() => {
    intervalFunc.current = func;
  }, [func]);
  useEffect(() => {
    let intervalId = null;
    if (delay !== null) intervalId = setInterval(() => {
      // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      intervalFunc.current();
    }, delay);
    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [delay]);
};
const getStaticVersionNumber = versionString => {
  if (versionString && versionString.indexOf('static-') === 0) {
    return versionString.split('static-')[1];
  }
  return 'static';
};
const shouldPromptUserToRefresh = (currentVersion, minVersion) => {
  return !isNaN(currentVersion) && !isNaN(minVersion) && currentVersion < minVersion;
};
export const useWorkspaceVersionCheck = workspaceName => {
  const currentWorkspaceVersion = getStaticVersionNumber(bender.currentProjectVersion);
  const isLocal = currentWorkspaceVersion === 'static';
  const [shouldPrompt, setShouldPrompt] = useState(false);
  const intervalLength = isLocal || shouldPrompt ? null : THIRTY_MINUTES;
  useInterval(() => {
    promiseDone(fetchWorkspaceMinVersion(workspaceName).then(workspaceMinVersion => {
      if (shouldPromptUserToRefresh(parseFloat(currentWorkspaceVersion),
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'JSON' is not assignable to param... Remove this comment to see the full error message
      parseFloat(workspaceMinVersion))) {
        setShouldPrompt(true);
      }
    }).catch(error => {
      reportError({
        error,
        tags: {
          isVisibleToUser: false
        }
      });
    }));
  }, intervalLength);
  return {
    shouldPrompt,
    setShouldPrompt
  };
};