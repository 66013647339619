import { ASSOCIATED_OBJECTS_CARD, BET_CUSTOMER_SUMMARY_CARD, CONVERSATIONS_CONTACT_TICKETS_CARD, CONVERSATIONS_DEAL_CARD, CONVERSATIONS_THREAD_TICKET_CARD, FEEDBACK_CARD, PAST_CONVERSATIONS_CARD, PROPERTIES_CARD, QUALIFIED_LEADS_CARD, TOOL_LINKS_CARD, ZORSE_CONVERSATIONS_TAXONOMY_CARD, ZORSE_MOVE_INBOX_CARD, ZORSE_REP_CHAT_ASSIGNMENT_CARD
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'cust... Remove this comment to see the full error message
} from 'customer-data-sidebar/universal/UniversalSidebarCardTypes';
import PastFeedbackCard from 'card-past-feedback-lib';
import AssociatedObjectsCardTitleUniversalSidebarDataProvider from 'card-associated-objects-lib-legacy/card/title/AssociatedObjectsCardTitleUniversalSidebarDataProvider';
import AsyncAssociatedObjectsCardContent from 'card-associated-objects-lib-legacy/card/AsyncAssociatedObjectsCardContent';
import PastConversationsCardTitle from '../cards/PastConversationsCardTitle';
import ThreadTicketCardTitle from '../cards/ThreadTicketCardTitle';
import { QualifiedLeadsSidebarCardTitle } from 'qualified-leads-embedded-lib';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'rep-... Remove this comment to see the full error message
import { RepChatAssignmentCardTitle } from 'rep-chat-assignment-lib';
import { ToolLinksCardTitle, ConversationsTaxonomyCardTitle } from 'zorse-crm-components-lib';
import { MoveTicketToSupportCardHeader as ZorseMoveInboxCardTitle } from 'conversation-relocation-lib';
import { makeAsyncCard } from './makeAsyncCard';
import { makeAsyncTitle } from './makeAsyncTitle';
import { reportError } from 'conversations-error-reporting/error-reporting/reportError';
import ReadOnlyTicketCardTitle from '../ReadOnlyTicketCardTitle';
export const getSidebarCards = ({
  shouldRenderTicketCards,
  isUngatedForLimitedTicketFunction
}) => {
  let conversationsContactTicketsCard = null;
  let threadTicketCard = null;
  if (shouldRenderTicketCards) {
    conversationsContactTicketsCard = {
      Content: makeAsyncCard({
        loader: () => import( /* webpackChunkName: "contact-tickets-card-content" */'conversations-inbox-ui/crm-sidebar-cards/cards/ContactTicketsCardContent').catch(error => {
          reportError({
            error
          });
        })
      }),
      Title: makeAsyncTitle({
        loader: () => import( /* webpackChunkName: "contact-tickets-card-title" */'conversations-inbox-ui/crm-sidebar-cards/cards/ContactTicketsCardTitle').catch(error => {
          reportError({
            error
          });
        })
      })
    };
    if (isUngatedForLimitedTicketFunction) {
      threadTicketCard = {
        Content: makeAsyncCard({
          loader: () => import( /* webpackChunkName: "thread-ticket-card-content" */'conversations-inbox-ui/crm-sidebar-cards/cards/ReadOnlyTicketCardContent').catch(error => {
            reportError({
              error
            });
          })
        }),
        Title: ReadOnlyTicketCardTitle
      };
    } else {
      threadTicketCard = {
        Content: makeAsyncCard({
          loader: () => import( /* webpackChunkName: "thread-ticket-card-content" */'conversations-inbox-ui/crm-sidebar-cards/cards/ThreadTicketCardContent').catch(error => {
            reportError({
              error
            });
          })
        }),
        Title: ThreadTicketCardTitle
      };
    }
  }
  return {
    [ASSOCIATED_OBJECTS_CARD]: {
      Content: AsyncAssociatedObjectsCardContent,
      Title: AssociatedObjectsCardTitleUniversalSidebarDataProvider
    },
    [CONVERSATIONS_CONTACT_TICKETS_CARD]: conversationsContactTicketsCard,
    [CONVERSATIONS_DEAL_CARD]: {
      Content: makeAsyncCard({
        loader: () => import( /* webpackChunkName: "deal-conversations-card-content" */'conversations-inbox-ui/crm-sidebar-cards/cards/DealConversationsCardContent').catch(error => {
          reportError({
            error
          });
        })
      }),
      Title: makeAsyncTitle({
        loader: () => import( /* webpackChunkName: "deal-conversations-card-title" */'conversations-inbox-ui/crm-sidebar-cards/cards/DealConversationsCardTitle').catch(error => {
          reportError({
            error
          });
        })
      })
    },
    [CONVERSATIONS_THREAD_TICKET_CARD]: threadTicketCard,
    [BET_CUSTOMER_SUMMARY_CARD]: {
      Content: makeAsyncCard({
        loader: () => import( /* webpackChunkName: "customer-summary-card-content" */'conversations-inbox-ui/crm-sidebar-cards/cards/CustomerSummaryCardContent').catch(error => {
          reportError({
            error
          });
        })
      }),
      Title: makeAsyncTitle({
        loader: () => import(
        // @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'BizO... Remove this comment to see the full error message
        /* webpackChunkName: "customer-summary-card-title" */
        'BizOpsCrmUIComponents/components/customerSummaryCard/CustomerSummaryCardTitle').catch(error => {
          reportError({
            error
          });
        })
      })
    },
    [FEEDBACK_CARD]: PastFeedbackCard,
    [PAST_CONVERSATIONS_CARD]: {
      Content: makeAsyncCard({
        loader: () => import( /* webpackChunkName: "past-conversations-card-content" */'conversations-inbox-ui/crm-sidebar-cards/cards/PastConversationsCardContent').catch(error => {
          reportError({
            error
          });
        })
      }),
      Title: PastConversationsCardTitle
    },
    [PROPERTIES_CARD]: {
      Content: makeAsyncCard({
        loader: () => import( /* webpackChunkName: "properties-card" */'card-properties-lib').then(card => card.default.Content).catch(error => {
          reportError({
            error
          });
        })
      }),
      Title: makeAsyncTitle({
        loader: () => import( /* webpackChunkName: "properties-card" */'card-properties-lib').then(card => card.default.Title).catch(error => {
          reportError({
            error
          });
        })
      })
    },
    [QUALIFIED_LEADS_CARD]: {
      Content: makeAsyncCard({
        loader: () => import( /* webpackChunkName: "qualified-leads-card-content" */'conversations-inbox-ui/crm-sidebar-cards/cards/QualifiedLeadsCardContent').catch(error => {
          reportError({
            error
          });
        })
      }),
      Title: QualifiedLeadsSidebarCardTitle
    },
    [ZORSE_CONVERSATIONS_TAXONOMY_CARD]: {
      Content: makeAsyncCard({
        loader: () => import( /* webpackChunkName: "conversations-taxonomy-card-content" */'conversations-inbox-ui/crm-sidebar-cards/cards/ZorseConversationsTaxonomyCardContent').catch(error => {
          reportError({
            error
          });
        })
      }),
      Title: ConversationsTaxonomyCardTitle
    },
    [ZORSE_MOVE_INBOX_CARD]: {
      Content: makeAsyncCard({
        loader: () => import( /* webpackChunkName: "move-inbox-card-content" */'conversations-inbox-ui/crm-sidebar-cards/cards/ZorseMoveInboxCardContent').catch(error => {
          reportError({
            error
          });
        })
      }),
      Title: ZorseMoveInboxCardTitle
    },
    [ZORSE_REP_CHAT_ASSIGNMENT_CARD]: {
      Content: makeAsyncCard({
        loader: () => import( /* webpackChunkName: "rep-chat-assignment-card-content"*/'conversations-inbox-ui/crm-sidebar-cards/cards/RepChatAssignmentCardContent').catch(error => {
          reportError({
            error
          });
        })
      }),
      Title: RepChatAssignmentCardTitle
    },
    [TOOL_LINKS_CARD]: {
      Content: makeAsyncCard({
        loader: () => import( /* webpackChunkName: "tool-links-card-content" */'conversations-inbox-ui/crm-sidebar-cards/cards/ToolLinksCardContent').catch(error => {
          reportError({
            error
          });
        })
      }),
      Title: ToolLinksCardTitle
    }
  };
};