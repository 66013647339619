import { useState, useEffect, useMemo } from 'react';
import Raven from 'raven-js';
import debounce from 'transmute/debounce';
export const useEmailPreview = (loadEmailPreview, bodyValue) => {
  const [emailPreview, setEmailPreview] = useState({
    html: ''
  });
  const [emailPreviewLoading, setEmailPreviewLoading] = useState(false);
  const [emailPreviewError, setEmailPreviewError] = useState(false);
  const debouncedLoadEmailPreview = useMemo(() => {
    return debounce(300, body => {
      setEmailPreview({
        html: ''
      });
      setEmailPreviewError(false);
      setEmailPreviewLoading(true);
      loadEmailPreview({
        body
      }).then(result => {
        setEmailPreview(result);
      }).catch(error => {
        setEmailPreviewError(true);
        Raven.captureException(error);
      }).finally(() => {
        setEmailPreviewLoading(false);
      });
    });
  }, [loadEmailPreview]);
  useEffect(() => {
    debouncedLoadEmailPreview(bodyValue);
  }, [bodyValue, debouncedLoadEmailPreview]);
  return {
    emailPreview,
    emailPreviewLoading,
    emailPreviewError
  };
};