import { TOPIC_NAMES } from 'crm-message-bus/types/MessageTopics';
import { useSendCrmMessageTopic } from 'crm-message-bus/useSendCrmMessageTopic';
import { useCallback, useContext, useEffect, useRef } from 'react';
import uniqueId from 'transmute/uniqueId';
import FloatingAlertStore from 'UIComponents/alert/FloatingAlertStore';
import { useGetAdditionalPropertyValue } from '../v2/hooks/useGetAdditionalPropertyValue';
import { useObjectId } from '../v2/hooks/useObjectId';
import { useObjectTypeId } from '../v2/hooks/useObjectTypeId';
import { useProperty } from '../v2/hooks/useProperty';
import { EDIT_INPUT_MODE } from '../v2/types/PropertyInputV2Component';
import { canValidateImmediately } from '../validation/utils/canValidateImmediately';
import { buildRefreshAlert, buildRetryAlert } from './alerts';
import { AutosaveContext } from './AutosaveContext';
import { getPropertyAutosaveEvent } from './getPropertyAutosaveEvent';
import { useHasAutosave } from './useHasAutosave';
import { useGetIsFocused } from '../v2/hooks/useGetIsFocused';
const getShouldAutosaveOnValidationChange = ({
  property,
  isOpen,
  isFocused
}) => {
  const propertyAutosaveEvent = getPropertyAutosaveEvent(property);
  if (propertyAutosaveEvent === 'close') {
    return !isOpen;
  }
  if (propertyAutosaveEvent === 'blur') {
    return !isFocused || canValidateImmediately(property);
  }
  const __never = propertyAutosaveEvent;
  return false;
};
const buildPropertyUpdateRequest = (objectTypeId, objectId, edits) => {
  return {
    objectTypeId,
    objectId: Number(objectId),
    properties: Object.entries(edits).map(([name, value]) => ({
      name,
      value
    }))
  };
};
export const useAutosave = ({
  onBlur,
  inputMode,
  onChange,
  onOpenChange,
  onSave,
  onValidationChange,
  enableAutosave,
  use
}) => {
  const autosaveContext = useContext(AutosaveContext);
  const objectId = useObjectId();
  const objectTypeId = useObjectTypeId();
  const property = useProperty();
  const hasAutosave = useHasAutosave();
  const getAdditionalPropertyValue = useGetAdditionalPropertyValue();
  const sendUpdateObjectsMessage = useSendCrmMessageTopic(TOPIC_NAMES.UPDATE_OBJECTS);
  const propertyAutosaveEvent = getPropertyAutosaveEvent(property);
  const getIsFocused = useGetIsFocused();

  // this defaults to true if use === 'table-cell' because in a table cell, the first
  // `onOpenChange` function  does not fire when clicking into a table cell formatter.
  const isOpenRef = useRef(use === 'table-cell');
  const pendingEditsRef = useRef({});
  const previousSavedValuesRef = useRef({});
  const validationRef = useRef(undefined);

  // We are storing onChange in a ref because when a save happens, we create a
  // callback that will be run on undo. Without the ref, that callback was being
  // instantiated with an old version of onChange, and was reverting to the
  // incorrect value with multiple undo's. Hence, the ref
  const onChangeRef = useRef(onChange);
  useEffect(() => {
    onChangeRef.current = onChange;
  }, [onChange]);
  const attemptSave = useCallback(() => {
    var _validationRef$curren;
    const hasEdits = Object.keys(pendingEditsRef.current).length > 0;
    if (!hasAutosave || !enableAutosave || !autosaveContext || inputMode !== EDIT_INPUT_MODE || !objectId || !((_validationRef$curren = validationRef.current) !== null && _validationRef$curren !== void 0 && _validationRef$curren.saveable) || !hasEdits) {
      return;
    }
    const request = buildPropertyUpdateRequest(objectTypeId, objectId, pendingEditsRef.current);
    pendingEditsRef.current = {};
    const previousSavedValuesSnapshot = previousSavedValuesRef.current;
    previousSavedValuesRef.current = {};
    const handleUndo = () => {
      onChangeRef.current(previousSavedValuesSnapshot);
    };
    const handleSave = () => {
      return autosaveContext.save(request, handleUndo).then(updates => {
        const messageBusPayload = Object.keys(updates).map(id => ({
          objectId: id,
          objectTypeId,
          propertyNames: Object.keys(updates[id])
        }));
        sendUpdateObjectsMessage(messageBusPayload, {
          sourceId: 'customer-data-properties'
        });
        onSave(updates);
      });
    };
    handleSave().catch(error => {
      const retryAlertId = uniqueId('autosave');
      FloatingAlertStore.addAlert(buildRetryAlert({
        id: retryAlertId,
        __error: error,
        onRetry: () => {
          FloatingAlertStore.removeAlert(retryAlertId);
          handleSave().catch(retryError => {
            FloatingAlertStore.addAlert(buildRefreshAlert({
              __error: retryError
            }));
          });
        }
      }));
    });
  }, [autosaveContext, inputMode, objectId, objectTypeId, hasAutosave, enableAutosave, sendUpdateObjectsMessage, onSave]);
  const wrappedOnBlur = useCallback(evt => {
    onBlur(evt);
    if (propertyAutosaveEvent === 'blur') {
      attemptSave();
    }
  }, [propertyAutosaveEvent, attemptSave, onBlur]);
  const wrappedOnChange = useCallback(changes => {
    Object.keys(changes).forEach(propertyName => {
      pendingEditsRef.current[propertyName] = changes[propertyName];
      if (enableAutosave) {
        if (!Object.prototype.hasOwnProperty.call(previousSavedValuesRef.current, propertyName)) {
          previousSavedValuesRef.current[propertyName] = getAdditionalPropertyValue(propertyName, objectId, objectTypeId);
        }
      }
    });
    onChange(changes);
  }, [enableAutosave, getAdditionalPropertyValue, objectId, objectTypeId, onChange]);
  const wrappedOnValidationChange = useCallback(validation => {
    validationRef.current = validation;
    onValidationChange(validation);
    const shouldAutosaveOnValidationChange = getShouldAutosaveOnValidationChange({
      property,
      isOpen: isOpenRef.current,
      isFocused: getIsFocused()
    });
    if (shouldAutosaveOnValidationChange) {
      attemptSave();
    }
  }, [onValidationChange, property, getIsFocused, attemptSave]);
  useEffect(
  // Save on unmount to handle annoying cases like index table ENTER key handler
  // where we may not have saved before unmounting
  () => () => {
    attemptSave();
  }, [attemptSave]);
  const handleBeforeUnload = useCallback(e => {
    if (enableAutosave && Object.keys(pendingEditsRef.current).length > 0) {
      e.preventDefault();
      e.returnValue = true;
    }
  }, [enableAutosave]);
  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [handleBeforeUnload]);
  const wrappedOnOpenChange = useCallback(event => {
    onOpenChange(event);
    isOpenRef.current = event.target.value;
    if (propertyAutosaveEvent === 'close' && !isOpenRef.current) {
      attemptSave();
    }
  }, [attemptSave, onOpenChange, propertyAutosaveEvent]);
  return {
    onBlur: wrappedOnBlur,
    onChange: wrappedOnChange,
    onOpenChange: wrappedOnOpenChange,
    onValidationChange: wrappedOnValidationChange,
    attemptAutosave: attemptSave
  };
};