import { useEffect } from 'react';
import devLogger from 'react-utils/devLogger';
import { useApolloClient } from '@apollo/client';
import Raven from 'raven-js';
import { isEnum } from 'customer-data-objects/property/PropertyIdentifier';
import { Metrics } from './Metrics';
import { EDIT_INPUT_MODE } from '../v2/types/PropertyInputV2Component';
import { useTheme } from 'styled-components';
import UIFloatingFormControl from 'UIComponents/form/UIFloatingFormControl';

/**
 * This data structure defines the supported properties and their replacements
 * for the select input component as part of the Trellis migration.
 *
 * It was created based on the prop changes outlined in the Trellis Migration Guide:
 * https://product.hubteam.com/docs/trellis-migration-guide/static/select-migration.html#prop-changes
 *
 * Each property includes metadata indicating whether it is supported and, if applicable,
 * the recommended replacement property.
 */
const selectInputPropsMetadata = {
  options: {
    supported: true,
    replacement: 'options, but has a different type'
  },
  allowCreate: {
    supported: false,
    replacement: null
  },
  anchorType: {
    supported: false,
    replacement: null
  },
  autoComplete: {
    supported: false,
    replacement: null
  },
  autoFocus: {
    supported: false,
    replacement: null
  },
  autoload: {
    supported: false,
    replacement: null
  },
  ButtonContent: {
    supported: false,
    replacement: null
  },
  buttonContentProps: {
    supported: false,
    replacement: null
  },
  buttonSize: {
    supported: true,
    replacement: 'referenceSize'
  },
  buttonUse: {
    supported: true,
    replacement: 'referenceVariant'
  },
  cache: {
    supported: false,
    replacement: null
  },
  caretRenderer: {
    supported: false,
    replacement: null
  },
  clearable: {
    supported: true,
    replacement: 'clearable'
  },
  closeOnTargetLeave: {
    supported: false,
    replacement: null
  },
  defaultOpen: {
    supported: false,
    replacement: 'Use open'
  },
  defaultValue: {
    supported: false,
    replacement: 'Use selectedValues'
  },
  delimiter: {
    supported: false,
    replacement: null
  },
  disabled: {
    supported: true,
    replacement: null
  },
  dropdownFooter: {
    supported: false,
    replacement: 'See design recommendation below'
  },
  error: {
    supported: true,
    replacement: null
  },
  filterOptions: {
    supported: false,
    replacement: null
  },
  id: {
    supported: true,
    replacement: null
  },
  ignoreAccents: {
    supported: false,
    replacement: 'Automatically ignores accents when searching'
  },
  ignoreCase: {
    supported: false,
    replacement: 'Automatically is case-insensitive when searching'
  },
  inputComponent: {
    supported: false,
    replacement: null
  },
  inputRef: {
    supported: false,
    replacement: null
  },
  inputValue: {
    supported: true,
    replacement: 'inputValue'
  },
  isLoading: {
    supported: false,
    replacement: 'Automatically renders a LoadingSpinner if options are not loaded'
  },
  itemComponent: {
    supported: false,
    replacement: null
  },
  loadMoreComponent: {
    supported: false,
    replacement: null
  },
  loadOptions: {
    supported: true,
    replacement: 'loadAsyncOptions'
  },
  menuRenderer: {
    supported: false,
    replacement: null
  },
  menuStyle: {
    supported: false,
    replacement: null
  },
  menuWidth: {
    supported: true,
    replacement: 'menuInlineSize'
  },
  minimumSearchCount: {
    supported: false,
    replacement: null
  },
  multi: {
    supported: true,
    replacement: null
  },
  multiCollapseLimit: {
    supported: false,
    replacement: null
  },
  noResultsText: {
    supported: true,
    replacement: 'noResultsText'
  },
  onChange: {
    supported: false,
    replacement: null
  },
  onInputKeyDown: {
    supported: false,
    replacement: null
  },
  onInputChange: {
    supported: false,
    replacement: null
  },
  onInputValueChange: {
    supported: true,
    replacement: 'onInputValueChange'
  },
  onOpenChange: {
    supported: true,
    replacement: 'onOpenChange'
  },
  onPaste: {
    supported: false,
    replacement: null
  },
  onSelectedOptionChange: {
    supported: true,
    replacement: 'onSelectedOptionChange'
  },
  open: {
    supported: true,
    replacement: null
  },
  optionComponent: {
    supported: false,
    replacement: null
  },
  optionRenderer: {
    supported: false,
    replacement: null
  },
  optionRendererIgnoresSpecialOptions: {
    supported: false,
    replacement: null
  },
  placeholder: {
    supported: true,
    replacement: 'selectedItemsPlaceholder'
  },
  placement: {
    supported: true,
    replacement: null
  },
  promptTextCreator: {
    supported: false,
    replacement: null
  },
  readOnly: {
    supported: false,
    replacement: null
  },
  resetValue: {
    supported: false,
    replacement: null
  },
  searchable: {
    supported: true,
    replacement: null
  },
  searchPlaceholder: {
    supported: true,
    replacement: 'textInputPlaceholder'
  },
  searchPromptText: {
    supported: false,
    replacement: null
  },
  splitRegex: {
    supported: false,
    replacement: null
  },
  tabIndex: {
    supported: false,
    replacement: null
  },
  value: {
    supported: false,
    replacement: 'Use selectedValues'
  },
  valueComponent: {
    supported: false,
    replacement: null
  },
  valueRenderer: {
    supported: false,
    replacement: null
  },
  _forcePlaceholder: {
    supported: false,
    replacement: null
  }
};
const useApolloClientCanary = ({
  objectTypeId,
  property
}) => {
  // Calling `useApolloClient` outside of an `ApolloProvider` context is dangerous because it will throw:
  // ```
  // Uncaught Invariant Violation: An error occurred! For more details, see the full error text at https://go.apollo.dev/c/err#%7B%22version%22%3A%223.11.4%22%2C%22message%22%3A50%2C%22args%22%3A%5B%5D%7D
  // ```
  let apolloClient = undefined;
  try {
    // Dear React: it's okay to call this inside a try/catch, because _calling it can throw_ and we need to catch it
    // eslint-disable-next-line react-hooks/rules-of-hooks
    apolloClient = useApolloClient();
  } catch (error) {
    // Do nothing; the canary below will log the failure
  }
  useEffect(() => {
    if (!apolloClient) {
      devLogger.warn({
        message: `propertyinputv2-apollo-client-canary: rendered without an <ApolloProvider>: objectTypeId=${objectTypeId}, propertyName=${property.name}`,
        key: 'propertyinputv2-apollo-client-canary'
      });
      Metrics.counter('propertyinputv2-apollo-client-canary').increment();
      // @gmcnaughton Sep 16, 2024 - disabled the Sentry until we can fix SalesSidebar
      // (gmail/outlook extension), which is a known omission. they were getting
      // paged due to the quantity of errors logged.
      // Raven.captureException(
      //   new Error('propertyinputv2 apollo client canary'),
      //   {
      //     extra: {
      //       objectTypeId,
      //       propertyName: property.name,
      //     },
      //   }
      // );
    }
  }, [apolloClient, objectTypeId, property.name]);
};

// Track usages of autosave props since we only want to support this in limited UIs
// for the near future. If someone starts using it unexpectedly, we want to know!
const useAutosaveCanary = ({
  enableAutosave,
  onSave
}) => {
  useEffect(() => {
    if (enableAutosave) {
      Metrics.counter('propertyinputv2-enableautosave').increment();
    }
    if (onSave) {
      Metrics.counter('propertyinputv2-onsave').increment();
    }

    // Not going to cause a direct error, but means someone is giving us a useless handler
    if (onSave && !enableAutosave) {
      devLogger.warn({
        message: 'propertyinputv2-onsave-without-enableautosave',
        key: 'propertyinputv2-onsave-without-enableautosave'
      });
      Metrics.counter('propertyinputv2-onsave-without-enableautosave').increment();
    }
  }, [enableAutosave, onSave]);
};
const useDataSensitivityCanary = ({
  objectTypeId,
  property
}) => {
  useEffect(() => {
    if (property.dataSensitivity === undefined) {
      devLogger.warn({
        message: `propertyinputv2-data-sensitivity-canary: received a property without dataSensitivity: objectTypeId=${objectTypeId}, propertyName=${property.name}`,
        key: 'propertyinputv2-data-sensitivity-canary'
      });
      Metrics.counter('propertyinputv2-data-sensitivity-canary').increment();
      // sam swindell July 15, 2024 - turned off to stop the flood until we have
      // fixed some known cases. Worried about COGS when sending too many sentries!
      // Raven.captureException(
      //   new Error('propertyinputv2 dataSensitivity canary'),
      //   {
      //     extra: {
      //       objectTypeId,
      //       propertyName: property.name,
      //     },
      //   }
      // );
    }
  }, [objectTypeId, property.dataSensitivity, property.name]);
};
const useDeprecatedPropsCanary = ({
  getIsUngated
}) => {
  useEffect(() => {
    if (getIsUngated) {
      Metrics.counter('propertyinputv2-getisungated').increment();
    }

    // Intentionally only run once on mount to avoid noise and poorly memoized apps from
    // skewing these metrics too heavily.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
const useObjectIdCanary = ({
  objectTypeId,
  objectId,
  inputMode = EDIT_INPUT_MODE,
  property
}) => {
  useEffect(() => {
    if (inputMode === EDIT_INPUT_MODE && !objectId) {
      devLogger.warn({
        message: `propertyinputv2-objectid-canary: received an invalid objectId/inputMode (objectId is required when inputMode = "edit"): objectTypeId=${objectTypeId}, objectId=${objectId}, inputMode=${inputMode}, propertyName=${property.name}`,
        key: 'propertyinputv2-objectid-canary'
      });
      Metrics.counter('propertyinputv2-objectid-canary').increment();
      Raven.capturePageEvent('propertyinputv2 objectid canary', {
        extra: {
          objectTypeId,
          objectId,
          inputMode,
          propertyName: property.name
        }
      });
    } else if (inputMode !== EDIT_INPUT_MODE && objectId) {
      devLogger.warn({
        message: `propertyinputv2-objectid-nonedit-canary: received an invalid objectId/inputMode (objectId is not expected when inputMode != "edit"): objectTypeId=${objectTypeId}, objectId=${objectId}, inputMode=${inputMode}, propertyName=${property.name}`,
        key: 'propertyinputv2-objectid-nonedit-canary'
      });
      Metrics.counter('propertyinputv2-objectid-nonedit-canary').increment();
      Raven.capturePageEvent('propertyinputv2 objectid nonedit canary', {
        extra: {
          objectTypeId,
          objectId,
          inputMode,
          propertyName: property.name
        }
      });
    }
  }, [objectTypeId, objectId, inputMode, property.name]);
};
const useOnOpenChangeInputPropsCanary = ({
  inputProps
}) => {
  useEffect(() => {
    if (inputProps !== null && inputProps !== void 0 && inputProps.onOpenChange) {
      Metrics.counter('propertyinputv2-onOpenChange-inputProps').increment();
      devLogger.warn({
        message: `propertyinputv2-onOpenChange-inputProps: onOpenChange was passed to PropertyInputV2 inside of inputProps, please pass it as as a top level prop instead: <PropertyInputV2 onOpenChange={...`,
        key: 'propertyinputv2-onOpenChange-inputProps'
      });
    }
    // Intentionally only run once on mount to avoid noise and poorly memoized apps from
    // skewing these metrics too heavily.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
const useProductMetricsCanary = ({
  inputMode,
  use
}) => {
  useEffect(() => {
    if (inputMode) {
      Metrics.counter('propertyinputv2-inputmode', {
        mode: inputMode
      }).increment();
    }
    if (use) {
      Metrics.counter('propertyinputv2-use', {
        use
      }).increment();
    }

    // Intentionally only run once on mount to avoid noise and poorly memoized apps from
    // skewing these metrics too heavily.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
export const useThemeProviderCanary = () => {
  const theme = useTheme();
  useEffect(() => {
    try {
      // Trying to access a theme property that doesn't exist will throw an error
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      theme.typography['body-100'];
    } catch (_) {
      devLogger.warn({
        message: `propertyinputv2-missing-trellis-theme-provider-canary: rendered without Trellis ThemeProvider`,
        key: 'propertyinputv2-missing-trellis-theme-provider-canary'
      });
      Metrics.counter('propertyinputv2-missing-trellis-theme-provider-canary').increment();
    }
  }, [theme]);
};
const useTrackFloatingFormControlCanary = ({
  FormControl,
  use
}) => {
  useEffect(() => {
    if (use === 'floating' || FormControl === UIFloatingFormControl) {
      Metrics.counter('propertyinputv2-floating-form-control-usage', {
        prop: use === 'floating' ? 'use' : 'FormControl'
      }).increment();
    }
  }, [FormControl, use]);
};
const useTrackSelectInputPropsCanary = ({
  inputProps,
  property
}) => {
  useEffect(() => {
    try {
      const isSelectInput = property.fieldType === 'select' || isEnum(property) || property.name === 'hs_tax_category';
      if (isSelectInput && typeof inputProps === 'object') {
        Object.keys(inputProps).forEach(key => {
          const selectInputProp = selectInputPropsMetadata[key];
          if (selectInputProp && (!selectInputProp.supported || !!selectInputProp.replacement && key !== selectInputProp.replacement)) {
            Metrics.counter('propertyinputv2-select-input-props-usage', {
              inputProp: key,
              propertyFieldType: property.fieldType,
              status: !selectInputProp.supported ? 'unsupported' : 'needs-replacement'
            }).increment();
          }
        });
      }
    } catch (_) {
      // Do nothing; this is a canary and we don't want to crash the app
    }
  }, [inputProps, property]);
};
const useValueCanary = ({
  objectTypeId,
  property,
  value
}) => {
  useEffect(() => {
    if (value !== null && value !== undefined && typeof value !== 'string') {
      devLogger.warn({
        message: `propertyinputv2-value-canary: received an invalid value type (must be string, null, or undefined): objectTypeId=${objectTypeId}, propertyName=${property.name}, valueType=${typeof value}`,
        key: 'propertyinputv2-value-canary'
      });
      Metrics.counter('propertyinputv2-value-canary').increment();
      Raven.captureException(new Error('propertyinputv2 value canary'), {
        extra: {
          objectTypeId,
          propertyName: property.name,
          valueType: typeof value
        }
      });
    }
  }, [objectTypeId, property.name, value]);
};
export const usePropertyInputCanaryMetrics = props => {
  useApolloClientCanary(props);
  useAutosaveCanary(props);
  useDataSensitivityCanary(props);
  useDeprecatedPropsCanary(props);
  useObjectIdCanary(props);
  useOnOpenChangeInputPropsCanary(props);
  useProductMetricsCanary(props);
  useThemeProviderCanary();
  useTrackFloatingFormControlCanary(props);
  useTrackSelectInputPropsCanary(props);
  useValueCanary(props);
};