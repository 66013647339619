export const buildPropertiesFormInitializingState = () => ({
  validationStatus: 'INITIALIZING',
  initializing: true,
  initializationFailed: false,
  loading: false,
  invalid: false,
  saveable: false,
  errors: new Map()
});
export const buildPropertiesFormInitializationFailedState = () => ({
  validationStatus: 'INITIALIZATION_FAILED',
  initializing: false,
  initializationFailed: true,
  loading: false,
  invalid: false,
  saveable: false,
  errors: new Map()
});
export const buildPropertiesFormPendingState = () => ({
  validationStatus: 'PENDING',
  initializing: false,
  initializationFailed: false,
  loading: true,
  invalid: false,
  saveable: false,
  errors: new Map()
});
export const buildPropertiesFormValidState = () => ({
  validationStatus: 'VALID',
  initializing: false,
  initializationFailed: false,
  loading: false,
  invalid: false,
  saveable: true,
  errors: new Map()
});
export const buildPropertiesFormInvalidState = errors => ({
  validationStatus: 'INVALID',
  initializing: false,
  initializationFailed: false,
  loading: false,
  invalid: true,
  saveable: false,
  errors
});