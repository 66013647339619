import { companyNewsParser } from './actionParsers/companyNews';
import { COMMUNICATION_SUMMARY, COMPANY_NEWS, COMPANY_TECH,
// OBJECT_ASSOCIATIONS, // TODO: add constant when in copilot-toolkit
OBJECT_ENGAGEMENTS_SUMMARY, OBJECT_SUMMARY, OBJECT_UPDATE, SUMMARIZE_ACTIVITY_FEED, WORKFLOW_CREATE } from 'copilot-toolkit/constants/CopilotInAppActionTypes';
import { objectSummaryParser } from './actionParsers/objectSummary';
import { objectEngagementsSummaryParser } from './actionParsers/objectEngagementsSummary';
import { communicationSummaryParser } from './actionParsers/communicationSummary';
import { companyTechParser } from './actionParsers/companyTech';
import { objectUpdateParser } from './actionParsers/objectUpdate';
import { summarizeActivityFeedParser } from './actionParsers/summarizeActivityFeedParser';
import { workflowCreateParser } from './actionParsers/workflowCreate';
export const getActionText = action => {
  const rawAction = action.raw_action;
  if (!rawAction) {
    return '';
  }
  switch (rawAction.intent) {
    case COMPANY_NEWS:
      return companyNewsParser(rawAction);
    case OBJECT_SUMMARY:
      return objectSummaryParser(rawAction);
    case 'object_associations':
      // TODO: add constant when in copilot-toolkit
      return objectEngagementsSummaryParser(rawAction);
    case OBJECT_ENGAGEMENTS_SUMMARY:
      return objectEngagementsSummaryParser(rawAction);
    case COMMUNICATION_SUMMARY:
      return communicationSummaryParser(rawAction);
    case COMPANY_TECH:
      return companyTechParser(rawAction);
    case OBJECT_UPDATE:
      return objectUpdateParser(rawAction);
    case SUMMARIZE_ACTIVITY_FEED:
      return summarizeActivityFeedParser(rawAction);
    case WORKFLOW_CREATE:
      return workflowCreateParser(rawAction);
    default:
      return '';
  }
};