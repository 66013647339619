import { COMMERCE_PAYMENT_TYPE_ID, COMPANY_TYPE_ID, CONTACT_TYPE_ID, DEAL_TYPE_ID, SUBSCRIPTION_TYPE_ID, TICKET_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
import { getEagerlyFetchPropertyList } from 'customer-data-property-utils/constants/EagerlyFetchPropertyList';

/**
 * Essential properties to fetch for each object type
 */
const ASSOCIATED_OBJECTS_CARD_REQUIRED_PROPERTY_NAMES = {
  [COMMERCE_PAYMENT_TYPE_ID]: ['hs_processor_type', 'hs_payment_method_type', 'js_reference_number', 'hs_payment_method_last_4', 'hs_payment_method_bank_or_issuer'],
  [COMPANY_TYPE_ID]: ['website', 'name', 'domain'],
  [CONTACT_TYPE_ID]: ['jobtitle', 'firstname', 'lastname', 'email'],
  [DEAL_TYPE_ID]: ['dealname'],
  [SUBSCRIPTION_TYPE_ID]: ['hs_name'],
  [TICKET_TYPE_ID]: ['closed_date', 'createdate', 'hubspot_owner_id', 'subject', 'content']
};
export const getRequiredPropertyNames = (objectTypeId, propertyNames) => {
  var _ASSOCIATED_OBJECTS_C;
  const hubSpotRequiredPropertyNamesForObjectType = getEagerlyFetchPropertyList(objectTypeId);
  const associatedObjectsCardRequiredPropertyNamesForObjectType = (_ASSOCIATED_OBJECTS_C = ASSOCIATED_OBJECTS_CARD_REQUIRED_PROPERTY_NAMES[objectTypeId]) !== null && _ASSOCIATED_OBJECTS_C !== void 0 ? _ASSOCIATED_OBJECTS_C : [];
  const requiredProperties = new Set([...hubSpotRequiredPropertyNamesForObjectType, ...associatedObjectsCardRequiredPropertyNamesForObjectType, ...propertyNames]);
  return Array.from(requiredProperties);
};