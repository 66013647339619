import { createInvalidResult } from '../utils/result';
export const propertyStateValidator = {
  validate: (node, request) => {
    const {
      propertyValidations
    } = request;
    const {
      propertyName
    } = node;
    const validationState = propertyValidations.get(propertyName);

    // If no property validation exists, then we consider it valid for now. This usually means that
    // the property has not been edited and we did not validate the initial value.
    if (!validationState) {
      return {
        status: 'VALID'
      };
    }
    if (validationState.loading) {
      return {
        status: 'PENDING'
      };
    }
    if (validationState.invalid) {
      return createInvalidResult('INVALID_PROPERTIES', propertyName);
    }
    return {
      status: 'VALID'
    };
  }
};