import { useMemo } from 'react';
import { getInitialSendersFromChannelInstance } from '../operators/getInitialSendersFromChannelInstance';
import { useSendFromChannelInstance } from '../../_internal/hooks/useSendFromChannelInstance';
export const useInitialSenders = ({
  inboxId,
  inReplyToMessage,
  channelId,
  userId,
  threadId,
  defaultSendFrom
}) => {
  const shouldDefer = !inboxId || !channelId;
  const {
    channelInstanceToSendFrom,
    isLoading
  } = useSendFromChannelInstance({
    channelId,
    deferred: shouldDefer,
    inboxId,
    inReplyToMessage,
    threadId
  });
  const areSendersLoading = !shouldDefer && isLoading;
  const senders = useMemo(() => {
    return getInitialSendersFromChannelInstance({
      channelId,
      channelInstance: channelInstanceToSendFrom,
      userId,
      defaultSendFrom
    });
  }, [channelId, channelInstanceToSendFrom, userId, defaultSendFrom]);
  return {
    senders,
    areSendersLoading
  };
};