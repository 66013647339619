import { useMemo } from 'react';
import { getIsDependentCpoProperty, isEligibleForConditionalPropertyOptions } from '../conditionalPropertyOptionsRules/utils';
import { getActivePropertyDefinitionWithFilteredOptions } from '../utils/getActivePropertyDefinitionWithFilteredOptions';
import { useAllPropertyValues } from './useAllPropertyValues';
import { useProperties } from './useProperties';
import { useConditionalPropertyOptionsRulesForObjectType } from './useConditionalPropertyOptionsRulesForObjectType';
import { getOwnProperty } from '../../utils/getOwnProperty';
export const useConditionalPropertyOptions = ({
  property,
  enableConditionalPropertyOptions = false,
  objectTypeId,
  objectId,
  getAdditionalPropertyValue,
  value
}) => {
  const isEligibleForCpo = isEligibleForConditionalPropertyOptions({
    enableConditionalPropertyOptions,
    property,
    objectTypeId
  });
  const {
    data: conditionalPropertyOptionsRules,
    loading: conditionalPropertyOptionsRulesLoading,
    error: conditionalPropertyOptionsRulesError
  } = useConditionalPropertyOptionsRulesForObjectType(objectTypeId, {
    skip: !isEligibleForCpo
  });
  const isDependentCpoProperty = getIsDependentCpoProperty(conditionalPropertyOptionsRules || [], property);
  const {
    data: properties,
    loading: propertiesLoading,
    error: propertiesError
  } = useProperties(objectTypeId, {
    skip: !isDependentCpoProperty
  });
  const {
    data: allPropertyValues,
    loading: allPropertyValuesLoading,
    error: allPropertyValuesError
  } = useAllPropertyValues({
    objectId,
    objectTypeId,
    skip: !isDependentCpoProperty
  });
  const conditionalPropertyOptionsRulesForProperty = useMemo(() => {
    return (conditionalPropertyOptionsRules || []).filter(rule => rule.dependentFieldName === property.name);
  }, [conditionalPropertyOptionsRules, property.name]);
  return useMemo(() => {
    if (conditionalPropertyOptionsRulesLoading || propertiesLoading || allPropertyValuesLoading) {
      return {
        loading: true,
        error: undefined,
        data: undefined
      };
    }
    const error = conditionalPropertyOptionsRulesError || propertiesError || allPropertyValuesError;
    if (error) {
      return {
        loading: false,
        error,
        data: undefined
      };
    }
    if (!isDependentCpoProperty || !isEligibleForCpo || properties === undefined) {
      return {
        loading: false,
        error: undefined,
        data: undefined
      };
    }

    // CPO limits to one controlling field per property, so we can safely assume the first
    // field will match all other fields
    const controllingFieldName = conditionalPropertyOptionsRulesForProperty[0].controllingField.fieldName;
    const controllingFieldPropertyDefinition = getOwnProperty(properties, controllingFieldName);
    const additionalPropertyValue = getAdditionalPropertyValue(controllingFieldName, objectId, objectTypeId);
    const controllingFieldCurrentValue = additionalPropertyValue !== undefined ? additionalPropertyValue : allPropertyValues ? getOwnProperty(allPropertyValues, controllingFieldName) : undefined;
    return {
      loading: false,
      error: undefined,
      data: getActivePropertyDefinitionWithFilteredOptions({
        conditionalPropertyOptionsRulesForProperty,
        controllingFieldCurrentValue,
        controllingFieldPropertyDefinition,
        originalPropertyDefinition: property,
        value
      })
    };
  }, [conditionalPropertyOptionsRulesLoading, propertiesLoading, allPropertyValuesLoading, conditionalPropertyOptionsRulesError, propertiesError, allPropertyValuesError, isDependentCpoProperty, isEligibleForCpo, properties, conditionalPropertyOptionsRulesForProperty, getAdditionalPropertyValue, objectId, objectTypeId, allPropertyValues, property, value]);
};