import { useMemo } from 'react';
import { applyConditionalPropertyRules } from './utils';
/**
 * This hook uses the Conditional Property Rules and current property values on the record
 * to determine if any Conditional Property dependents should be shown as dependents in the form.
 * Depending on an input's value in the form,
 *  - finds the related Conditional Property rules
 *  - adds dependents to the input in the form
 *
 * @returns {dependencies}: object with controlling property as key and an array of dependent properties as value
 * @returns {formProperties}: a tree of form properties along CP dependents
 *
 */
const useConditionalPropertyRules = ({
  nodes,
  changedProperties,
  alwaysShowConditionalProperties,
  conditionalPropertyDependencies
}) => {
  return useMemo(() => {
    if (Object.keys(conditionalPropertyDependencies).length > 0) {
      return applyConditionalPropertyRules(nodes, conditionalPropertyDependencies, changedProperties, alwaysShowConditionalProperties);
    }
    return nodes;
  }, [alwaysShowConditionalProperties, changedProperties, conditionalPropertyDependencies, nodes]);
};
export default useConditionalPropertyRules;