import { Slice } from 'prosemirror-model';
import { Plugin } from 'prosemirror-state';
import { linkify, linkifyTextNode } from './linkify';
function shouldTryToLinkify(nodeBefore, event) {
  const isAlreadyLink = nodeBefore.marks.some(mark => mark.type.name === 'link');
  return !isAlreadyLink && (event.key === ' ' || event.key.toLowerCase() === 'spacebar' || event.key.toLowerCase() === 'enter' || event.key === '\n');
}
export const AutoDetectLinkPlugin = new Plugin({
  props: {
    transformPasted: slice => {
      return new Slice(linkify(slice.content), slice.openStart, slice.openEnd);
    },
    handleKeyDown(view, event) {
      const {
        state,
        dispatch
      } = view;
      const {
        $from
      } = state.selection;
      const {
        nodeBefore
      } = $from;

      // Detect a ' ' or spacebar or Enter key press
      if (nodeBefore != null && shouldTryToLinkify(nodeBefore, event)) {
        const linkifiedTextNode = linkifyTextNode(nodeBefore);
        const didLinkify = linkifiedTextNode.length > 0 && linkifiedTextNode.some(node => node.marks.some(mark => mark.type.name === 'link'));
        if (didLinkify) {
          const textLength = nodeBefore.text == null ? 0 : nodeBefore.text.length;
          const replaceFrom = $from.pos - textLength;
          const replaceTo = $from.pos;
          const tr = state.tr.replaceWith(replaceFrom, replaceTo, linkifiedTextNode);
          dispatch(tr);
        }
      }
      return false; // Allow default handling for other keys
    }
  }
});