import { REPORTING_INSIGHTS_COPILOT_GENERATION } from '../../constants/CopilotInAppActionTypes';
export const reportingInsightsCopilotGeneration = action => {
  try {
    return Object.assign({}, action.promptText && {
      text: action.promptText
    }, {
      data: {
        intent: REPORTING_INSIGHTS_COPILOT_GENERATION,
        insightsGenerationRequestString: JSON.stringify({
          reportEgg: action.report,
          dataset: action.dataset
        })
      }
    });
  } catch (e) {
    console.error('JSON stringify call did not succeed while parsing report and dataset');
    return Object.assign({}, action.promptText && {
      text: action.promptText
    }, {
      data: {
        intent: REPORTING_INSIGHTS_COPILOT_GENERATION,
        insightsGenerationRequestString: ''
      }
    });
  }
};