import { Record } from 'immutable';
import { PhoneNumberSources } from '../../../constants/PhoneNumberSources';
export const flattenFromNumber = fromNumber => {
  const nickname = fromNumber.nickname ? fromNumber.nickname : fromNumber.unifiedPhoneNumberMetadata ? fromNumber.unifiedPhoneNumberMetadata.nickname : null;
  return Object.assign({}, fromNumber, {
    nickname
  });
};
class FromNumber extends Record({
  phoneNumber: '',
  friendlyName: '',
  sid: null,
  countryCode: '',
  dateUpdated: null,
  extension: null,
  source: PhoneNumberSources.EXTERNAL_NUMBER,
  unifiedPhoneNumberMetadata: undefined,
  nickname: null,
  workspaceIdAndType: null
}, 'FromNumber') {
  constructor(props) {
    if (!props) {
      super(props);
      return;
    }
    const nickname = props.nickname ? props.nickname : props.unifiedPhoneNumberMetadata ? props.unifiedPhoneNumberMetadata.nickname : null;
    super(Object.assign({}, props, {
      nickname
    }));
  }
}
export default FromNumber;