import { GET_AGENT_STATUS } from '../queries/agentStatusQueries';
import { GET_USER_CAPACITY_DATA_QUERY } from '../../../__generated__/chirp/com/hubspot/cv/assignments/rpc/UserCapacityLimitsV2Service.dfc';
export function getCachedAgentStatus(client, workspace) {
  const agentStatusCache = client.readQuery({
    query: GET_AGENT_STATUS,
    variables: {
      workspace: workspace || ''
    }
  });
  return agentStatusCache ? agentStatusCache.agentStatus : undefined;
}
export function updateCachedAgentStatus({
  agentStatus,
  client,
  workspace
}) {
  client.writeQuery({
    query: GET_AGENT_STATUS,
    data: {
      agentStatus
    },
    variables: {
      workspace: workspace || ''
    }
  });
}
export function updateCachedCapacityLimit({
  capacityLimitMessage,
  client
}) {
  const query = client.readQuery({
    query: GET_USER_CAPACITY_DATA_QUERY,
    variables: {
      userId: capacityLimitMessage.userId
    }
  });
  client.writeQuery({
    query: GET_USER_CAPACITY_DATA_QUERY,
    data: {
      getUserCapacityData: Object.assign({
        hasHelpdeskRoutingScope: false,
        userHasCapacityLimit: true
      }, (query || {}).getUserCapacityData, {
        totalTicketUserCapacityData: {
          capacityLimit: capacityLimitMessage.capacityLimit,
          capacityLoad: capacityLimitMessage.capacityLoad
        }
      })
    },
    variables: {
      userId: capacityLimitMessage.userId
    }
  });
}