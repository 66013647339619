import { useEffect } from 'react';
import devLogger from 'react-utils/devLogger';
import { Metrics } from './Metrics';
import { useApolloClient } from '@apollo/client';
import { useTheme } from 'styled-components';
const useApolloClientCanary = ({
  objectTypeId,
  property
}) => {
  // Calling `useApolloClient` outside of an `ApolloProvider` context is dangerous because it will throw:
  // ```
  // Uncaught Invariant Violation: An error occurred! For more details, see the full error text at https://go.apollo.dev/c/err#%7B%22version%22%3A%223.11.4%22%2C%22message%22%3A50%2C%22args%22%3A%5B%5D%7D
  // ```
  let apolloClient = undefined;
  try {
    // Dear React: it's okay to call this inside a try/catch, because _calling it can throw_ and we need to catch it
    // eslint-disable-next-line react-hooks/rules-of-hooks
    apolloClient = useApolloClient();
  } catch (error) {
    // Do nothing; the canary below will log the failure
  }
  useEffect(() => {
    if (!apolloClient) {
      devLogger.warn({
        message: `formattedpropertyv2-apollo-client-canary: rendered without an <ApolloProvider>: objectTypeId=${objectTypeId}, propertyName=${property.name}`,
        key: 'formattedpropertyv2-apollo-client-canary'
      });
      Metrics.counter('formattedpropertyv2-apollo-client-canary').increment();
      // @gmcnaughton Sep 16, 2024 - disabled the Sentry until we can fix SalesSidebar
      // (gmail/outlook extension), which is a known omission. they were getting
      // paged due to the quantity of errors logged.
      // Raven.captureException(
      //   new Error('formattedpropertyv2 apollo client canary'),
      //   {
      //     extra: {
      //       objectTypeId,
      //       propertyName: property.name,
      //     },
      //   }
      // );
    }
  }, [apolloClient, objectTypeId, property.name]);
};
const useDataSensitivityCanary = ({
  objectTypeId,
  property
}) => {
  useEffect(() => {
    if (property.dataSensitivity === undefined) {
      devLogger.warn({
        message: `formattedpropertyv2-data-sensitivity-canary: received a property without dataSensitivity: objectTypeId=${objectTypeId}, propertyName=${property.name}`,
        key: 'formattedpropertyv2-data-sensitivity-canary'
      });
      Metrics.counter('formattedpropertyv2-data-sensitivity-canary').increment();
      // sam swindell July 15, 2024 - turned off to stop the flood until we have
      // fixed some known cases. Worried about COGS when sending too many sentries!
      // Raven.captureException(
      //   new Error('formattedpropertyv2 dataSensitivity canary'),
      //   {
      //     extra: {
      //       objectTypeId,
      //       propertyName: property.name,
      //     },
      //   }
      // );
    }
  }, [objectTypeId, property.dataSensitivity, property.name]);
};
const useDeprecatedPropsCanary = ({
  currencyProps,
  getIsUngated
}) => {
  useEffect(() => {
    if (currencyProps) {
      Metrics.counter('formattedpropertyv2-currencyprops').increment();
    }
    if (getIsUngated) {
      Metrics.counter('formattedpropertyv2-getisungated').increment();
    }

    // Intentionally only run once on mount to avoid noise and poorly memoized apps from
    // skewing these metrics too heavily.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
export const useThemeProviderCanary = () => {
  const theme = useTheme();
  useEffect(() => {
    try {
      // Trying to access a theme property that doesn't exist will throw an error
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      theme.typography['body-100'];
    } catch (_) {
      devLogger.warn({
        message: `formattedpropertyv2-missing-trellis-theme-provider-canary: rendered without Trellis ThemeProvider`,
        key: 'formattedpropertyv2-missing-trellis-theme-provider-canary'
      });
      Metrics.counter('formattedpropertyv2-missing-trellis-theme-provider-canary').increment();
    }
  }, [theme]);
};
export const useFormattedPropertyCanaryMetrics = props => {
  useApolloClientCanary(props);
  useDataSensitivityCanary(props);
  useDeprecatedPropsCanary(props);
  useThemeProviderCanary();
};