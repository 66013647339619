// eslint-disable-next-line no-restricted-imports -- we use http by default
import http from 'hub-http/clients/apiClient';
import { registerQuery, useQuery } from 'data-fetching-client';
const FETCH_AUTO_ASSOCIATIONS_FOR_ACTIVITY = registerQuery({
  fieldName: 'autoAssociationsData',
  args: ['subjectObjectTypeId', 'subjectObjectId', 'engagementType', 'includedObjects', 'apiClient'],
  fetcher: ({
    subjectObjectTypeId,
    subjectObjectId,
    engagementType,
    includedObjects,
    apiClient
  }) => apiClient.post('/crm-engagement-auto-associations/v3', {
    data: {
      subjectObjectTypeId,
      subjectObjectId,
      engagementType,
      includedObjects
    }
  })
});
export const useFetchAutoAssociations = ({
  subjectObjectTypeId,
  subjectObjectId,
  engagementType,
  skip,
  apiClient = http
}) => {
  return useQuery(FETCH_AUTO_ASSOCIATIONS_FOR_ACTIVITY, {
    variables: {
      subjectObjectTypeId: subjectObjectTypeId || '',
      // NOTE: query is skipped if subjectObjectTypeId is undefined
      subjectObjectId: subjectObjectId || '',
      // NOTE: query is skipped if subjectObjectId is undefined
      engagementType,
      includedObjects: {},
      apiClient
    },
    fetchPolicy: 'network-only',
    skip
  });
};