import { registerQuery, useLazyQuery } from 'data-fetching-client';
import { Map as ImmutableMap } from 'immutable';
import { useChannelInstanceById } from './useChannelInstanceById';
import http from 'conversations-http/clients/http';
import { useAgentVisitorDeliveryIdentifiers } from '../../../delivery-identifiers/public/hooks';
import { useEffect } from 'react';
import ChannelInstance from '../../public/records/ChannelInstance';
const CHANNEL_INSTANCE_BY_DELIVERY_IDENTIFIER_QUERY = registerQuery({
  fieldName: 'channelInstanceByDeliveryIdentifier',
  fetcher({
    deliveryIdentifierType,
    deliveryIdentifierValue,
    channelId
  }) {
    return http.get('conversations-channels/v1/channel-instances/delivery-identifier', {
      query: {
        deliveryIdentifierType,
        deliveryIdentifierValue,
        channelId
      }
    });
  },
  args: ['deliveryIdentifierType', 'deliveryIdentifierValue', 'channelId']
});
export const useChannelInstanceByThreadId = ({
  threadId,
  originalChannelInstanceId,
  genericChannelId
}) => {
  const {
    channelInstance,
    isLoading,
    isFailed
  } = useChannelInstanceById({
    channelInstanceId: originalChannelInstanceId,
    deferred: !originalChannelInstanceId
  });
  const [fetchChannelInstanceByDeliveryIdentifier, {
    data,
    loading,
    error
  }] = useLazyQuery(CHANNEL_INSTANCE_BY_DELIVERY_IDENTIFIER_QUERY);
  const {
    threadAgentDeliveryIdentifierData
  } = useAgentVisitorDeliveryIdentifiers({
    threadId,
    genericChannelId: genericChannelId
  });
  const resolvedChannelInstance = data !== null && data !== void 0 && data.channelInstanceByDeliveryIdentifier ? ChannelInstance(ImmutableMap(data.channelInstanceByDeliveryIdentifier)) : channelInstance;
  useEffect(() => {
    var _threadAgentDeliveryI;
    const {
      type,
      value
    } = (_threadAgentDeliveryI = threadAgentDeliveryIdentifierData === null || threadAgentDeliveryIdentifierData === void 0 ? void 0 : threadAgentDeliveryIdentifierData.deliveryIdentifier) !== null && _threadAgentDeliveryI !== void 0 ? _threadAgentDeliveryI : {};
    if (isFailed && type && value) {
      fetchChannelInstanceByDeliveryIdentifier({
        variables: {
          deliveryIdentifierType: type,
          deliveryIdentifierValue: value,
          channelId: genericChannelId
        }
      });
    }
  }, [isFailed, genericChannelId, fetchChannelInstanceByDeliveryIdentifier, threadAgentDeliveryIdentifierData === null || threadAgentDeliveryIdentifierData === void 0 ? void 0 : threadAgentDeliveryIdentifierData.deliveryIdentifier]);
  return {
    channelInstance: resolvedChannelInstance,
    isLoading: Boolean(isLoading || loading),
    isFailed: Boolean(error)
  };
};