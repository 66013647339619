module.exports = {
  "clicked-to-save-business-hours": {
    "class": "interaction",
    "name": "clicked-to-save-business-hours",
    "properties": {
      "action": "string",
      "hours count": "number",
      "first hour": "string",
      "second hour": {
        "type": "string",
        "isOptional": true
      },
      "third hour": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "clicked-manage-when-slas-apply": {
    "class": "interaction",
    "name": "clicked-manage-when-slas-apply",
    "properties": {
      "action": [
        "clicked to manage when slas apply"
      ],
      "platform": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "viewed-sla-settings-page": {
    "class": "view",
    "name": "viewed-sla-settings-page",
    "properties": {
      "action": [
        "viewed sla settings page"
      ],
      "portalId": "number",
      "platform": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "saved-sla-goal-config": {
    "class": "interaction",
    "name": "manage-settings",
    "properties": {
      "action": [
        "Saved SLA goal config changes"
      ],
      "warningDuration": "number",
      "warningUnit": "string",
      "breachedDuration": "number",
      "breachUnit": "string",
      "filterPriorities": "array",
      "goalType": "string",
      "isActive": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "canceled-sla-changes": {
    "class": "interaction",
    "name": "manage-settings",
    "properties": {
      "action": [
        "canceled changes"
      ],
      "platform": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "clicked-assign-seats": {
    "name": "clicked-assign-seats",
    "class": "interaction",
    "properties": {
      "action": [
        "clicked to assign seats"
      ]
    },
    "namespace": "conversations"
  },
  "clicked-manage-paid-users": {
    "name": "clicked-manage-paid-users",
    "class": "interaction",
    "properties": {
      "action": [
        "clicked to manage paid users"
      ],
      "type": [
        "team",
        "banner"
      ]
    },
    "namespace": "conversations"
  },
  "help-desk-intercept": {
    "name": "help desk intercept interaction",
    "class": "interaction",
    "properties": {
      "action": [
        "viewed interception",
        "dismissed interception",
        "clicked go to help desk"
      ],
      "component": [
        "modal",
        "alert"
      ]
    },
    "namespace": "conversations"
  },
  "clicked-move-to-help-desk": {
    "class": "interaction",
    "name": "manage-settings",
    "properties": {
      "action": [
        "clicked move to help desk"
      ],
      "channelType": {
        "type": [
          "BOT",
          "EMAIL",
          "LIVE_CHAT",
          "FORMS"
        ],
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "clicked-start-move-to-help-desk": {
    "class": "interaction",
    "name": "manage-settings",
    "properties": {
      "action": [
        "clicked start move to help desk"
      ],
      "channelType": [
        "BOT",
        "EMAIL",
        "LIVE_CHAT",
        "FORMS"
      ]
    },
    "namespace": "conversations"
  },
  "clicked-move-to-an-inbox": {
    "class": "interaction",
    "name": "manage-settings",
    "properties": {
      "action": [
        "clicked move to an inbox"
      ],
      "channelType": {
        "type": [
          "BOT",
          "EMAIL",
          "LIVE_CHAT",
          "FORMS"
        ],
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "conversations"
  },
  "clicked-start-move-to-an-inbox": {
    "class": "interaction",
    "name": "manage-settings",
    "properties": {
      "action": [
        "clicked start move to an inbox"
      ],
      "channelType": [
        "BOT",
        "EMAIL",
        "LIVE_CHAT",
        "FORMS"
      ]
    },
    "namespace": "conversations"
  },
  "clicked-cancel-migration": {
    "class": "interaction",
    "name": "manage-settings",
    "properties": {
      "action": [
        "clicked cancel migration"
      ],
      "migrationType": [
        "Help Desk to Inbox",
        "Inbox to Help Desk"
      ]
    },
    "namespace": "conversations"
  },
  "clicked-go-to-help-desk-settings": {
    "class": "interaction",
    "name": "manage-settings",
    "properties": {
      "action": [
        "clicked go to help desk settings"
      ]
    },
    "namespace": "conversations"
  },
  "clicked-go-to-previous-settings": {
    "class": "interaction",
    "name": "manage-settings",
    "properties": {
      "action": [
        "clicked go to previous settings"
      ],
      "migrationType": [
        "Help Desk to Inbox",
        "Inbox to Help Desk"
      ]
    },
    "namespace": "conversations"
  },
  "migration-step-viewed": {
    "class": "view",
    "name": "manage-settings",
    "properties": {
      "screen": "string",
      "step": "string"
    },
    "namespace": "conversations"
  }
};