/* eslint-disable @typescript-eslint/no-var-requires */

/**
 * react-dnd exports
 */ // @ts-expect-error need to publish webpack-env still, oops
const reactDnd = $hasDep('react-dnd', '5') ? require('./index.v5') : require('./index.v4');
export const DragSource = reactDnd.DragSource;
export const DropTarget = reactDnd.DropTarget;
export const DragLayer = reactDnd.DragLayer;
export const DragDropContext = reactDnd.DragDropContext;
/**
 * react-dnd-html5-backend exports
 */ // @ts-expect-error need to publish webpack-env still, oops
export const HTML5Backend = $hasDep('react-dnd-html5-backend', '5') ? require('react-dnd-html5-backend').HTML5Backend : require('react-dnd-html5-backend').default;
export const getEmptyImage = require('react-dnd-html5-backend').getEmptyImage;
export const NativeTypes = require('react-dnd-html5-backend').NativeTypes;