module.exports = {
  "openCreateQuoteTemplateModal": {
    "properties": {
      "action": [
        "Open modal"
      ],
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "category": {
        "type": "array",
        "isOptional": true
      }
    },
    "namespace": "custom-quote-templates",
    "name": "Custom quote template interaction",
    "class": "interaction"
  },
  "previewQuoteTemplate": {
    "properties": {
      "action": [
        "Preview quote template"
      ],
      "templateId": {
        "type": "number"
      },
      "templatePath": {
        "type": "string"
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "category": {
        "type": "array",
        "isOptional": true
      }
    },
    "namespace": "custom-quote-templates",
    "name": "Custom quote template interaction",
    "class": "interaction"
  },
  "chooseQuoteTemplate": {
    "properties": {
      "action": [
        "Choose quote template"
      ],
      "templateId": {
        "type": "number"
      },
      "templatePath": {
        "type": "string"
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "category": {
        "type": "array",
        "isOptional": true
      }
    },
    "namespace": "custom-quote-templates",
    "name": "Custom quote template interaction",
    "class": "interaction"
  },
  "toggleModule": {
    "properties": {
      "action": [
        "Hide module",
        "Show module"
      ],
      "moduleName": {
        "type": "string"
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "category": {
        "type": "array",
        "isOptional": true
      }
    },
    "namespace": "custom-quote-templates",
    "name": "Custom quote template interaction",
    "class": "interaction"
  },
  "editModule": {
    "properties": {
      "action": [
        "Edit module"
      ],
      "name": {
        "type": "string"
      },
      "field": {
        "type": "string"
      },
      "cmsTemplatePath": {
        "type": "string"
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "category": {
        "type": "array",
        "isOptional": true
      }
    },
    "namespace": "custom-quote-templates",
    "name": "Custom quote template interaction",
    "class": "interaction"
  },
  "saveQuoteTemplate": {
    "properties": {
      "action": [
        "Save quote template"
      ],
      "templateId": {
        "type": "number",
        "isOptional": true
      },
      "templatePath": {
        "type": "string"
      },
      "saveType": [
        "create",
        "edit"
      ],
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "category": {
        "type": "array",
        "isOptional": true
      }
    },
    "namespace": "custom-quote-templates",
    "name": "Custom quote template usage",
    "class": "usage"
  },
  "openQuoteTemplateEditor": {
    "properties": {
      "action": [
        "Open quote template editor"
      ],
      "templateId": {
        "type": "number"
      },
      "templatePath": {
        "type": "string"
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "category": {
        "type": "array",
        "isOptional": true
      }
    },
    "namespace": "custom-quote-templates",
    "name": "Custom quote template usage",
    "class": "usage"
  },
  "deleteQuoteTemplate": {
    "properties": {
      "action": [
        "Delete quote template"
      ],
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "category": {
        "type": "array",
        "isOptional": true
      }
    },
    "namespace": "custom-quote-templates",
    "name": "Custom quote template usage",
    "class": "usage"
  },
  "viewQuoteTemplateLibrary": {
    "properties": {
      "screen": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "category": {
        "type": "array",
        "isOptional": true
      }
    },
    "namespace": "custom-quote-templates",
    "name": "Custom quote template pageview",
    "class": "view"
  },
  "viewQuotesIndex": {
    "properties": {
      "screen": "string",
      "subscreen": "string",
      "category": {
        "type": "array",
        "isOptional": true
      }
    },
    "namespace": "quotes-admin",
    "name": "Quotes pageview",
    "class": "view"
  },
  "viewQuotesDetailsPage": {
    "properties": {
      "screen": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "category": {
        "type": "array",
        "isOptional": true
      }
    },
    "namespace": "quotes-admin",
    "name": "Quotes pageview",
    "class": "view"
  },
  "quoteApprovalActivation": {
    "properties": {
      "action": [
        "Create quote workflows"
      ],
      "version": [
        "v1",
        "v2"
      ],
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "category": {
        "type": "array",
        "isOptional": true
      }
    },
    "namespace": "quotes-admin",
    "name": "Quotes activation",
    "class": "activation"
  },
  "byosStripeUpgrade": {
    "properties": {
      "action": [
        "Direct to payments zero state"
      ],
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "category": {
        "type": "array",
        "isOptional": true
      }
    },
    "namespace": "quotes-admin",
    "name": "Quotes activation",
    "class": "activation"
  },
  "createQuote": {
    "name": "Quote create",
    "properties": {
      "action": [
        "Created quote",
        "Drafted quote",
        "Requested quote"
      ],
      "location": {
        "type": [
          "dealRecord",
          "dealLineItemEditor",
          "index",
          "quoteTemplateSettings",
          "quotePreviewPanel"
        ],
        "isOptional": true
      },
      "template": {
        "type": [
          "ORIGINAL",
          "BASIC",
          "MODERN"
        ],
        "isOptional": true
      },
      "templateType": {
        "type": [
          "CUSTOMIZABLE_QUOTE_TEMPLATE",
          "QUOTE",
          "PROPOSAL"
        ],
        "isOptional": true
      },
      "cmsTemplatePath": {
        "type": "string",
        "isOptional": true
      },
      "numberOfRecipientContacts": {
        "type": "number"
      },
      "signatureEnabled": {
        "type": "boolean"
      },
      "esignatureEnabled": {
        "type": "boolean"
      },
      "stripeEnabled": {
        "type": "boolean"
      },
      "hubspotPaymentsEnabled": {
        "type": "boolean"
      },
      "paymentType": {
        "type": [
          "HUBSPOT",
          "BYO_STRIPE",
          "none"
        ]
      },
      "discountsApplied": {
        "type": [
          "PERCENT",
          "FIXED"
        ],
        "isOptional": true
      },
      "totalDiscountsRate": {
        "type": "number",
        "isOptional": true
      },
      "paymentScheduleEnabled": {
        "type": "boolean",
        "isOptional": true
      },
      "enabled": {
        "type": "array"
      },
      "isDefault": {
        "type": "boolean"
      },
      "currencyCode": {
        "type": "string"
      },
      "numLineItemsWithTax": {
        "type": "number"
      },
      "billingFrequency": {
        "type": "array",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "category": {
        "type": "array",
        "isOptional": true
      }
    },
    "namespace": "quotes-admin",
    "class": "usage"
  },
  "updatePublishedQuote": {
    "properties": {
      "action": [
        "Mark as manually signed",
        "Archive quote"
      ],
      "location": {
        "type": [
          "index",
          "quoteSidebarCard",
          "quotePreviewPanel",
          "quoteDetailsPage"
        ],
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "category": {
        "type": "array",
        "isOptional": true
      }
    },
    "namespace": "quotes-admin",
    "name": "Quotes usage",
    "class": "usage"
  },
  "quoteApprovalUsage": {
    "properties": {
      "action": [
        "Approved quote",
        "Requested quote changes"
      ],
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "category": {
        "type": "array",
        "isOptional": true
      }
    },
    "namespace": "quotes-admin",
    "name": "Quotes usage",
    "class": "usage"
  },
  "createDeal": {
    "properties": {
      "action": [
        "Create deal"
      ],
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "category": {
        "type": "array",
        "isOptional": true
      }
    },
    "namespace": "quotes-admin",
    "name": "Quotes usage",
    "class": "usage"
  },
  "selectDeal": {
    "properties": {
      "action": [
        "Select deal"
      ],
      "hasCreatedDeal": {
        "type": "boolean"
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "category": {
        "type": "array",
        "isOptional": true
      }
    },
    "namespace": "quotes-admin",
    "name": "Quotes usage",
    "class": "usage"
  },
  "quoteWizardProgress": {
    "properties": {
      "action": [
        "Quote wizard progress"
      ],
      "step": [
        "Deal information",
        "Deal review",
        "Quote details",
        "Buyer information",
        "Sender information",
        "Review line items",
        "Signature and payment",
        "Review"
      ],
      "hasOver100LineItems": {
        "type": "boolean",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "category": {
        "type": "array",
        "isOptional": true
      }
    },
    "namespace": "quotes-admin",
    "name": "Quotes interaction",
    "class": "interaction"
  },
  "quoteWizardInteraction": {
    "properties": {
      "action": [
        "Edit terms",
        "Edit comments",
        "Open snippet popover",
        "Change expiration date",
        "Open buyer contact",
        "Edit buyer contact",
        "Open buyer company",
        "Edit buyer company",
        "Open edit sender contact",
        "Edit sender contact",
        "Open edit sender company",
        "Edit sender company",
        "Add additional fee",
        "Remove additional fee",
        "Enable print signature",
        "Enable print countersignature",
        "Modify esign countersignature",
        "Change quote language",
        "Change quote locale",
        "Edit slug",
        "Add payment schedule",
        "Delete payment schedule",
        "Show TCV on published quote",
        "Hide TCV on published quote",
        "Copy quote link in created modal",
        "Click write email button in created modal",
        "Close quote created modal",
        "Footer click back button",
        "Footer click cancel button",
        "Footer click save draft button",
        "Footer click done button",
        "Footer click next button",
        "Close wizard using escape key"
      ],
      "editedProperties": {
        "type": "string",
        "isOptional": true
      },
      "type": {
        "type": [
          "DISCOUNT",
          "FEE",
          "TAX"
        ],
        "isOptional": true
      },
      "modifyType": {
        "type": [
          "ADD",
          "REMOVE"
        ],
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "category": {
        "type": "array",
        "isOptional": true
      }
    },
    "namespace": "quotes-admin",
    "name": "Quotes interaction",
    "class": "interaction"
  },
  "quoteWizardCancel": {
    "properties": {
      "action": [
        "Cancel quote"
      ],
      "location": [
        "create flow",
        "edit flow"
      ],
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "category": {
        "type": "array",
        "isOptional": true
      }
    },
    "namespace": "quotes-admin",
    "name": "Quotes interaction",
    "class": "interaction"
  },
  "quoteInteraction": {
    "properties": {
      "action": [
        "Click create quote button",
        "Click quote title",
        "Clone quote",
        "Copy quote link",
        "Copy quote approval link",
        "Edit quote",
        "Download quote",
        "Countersign quote",
        "Send quote",
        "View esign signatures",
        "Open delete quote modal",
        "Delete quote",
        "Open recall quote modal",
        "Recall quote",
        "Click view quote",
        "Click see details",
        "Click details button",
        "Click convert to invoice",
        "Click convert to billing",
        "Open archive quote modal",
        "Open mark as manually signed modal",
        "Click to enroll now in payments",
        "Click to open test mode",
        "Click to go to payments guide",
        "Click to go to payments settings"
      ],
      "location": [
        "dealRecord",
        "dealLineItemEditor",
        "index",
        "quoteDetailsPage",
        "quotePreviewPanel",
        "signatureAndPaymentPage"
      ],
      "paymentType": {
        "type": [
          "HUBSPOT",
          "BYO_STRIPE"
        ],
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "category": {
        "type": "array",
        "isOptional": true
      }
    },
    "namespace": "quotes-admin",
    "name": "Quotes interaction",
    "class": "interaction"
  },
  "quoteTableStatusFilter": {
    "properties": {
      "action": [
        "Filter by status"
      ],
      "status": [
        "ALL",
        "DRAFT",
        "PENDING_APPROVAL",
        "CHANGES_REQUESTED",
        "PENDING_SIGNATURE",
        "SIGNED",
        "PENDING_PAYMENT",
        "PROCESSING",
        "PAID",
        "PUBLISHED"
      ],
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "category": {
        "type": "array",
        "isOptional": true
      }
    },
    "namespace": "quotes-admin",
    "name": "Quotes interaction",
    "class": "interaction"
  },
  "quoteTableArchiveStatusFilter": {
    "properties": {
      "action": [
        "Filter by archive status"
      ],
      "status": [
        "ACTIVE",
        "ARCHIVED",
        "ALL"
      ],
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "category": {
        "type": "array",
        "isOptional": true
      }
    },
    "namespace": "quotes-admin",
    "name": "Quotes interaction",
    "class": "interaction"
  },
  "quoteTableOwnersFilter": {
    "properties": {
      "action": [
        "Filter by owner"
      ],
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "category": {
        "type": "array",
        "isOptional": true
      }
    },
    "namespace": "quotes-admin",
    "name": "Quotes interaction",
    "class": "interaction"
  },
  "quoteTableClearFilters": {
    "properties": {
      "action": [
        "Clear all filters"
      ],
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "category": {
        "type": "array",
        "isOptional": true
      }
    },
    "namespace": "quotes-admin",
    "name": "Quotes interaction",
    "class": "interaction"
  },
  "downloadAllQuotes": {
    "properties": {
      "action": [
        "Download all quotes"
      ],
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "category": {
        "type": "array",
        "isOptional": true
      }
    },
    "namespace": "quotes-admin",
    "name": "Quotes interaction",
    "class": "interaction"
  },
  "quoteApprovalPermissionSetup": {
    "properties": {
      "action": [
        "Open quote approval permissions modal",
        "Confirm quote approval permissions modal"
      ],
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "category": {
        "type": "array",
        "isOptional": true
      }
    },
    "namespace": "quotes-admin",
    "name": "Quotes interaction",
    "class": "interaction"
  },
  "quoteApprovalWorkflowSetup": {
    "properties": {
      "action": [
        "Open quote approval notifications modal",
        "Confirm quote approval notifications modal",
        "Manage quote approval workflows"
      ],
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "category": {
        "type": "array",
        "isOptional": true
      }
    },
    "namespace": "quotes-admin",
    "name": "Quotes interaction",
    "class": "interaction"
  },
  "quoteTimelineEvent": {
    "properties": {
      "action": [
        "Click public quote link"
      ],
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "category": {
        "type": "array",
        "isOptional": true
      }
    },
    "namespace": "quotes-admin",
    "name": "Quotes interaction",
    "class": "interaction"
  },
  "quoteTemplateSettings": {
    "properties": {
      "action": [
        "Click quote template settings link"
      ],
      "location": [
        "Quotes index header",
        "Quotes index banner",
        "Quotes index empty state",
        "Quote wizard template select"
      ],
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "category": {
        "type": "array",
        "isOptional": true
      }
    },
    "namespace": "quotes-admin",
    "name": "Quotes interaction",
    "class": "interaction"
  },
  "quoteInterceptModal": {
    "properties": {
      "action": [
        "Open enroll in payments modal",
        "Click enroll in payments button"
      ],
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "category": {
        "type": "array",
        "isOptional": true
      }
    },
    "namespace": "quotes-admin",
    "name": "Quotes interaction",
    "class": "interaction"
  }
};