import { SUBSCRIPTION_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
import { useAdditionalPropertyValues } from './useAdditionalPropertyValues';
import { isReadOnlySubscriptionProperty } from 'customer-data-objects/subscription/isReadOnlySubscriptionProperty';

// See https://git.hubteam.com/HubSpot/PropertiesFrontendTeam/issues/1921,
// https://git.hubteam.com/HubSpot/PropertiesFrontendTeam/issues/1984
export const useIsReadOnlySubscriptionProperty = (objectTypeId, propertyDefinition) => {
  const {
    data,
    loading
  } = useAdditionalPropertyValues({
    hs_invoice_creation: 'hs_invoice_creation',
    hs_collection_process: 'hs_collection_process'
  }, {
    skip: objectTypeId !== SUBSCRIPTION_TYPE_ID || !propertyDefinition.hubspotDefined
  });
  if (loading) {
    return true;
  }
  return isReadOnlySubscriptionProperty({
    objectTypeId,
    propertyName: propertyDefinition.name,
    hubspotDefined: propertyDefinition.hubspotDefined,
    invoiceCreation: data === null || data === void 0 ? void 0 : data.hs_invoice_creation,
    collectionProcess: data === null || data === void 0 ? void 0 : data.hs_collection_process
  });
};