import http from 'conversations-http/clients/http';
export function fetchCustomViewSettings({
  workspaceId
}) {
  return http.get('conversations-custom-views/v1/settings/users', {
    query: {
      workspaceId
    }
  });
}
export function postCustomViewSettings(req) {
  return http.post('conversations-custom-views/v1/settings/users', {
    data: req
  });
}