import { COMPANY, CONTACT, DEAL, PARTNER_CLIENT, TICKET } from 'customer-data-objects/constants/ObjectTypes';
import { CART_TYPE_ID, COMMERCE_PAYMENT_TYPE_ID, COMPANY_TYPE_ID, CONTACT_TYPE_ID, DEAL_TYPE_ID, INVOICE_TYPE_ID, LEAD_TYPE_ID, LINE_ITEM_TYPE_ID, ORDER_TYPE_ID, PARTNER_CLIENT_TYPE_ID, PAYMENT_LINK_TYPE_ID, QUOTE_TYPE_ID, SUBSCRIPTION_TYPE_ID, TICKET_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
import { Set as ImmutableSet } from 'immutable';
export const HUBSPOT_DEFINED_ASSOCIATION_CATEGORY = 'HUBSPOT_DEFINED';
export const CONTACT_TO_COMPANY_UNLABELED_ASSOCIATION_ID = 279;
export const CONTACT_TO_COMPANY_PRIMARY_ASSOCIATION_ID = 1;
export const COMPANY_TO_CONTACT_UNLABELED_ASSOCIATION_ID = 280;
export const COMPANY_TO_CONTACT_PRIMARY_ASSOCIATION_ID = 2;
const CONTACT_TO_CONTACT_UNLABELED_ASSOCIATION_ID = 449;
export const COMPANY_CONTACT_ASSOCIATIONS = ImmutableSet.of(CONTACT_TO_COMPANY_UNLABELED_ASSOCIATION_ID, CONTACT_TO_COMPANY_PRIMARY_ASSOCIATION_ID, COMPANY_TO_CONTACT_UNLABELED_ASSOCIATION_ID, COMPANY_TO_CONTACT_PRIMARY_ASSOCIATION_ID);
export const LEGACY_COMPANY_TO_DEAL_PRIMARY_ASSOCIATION_ID = 272;
export const LEGACY_DEAL_TO_COMPANY_PRIMARY_ASSOCIATION_ID = 271;

//These are the new primary associationTypeIds and eventually 271 and 272 will be deprecated.
//For now the new ones were backfilled which means that both are returned when ungated for flexible-associations so we need to block both.
export const DEAL_TO_COMPANY_UNLABELED_ASSOCIATION_ID = 341;
export const DEAL_TO_COMPANY_PRIMARY_ASSOCIATION_ID = 5;
export const COMPANY_TO_DEAL_UNLABELED_ASSOCIATION_ID = 342;
export const COMPANY_TO_DEAL_PRIMARY_ASSOCIATION_ID = 6;
export const COMPANY_DEAL_ASSOCIATIONS = ImmutableSet.of(DEAL_TO_COMPANY_UNLABELED_ASSOCIATION_ID, LEGACY_DEAL_TO_COMPANY_PRIMARY_ASSOCIATION_ID, DEAL_TO_COMPANY_PRIMARY_ASSOCIATION_ID, COMPANY_TO_DEAL_UNLABELED_ASSOCIATION_ID, LEGACY_COMPANY_TO_DEAL_PRIMARY_ASSOCIATION_ID, COMPANY_TO_DEAL_PRIMARY_ASSOCIATION_ID);
export const LEGACY_COMPANY_TO_TICKET_PRIMARY_ASSOCIATION_ID = 274;
export const LEGACY_TICKET_TO_COMPANY_PRIMARY_ASSOCIATION_ID = 273;
export const TICKET_TO_COMPANY_UNLABELED_ASSOCIATION_ID = 339;
export const TICKET_TO_COMPANY_PRIMARY_ASSOCIATION_ID = 26;
export const COMPANY_TO_TICKET_UNLABELED_ASSOCIATION_ID = 340;
export const COMPANY_TO_TICKET_PRIMARY_ASSOCIATION_ID = 25;
const COMPANY_TO_COMPANY_UNLABELED_ASSOCIATION_ID = 450;
export const COMPANY_TICKET_ASSOCIATIONS = ImmutableSet.of(TICKET_TO_COMPANY_UNLABELED_ASSOCIATION_ID, LEGACY_TICKET_TO_COMPANY_PRIMARY_ASSOCIATION_ID, TICKET_TO_COMPANY_PRIMARY_ASSOCIATION_ID, COMPANY_TO_TICKET_UNLABELED_ASSOCIATION_ID, LEGACY_COMPANY_TO_TICKET_PRIMARY_ASSOCIATION_ID, COMPANY_TO_TICKET_PRIMARY_ASSOCIATION_ID);
export const TICKET_TO_CONTACT_UNLABELED_ASSOCIATION_ID = 16;
export const CONTACT_TO_TICKET_UNLABELED_ASSOCIATION_ID = 15;
export const CONTACT_TICKET_ASSOCIATIONS = ImmutableSet.of(TICKET_TO_CONTACT_UNLABELED_ASSOCIATION_ID, CONTACT_TO_TICKET_UNLABELED_ASSOCIATION_ID);
export const DEAL_TO_CONTACT_UNLABELED_ASSOCIATION_ID = 3;
export const CONTACT_TO_DEAL_UNLABELED_ASSOCIATION_ID = 4;
const DEAL_TO_DEAL_UNLABELED_ASSOCIATION_ID = 451;
export const CONTACT_DEAL_ASSOCIATIONS = ImmutableSet.of(DEAL_TO_CONTACT_UNLABELED_ASSOCIATION_ID, CONTACT_TO_DEAL_UNLABELED_ASSOCIATION_ID);
export const TICKET_TO_DEAL_UNLABELED_ASSOCIATION_ID = 28;
export const DEAL_TO_TICKET_UNLABELED_ASSOCIATION_ID = 27;
const TICKET_TO_TICKET_UNLABELED_ASSOCIATION_ID = 452;
export const DEAL_TICKET_ASSOCIATIONS = ImmutableSet.of(TICKET_TO_DEAL_UNLABELED_ASSOCIATION_ID, DEAL_TO_TICKET_UNLABELED_ASSOCIATION_ID);
const PARTNER_CLIENT_TO_COMPANY_PRIMARY_ASSOCIATION_ID = 616;
const SUBSCRIPTION_TO_CONTACT_UNLABELED_ASSOCIATION_ID = 295;
const COMMERCE_PAYMENT_TO_CONTACT_UNLABELED_ASSOCIATION_ID = 387;
const PAYMENT_LINK_TO_CONTACT_UNLABELED_ASSOCIATION_ID = 469;
const ORDER_TO_CONTACT_UNLABELED_ASSOCIATION_ID = 507;
const CART_TO_CONTACT_UNLABELED_ASSOCIATION_ID = 586;
const SUBSCRIPTION_TO_COMPANY_UNLABELED_ASSOCIATION_ID = 297;
const COMMERCE_PAYMENT_TO_COMPANY_UNLABELED_ASSOCIATION_ID = 389;
const PAYMENT_LINK_TO_COMPANY_UNLABELED_ASSOCIATION_ID = 471;
const ORDER_TO_COMPANY_UNLABELED_ASSOCIATION_ID = 934;
const CART_TO_COMPANY_UNLABELED_ASSOCIATION_ID = 936;
const ORDER_TO_TICKET_UNLABELED_ASSOCIATION_ID = 525;
const CART_TO_TICKET_UNLABELED_ASSOCIATION_ID = 594;
const SUBSCRIPTION_TO_DEAL_UNLABELED_ASSOCIATION_ID = 299;
const COMMERCE_PAYMENT_TO_DEAL_UNLABELED_ASSOCIATION_ID = 391;
const PAYMENT_LINK_TO_DEAL_UNLABELED_ASSOCIATION_ID = 473;
const ORDER_TO_DEAL_UNLABELED_ASSOCIATION_ID = 512;
const LEAD_TO_DEAL_UNLABELED_ASSOCIATION_ID = 582;
const INVOICE_TO_DEAL_UNLABELED_ASSOCIATION_ID = 175;
const LINE_ITEM_TO_DEAL_UNLABELED_ASSOCIATION_ID = 20;
const QUOTE_TO_DEAL_UNLABELED_ASSOCIATION_ID = 64;
/**
 * CRM Search expects the inverse association id to be passed in the query
 * Sourced from https://git.hubteam.com/HubSpotProtected/InboundDbAssociations/blob/e922f30f87840b7f233655ce59d8d7131f7a8047/InboundDbAssociationsModels/src/main/java/com/hubspot/inbounddb/associations/base/DefaultAssociationType.java#L23
 */
export const UNLABELED_ASSOCIATION_BY_OBJECT_TYPE_ID_INVERSE_ID = {
  [CONTACT_TYPE_ID]: {
    [COMPANY_TYPE_ID]: COMPANY_TO_CONTACT_UNLABELED_ASSOCIATION_ID,
    [DEAL_TYPE_ID]: DEAL_TO_CONTACT_UNLABELED_ASSOCIATION_ID,
    [TICKET_TYPE_ID]: TICKET_TO_CONTACT_UNLABELED_ASSOCIATION_ID,
    [CONTACT_TYPE_ID]: CONTACT_TO_CONTACT_UNLABELED_ASSOCIATION_ID,
    [SUBSCRIPTION_TYPE_ID]: SUBSCRIPTION_TO_CONTACT_UNLABELED_ASSOCIATION_ID,
    [COMMERCE_PAYMENT_TYPE_ID]: COMMERCE_PAYMENT_TO_CONTACT_UNLABELED_ASSOCIATION_ID,
    [PAYMENT_LINK_TYPE_ID]: PAYMENT_LINK_TO_CONTACT_UNLABELED_ASSOCIATION_ID,
    [ORDER_TYPE_ID]: ORDER_TO_CONTACT_UNLABELED_ASSOCIATION_ID,
    [CART_TYPE_ID]: CART_TO_CONTACT_UNLABELED_ASSOCIATION_ID,
    //Social profile
    '0-144': 636
  },
  [COMPANY_TYPE_ID]: {
    [CONTACT_TYPE_ID]: CONTACT_TO_COMPANY_UNLABELED_ASSOCIATION_ID,
    [TICKET_TYPE_ID]: TICKET_TO_COMPANY_UNLABELED_ASSOCIATION_ID,
    [DEAL_TYPE_ID]: DEAL_TO_COMPANY_UNLABELED_ASSOCIATION_ID,
    [COMPANY_TYPE_ID]: COMPANY_TO_COMPANY_UNLABELED_ASSOCIATION_ID,
    [SUBSCRIPTION_TYPE_ID]: SUBSCRIPTION_TO_COMPANY_UNLABELED_ASSOCIATION_ID,
    [COMMERCE_PAYMENT_TYPE_ID]: COMMERCE_PAYMENT_TO_COMPANY_UNLABELED_ASSOCIATION_ID,
    [PAYMENT_LINK_TYPE_ID]: PAYMENT_LINK_TO_COMPANY_UNLABELED_ASSOCIATION_ID,
    [ORDER_TYPE_ID]: ORDER_TO_COMPANY_UNLABELED_ASSOCIATION_ID,
    [CART_TYPE_ID]: CART_TO_COMPANY_UNLABELED_ASSOCIATION_ID
  },
  [TICKET_TYPE_ID]: {
    [COMPANY_TYPE_ID]: COMPANY_TO_TICKET_UNLABELED_ASSOCIATION_ID,
    [CONTACT_TYPE_ID]: CONTACT_TO_TICKET_UNLABELED_ASSOCIATION_ID,
    [DEAL_TYPE_ID]: DEAL_TO_TICKET_UNLABELED_ASSOCIATION_ID,
    [TICKET_TYPE_ID]: TICKET_TO_TICKET_UNLABELED_ASSOCIATION_ID,
    [ORDER_TYPE_ID]: ORDER_TO_TICKET_UNLABELED_ASSOCIATION_ID,
    [CART_TYPE_ID]: CART_TO_TICKET_UNLABELED_ASSOCIATION_ID
  },
  [DEAL_TYPE_ID]: {
    [COMPANY_TYPE_ID]: COMPANY_TO_DEAL_UNLABELED_ASSOCIATION_ID,
    [CONTACT_TYPE_ID]: CONTACT_TO_DEAL_UNLABELED_ASSOCIATION_ID,
    [TICKET_TYPE_ID]: TICKET_TO_DEAL_UNLABELED_ASSOCIATION_ID,
    [DEAL_TYPE_ID]: DEAL_TO_DEAL_UNLABELED_ASSOCIATION_ID,
    [SUBSCRIPTION_TYPE_ID]: SUBSCRIPTION_TO_DEAL_UNLABELED_ASSOCIATION_ID,
    [COMMERCE_PAYMENT_TYPE_ID]: COMMERCE_PAYMENT_TO_DEAL_UNLABELED_ASSOCIATION_ID,
    [PAYMENT_LINK_TYPE_ID]: PAYMENT_LINK_TO_DEAL_UNLABELED_ASSOCIATION_ID,
    [ORDER_TYPE_ID]: ORDER_TO_DEAL_UNLABELED_ASSOCIATION_ID,
    [LEAD_TYPE_ID]: LEAD_TO_DEAL_UNLABELED_ASSOCIATION_ID,
    [INVOICE_TYPE_ID]: INVOICE_TO_DEAL_UNLABELED_ASSOCIATION_ID,
    [LINE_ITEM_TYPE_ID]: LINE_ITEM_TO_DEAL_UNLABELED_ASSOCIATION_ID,
    [QUOTE_TYPE_ID]: QUOTE_TO_DEAL_UNLABELED_ASSOCIATION_ID
  }
};

/**
 * CRM Search expects the inverse association id to be passed in the query
 * Sourced from https://git.hubteam.com/HubSpotProtected/InboundDbAssociations/blob/e922f30f87840b7f233655ce59d8d7131f7a8047/InboundDbAssociationsModels/src/main/java/com/hubspot/inbounddb/associations/base/DefaultAssociationType.java#L23
 */
export const PRIMARY_ASSOCIATION_BY_OBJECT_TYPE_ID_INVERSE_ID = {
  [CONTACT_TYPE_ID]: {
    [COMPANY_TYPE_ID]: COMPANY_TO_CONTACT_PRIMARY_ASSOCIATION_ID
  },
  [COMPANY_TYPE_ID]: {
    [CONTACT_TYPE_ID]: CONTACT_TO_COMPANY_PRIMARY_ASSOCIATION_ID,
    [TICKET_TYPE_ID]: TICKET_TO_COMPANY_PRIMARY_ASSOCIATION_ID,
    [DEAL_TYPE_ID]: DEAL_TO_COMPANY_PRIMARY_ASSOCIATION_ID
  },
  [TICKET_TYPE_ID]: {
    [COMPANY_TYPE_ID]: COMPANY_TO_TICKET_PRIMARY_ASSOCIATION_ID
  },
  [DEAL_TYPE_ID]: {
    [COMPANY_TYPE_ID]: COMPANY_TO_DEAL_PRIMARY_ASSOCIATION_ID
  }
};
export const PRIMARY_ASSOCIATION_BY_OBJECT_TYPES = {
  [CONTACT]: {
    [COMPANY]: CONTACT_TO_COMPANY_PRIMARY_ASSOCIATION_ID,
    [COMPANY_TYPE_ID]: CONTACT_TO_COMPANY_PRIMARY_ASSOCIATION_ID
  },
  [COMPANY]: {
    [CONTACT]: COMPANY_TO_CONTACT_PRIMARY_ASSOCIATION_ID,
    [TICKET]: COMPANY_TO_TICKET_PRIMARY_ASSOCIATION_ID,
    [DEAL]: COMPANY_TO_DEAL_PRIMARY_ASSOCIATION_ID,
    [CONTACT_TYPE_ID]: COMPANY_TO_CONTACT_PRIMARY_ASSOCIATION_ID,
    [TICKET_TYPE_ID]: COMPANY_TO_TICKET_PRIMARY_ASSOCIATION_ID,
    [DEAL_TYPE_ID]: COMPANY_TO_DEAL_PRIMARY_ASSOCIATION_ID
  },
  [TICKET]: {
    [COMPANY]: TICKET_TO_COMPANY_PRIMARY_ASSOCIATION_ID,
    [COMPANY_TYPE_ID]: TICKET_TO_COMPANY_PRIMARY_ASSOCIATION_ID
  },
  [DEAL]: {
    [COMPANY]: DEAL_TO_COMPANY_PRIMARY_ASSOCIATION_ID,
    [COMPANY_TYPE_ID]: DEAL_TO_COMPANY_PRIMARY_ASSOCIATION_ID
  },
  [PARTNER_CLIENT]: {
    [COMPANY]: PARTNER_CLIENT_TO_COMPANY_PRIMARY_ASSOCIATION_ID,
    [COMPANY_TYPE_ID]: PARTNER_CLIENT_TO_COMPANY_PRIMARY_ASSOCIATION_ID
  },
  [CONTACT_TYPE_ID]: {
    [COMPANY]: CONTACT_TO_COMPANY_PRIMARY_ASSOCIATION_ID,
    [COMPANY_TYPE_ID]: CONTACT_TO_COMPANY_PRIMARY_ASSOCIATION_ID
  },
  [COMPANY_TYPE_ID]: {
    [CONTACT]: COMPANY_TO_CONTACT_PRIMARY_ASSOCIATION_ID,
    [TICKET]: COMPANY_TO_TICKET_PRIMARY_ASSOCIATION_ID,
    [DEAL]: COMPANY_TO_DEAL_PRIMARY_ASSOCIATION_ID,
    [CONTACT_TYPE_ID]: COMPANY_TO_CONTACT_PRIMARY_ASSOCIATION_ID,
    [TICKET_TYPE_ID]: COMPANY_TO_TICKET_PRIMARY_ASSOCIATION_ID,
    [DEAL_TYPE_ID]: COMPANY_TO_DEAL_PRIMARY_ASSOCIATION_ID
  },
  [TICKET_TYPE_ID]: {
    [COMPANY]: TICKET_TO_COMPANY_PRIMARY_ASSOCIATION_ID,
    [COMPANY_TYPE_ID]: TICKET_TO_COMPANY_PRIMARY_ASSOCIATION_ID
  },
  [DEAL_TYPE_ID]: {
    [COMPANY]: DEAL_TO_COMPANY_PRIMARY_ASSOCIATION_ID,
    [COMPANY_TYPE_ID]: DEAL_TO_COMPANY_PRIMARY_ASSOCIATION_ID
  },
  [PARTNER_CLIENT_TYPE_ID]: {
    [COMPANY]: PARTNER_CLIENT_TO_COMPANY_PRIMARY_ASSOCIATION_ID,
    [COMPANY_TYPE_ID]: PARTNER_CLIENT_TO_COMPANY_PRIMARY_ASSOCIATION_ID
  }
};