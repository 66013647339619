import { OBJECT_SUMMARY } from '../../constants/CopilotInAppActionTypes';
import { normalizeObjectType } from './utils/normalizeObjectType';
import { parseObjectId } from './utils/parseObjectId';
export const objectSummary = action => {
  return {
    data: {
      intent: OBJECT_SUMMARY,
      crm_object: {
        object_type: normalizeObjectType(action.objectTypeId),
        object_id: parseObjectId(action.objectId)
      },
      from_date: action.fromDate,
      to_date: action.toDate,
      context: action.context
    }
  };
};