import { gate as v3Gate } from 'hub-http/gates';
import { useAuth } from '../hooks/useAuth';

/**
 * Returns true if the current portal has the provided gate
 */
export function useHasGate(gate) {
  const {
    gates
  } = useAuth();
  return gates.includes(gate);
}
export function useHasNotificationsGate() {
  return useHasGate(v3Gate('Subscriptions:Settings:BuyerEmails'));
}
export const useHasAllSupportedCurrenciesGate = () => useHasGate(v3Gate('Subscriptions:AllSupportedCurrencies'));
export function useHasIndexActionsGate() {
  return useHasGate(v3Gate('Subscriptions:IndexActions'));
}