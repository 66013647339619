import { useMemo } from 'react';
import PortalIdParser from 'PortalIdParser';
import { useObjectTypeSupportsCustomization } from './internal/useObjectTypeSupportsCustomization';
import { isIdle, isLoading, isRejected } from '../common/types/FetchStatus';
export const useGetPreviewCustomizationSettingsUrl = objectTypeId => {
  const objectTypeSupportsCustomizationState = useObjectTypeSupportsCustomization(objectTypeId, 'CRM_OBJECT_PREVIEW');
  const previewCustomizationSettingsLink = useMemo(() => {
    // This param is used when building links to the settings page to provide a backlink to records
    const eschref = `${window.location.pathname}${window.location.search}`;
    const baseSettingsUrl = `/sales-products-settings/${PortalIdParser.get()}/object/${objectTypeId}/preview-customization`;
    return `${baseSettingsUrl}?eschref=${encodeURIComponent(eschref)}`;
  }, [objectTypeId]);
  if (isIdle(objectTypeSupportsCustomizationState) || isLoading(objectTypeSupportsCustomizationState) || isRejected(objectTypeSupportsCustomizationState) || !objectTypeSupportsCustomizationState.data) {
    return null;
  }
  return previewCustomizationSettingsLink;
};