// ANY CONNECTION -> DEVICE

export let DeviceMessageTypes;
(function (DeviceMessageTypes) {
  DeviceMessageTypes["END_CALL_ON_DEVICE_MESSAGE"] = "END_CALL_ON_DEVICE_MESSAGE";
  DeviceMessageTypes["KEYPAD_NUMBER_PRESS_DEVICE_MESSAGE"] = "KEYPAD_NUMBER_PRESS_DEVICE_MESSAGE";
  DeviceMessageTypes["MUTE_DEVICE_MESSAGE"] = "MUTE_DEVICE_MESSAGE";
  DeviceMessageTypes["SELECT_SPEAKER_DEVICE_MESSAGE"] = "SELECT_SPEAKER_DEVICE_MESSAGE";
  DeviceMessageTypes["SELECT_MICROPHONE_DEVICE_MESSAGE"] = "SELECT_MICROPHONE_DEVICE_MESSAGE";
  DeviceMessageTypes["REFRESH_TAB_MESSAGE"] = "REFRESH_TAB_MESSAGE";
  DeviceMessageTypes["INITIATE_OUTBOUND_CALL_MESSAGE"] = "INITIATE_OUTBOUND_CALL_MESSAGE";
  DeviceMessageTypes["SET_THREAD_ID"] = "SET_THREAD_ID";
  DeviceMessageTypes["TOGGLE_CALL_ON_HOLD_MESSAGE"] = "TOGGLE_CALL_ON_HOLD_MESSAGE";
  DeviceMessageTypes["ANSWER_TRANSFER_REQUEST_MESSAGE"] = "ANSWER_TRANSFER_REQUEST_MESSAGE";
  DeviceMessageTypes["REJECT_TRANSFER_REQUEST_MESSAGE"] = "REJECT_TRANSFER_REQUEST_MESSAGE";
  DeviceMessageTypes["CONFIRM_TRANSFER_HANDOFF_MESSAGE"] = "CONFIRM_TRANSFER_HANDOFF_MESSAGE";
  DeviceMessageTypes["CANCEL_TRANSFER_REQUEST_MESSAGE"] = "CANCEL_TRANSFER_REQUEST_MESSAGE";
  DeviceMessageTypes["INITIATE_TRANSFER_REQUEST_MESSAGE"] = "INITIATE_TRANSFER_REQUEST_MESSAGE";
  DeviceMessageTypes["TOGGLE_RECORDING_MESSAGE"] = "TOGGLE_RECORDING_MESSAGE";
  DeviceMessageTypes["TOGGLE_RECORDING_MODAL_MESSAGE"] = "TOGGLE_RECORDING_MODAL_MESSAGE";
  DeviceMessageTypes["CLOSE_ONE_PARTY_CONSENT_RECORDING_ALERT_MESSAGE"] = "CLOSE_ONE_PARTY_CONSENT_RECORDING_ALERT_MESSAGE";
  DeviceMessageTypes["ACCEPT_INBOUND_CALL_MESSAGE"] = "ACCEPT_INBOUND_CALL_MESSAGE";
  DeviceMessageTypes["DECLINE_INBOUND_CALL_MESSAGE"] = "DECLINE_INBOUND_CALL_MESSAGE";
  DeviceMessageTypes["COMPLETE_CALL_MESSAGE"] = "COMPLETE_CALL_MESSAGE";
})(DeviceMessageTypes || (DeviceMessageTypes = {}));
export const END_CALL_ON_DEVICE_MESSAGE = DeviceMessageTypes.END_CALL_ON_DEVICE_MESSAGE;
export const KEYPAD_NUMBER_PRESS_DEVICE_MESSAGE = DeviceMessageTypes.KEYPAD_NUMBER_PRESS_DEVICE_MESSAGE;
export const MUTE_DEVICE_MESSAGE = DeviceMessageTypes.MUTE_DEVICE_MESSAGE;
export const SELECT_SPEAKER_DEVICE_MESSAGE = DeviceMessageTypes.SELECT_SPEAKER_DEVICE_MESSAGE;
export const SELECT_MICROPHONE_DEVICE_MESSAGE = DeviceMessageTypes.SELECT_MICROPHONE_DEVICE_MESSAGE;
export const REFRESH_TAB_MESSAGE = DeviceMessageTypes.REFRESH_TAB_MESSAGE;
export const INITIATE_OUTBOUND_CALL_MESSAGE = DeviceMessageTypes.INITIATE_OUTBOUND_CALL_MESSAGE;
export const SET_THREAD_ID = DeviceMessageTypes.SET_THREAD_ID;
export const TOGGLE_CALL_ON_HOLD_MESSAGE = DeviceMessageTypes.TOGGLE_CALL_ON_HOLD_MESSAGE;
export const ANSWER_TRANSFER_REQUEST_MESSAGE = DeviceMessageTypes.ANSWER_TRANSFER_REQUEST_MESSAGE;
export const REJECT_TRANSFER_REQUEST_MESSAGE = DeviceMessageTypes.REJECT_TRANSFER_REQUEST_MESSAGE;
export const CONFIRM_TRANSFER_HANDOFF_MESSAGE = DeviceMessageTypes.CONFIRM_TRANSFER_HANDOFF_MESSAGE;
export const CANCEL_TRANSFER_REQUEST_MESSAGE = DeviceMessageTypes.CANCEL_TRANSFER_REQUEST_MESSAGE;
export const INITIATE_TRANSFER_REQUEST_MESSAGE = DeviceMessageTypes.INITIATE_TRANSFER_REQUEST_MESSAGE;
export const TOGGLE_RECORDING_MESSAGE = DeviceMessageTypes.TOGGLE_RECORDING_MESSAGE;
export const TOGGLE_RECORDING_MODAL_MESSAGE = DeviceMessageTypes.TOGGLE_RECORDING_MODAL_MESSAGE;
export const CLOSE_ONE_PARTY_CONSENT_RECORDING_ALERT_MESSAGE = DeviceMessageTypes.CLOSE_ONE_PARTY_CONSENT_RECORDING_ALERT_MESSAGE;
export const ACCEPT_INBOUND_CALL_MESSAGE = DeviceMessageTypes.ACCEPT_INBOUND_CALL_MESSAGE;
export const DECLINE_INBOUND_CALL_MESSAGE = DeviceMessageTypes.DECLINE_INBOUND_CALL_MESSAGE;
export const COMPLETE_CALL_MESSAGE = DeviceMessageTypes.COMPLETE_CALL_MESSAGE;