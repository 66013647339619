import { useCallback } from 'react';
import { useFocusContext } from '../context/FocusContext';

/**
 * Reads the current focus state from a ref. Useful if you need to avoid stale
 * values from showing up in callbacks or desire a stable reference.
 *
 * Note that the callback returned will not trigger dependency changes when the
 * focus value changes, since it only depends on a ref itself.
 *
 * @example
 * const getIsFocused = useGetIsFocused();
 * const myCallback = useCallback(() => {
 *   if (getIsFocused()) {
 *     .. do something
 *   }
 * }, [getIsFocused]);
 *
 */
export const useGetIsFocused = () => {
  const ref = useFocusContext().isFocusedRef;
  return useCallback(() => {
    return ref.current;
  }, [ref]);
};