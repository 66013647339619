import { useRef, useCallback, useEffect } from 'react';
import { FILE_DROP } from '../editorCapabilities';
import { DropPlugin } from '../plugins/DropPlugin';
export function useDndSetup(editorState, setEditorState, editorConfig) {
  const containerRef = useRef(null);
  const sendFilesToDropPlugin = useCallback(files => {
    setEditorState(editorState.apply(editorState.tr.setMeta(DropPlugin, {
      files
    })));
  }, [editorState, setEditorState]);
  useEffect(() => {
    if (containerRef.current) {
      const instance = containerRef.current;
      const handleDrop = e => {
        if (e.dataTransfer && e.dataTransfer.files.length > 0) {
          e.preventDefault();
          e.stopPropagation();
          const fileDrop = editorConfig[FILE_DROP];
          if (fileDrop) {
            sendFilesToDropPlugin(e.dataTransfer.files);
          }
        }
      };
      const handleDragOver = e => {
        if (e.dataTransfer && e.dataTransfer.types.includes('Files')) {
          e.dataTransfer.dropEffect = 'copy';
          e.preventDefault();
          e.stopPropagation();
        }
      };
      const handleDragEnter = e => {
        if (e.dataTransfer && e.dataTransfer.types.includes('Files')) {
          e.dataTransfer.dropEffect = 'copy';
          e.preventDefault();
          e.stopPropagation();
        }
      };

      // Synthetic events do not behave correctly in this case.
      instance.addEventListener('drop', handleDrop, true);
      instance.addEventListener('dragover', handleDragOver, true);
      instance.addEventListener('dragenter', handleDragEnter, true);
      return () => {
        instance.removeEventListener('drop', handleDrop, true);
        instance.removeEventListener('dragover', handleDragOver, true);
        instance.removeEventListener('dragenter', handleDragEnter, true);
      };
    } else {
      return () => {};
    }
  }, [editorConfig, editorState, sendFilesToDropPlugin]);
  return containerRef;
}