import { useState, useCallback } from 'react';
import { Map as ImmutableMap } from 'immutable';
import { addDanger
// @ts-expect-error untyped-module
} from 'customer-data-ui-utilities/alerts/Alerts';
import { COMPANY_TYPE_ID, CONTACT_TYPE_ID, INVOICE_TYPE_ID, LINE_ITEM_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
import { FETCH_INVOICE } from '../graphQL/invoiceHighlightQuery';
import { InvoiceOperation } from 'invoices-iframe-lib/components/InvoicesEditorIFrameModal';
const useInvoiceEditor = (client, updateInvoiceSidebarCard, sendInvoiceCreatedMessage, updateInvoiceAssociations, operation, onObjectUpdate, resetChargePaymentMethodDisabledReason, canEditReminders, setPollInterval) => {
  const [isInvoiceEditorOpen, setInvoiceEditorOpen] = useState(false);
  const handleInvoiceEditorClose = useCallback(updatedInvoice => {
    setInvoiceEditorOpen(false);
    if (updatedInvoice && updatedInvoice.objectId) {
      if (operation === InvoiceOperation.CLONE) {
        sendInvoiceCreatedMessage(updatedInvoice.objectId);
      } else {
        updateInvoiceSidebarCard();
        updateInvoiceAssociations(COMPANY_TYPE_ID);
        updateInvoiceAssociations(LINE_ITEM_TYPE_ID);
        updateInvoiceAssociations(CONTACT_TYPE_ID);
        client.refetchQueries({
          include: [FETCH_INVOICE]
        }).catch(() => {
          addDanger('invoicesHighlightCard.alerts.generic.danger');
        });
        if (canEditReminders) {
          // updates to email reminders are not immediately reflected on the invoice, so we are polling for 3 seconds after the invoice is updated
          setPollInterval(1000);
          setTimeout(() => {
            setPollInterval(0);
          }, 3000);
        }
        onObjectUpdate({
          objectType: INVOICE_TYPE_ID,
          objectId: String(updatedInvoice.objectId),
          properties: Object.keys(updatedInvoice.properties).reduce((acc, prop) => {
            const property = updatedInvoice.properties[prop];
            return acc.set(property.name, property.value);
          }, ImmutableMap())
        });
      }
      resetChargePaymentMethodDisabledReason();
    }
  }, [updateInvoiceSidebarCard, client, operation, sendInvoiceCreatedMessage, resetChargePaymentMethodDisabledReason, onObjectUpdate, updateInvoiceAssociations, canEditReminders, setPollInterval]);
  return {
    isInvoiceEditorOpen,
    setInvoiceEditorOpen,
    handleInvoiceEditorClose
  };
};
export default useInvoiceEditor;