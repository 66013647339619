import createMark from '../../internal/createMark';
export const CrmLocationReady = createMark('mark-CrmLocationReady');
const marks = {};
function getMark(key) {
  if (Object.hasOwn(marks, key)) {
    return marks[key];
  }
  const mark = createMark(key);
  marks[key] = mark;
  return mark;
}
export const getCrmLocationReady = location => {
  const key = `mark-${location}-CrmLocationReady`;
  return getMark(key);
};
export const getCrmLocationInitialCardsReady = location => {
  const key = `mark-${location}-CrmLocationInitialCardsReady`;
  return getMark(key);
};