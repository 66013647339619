import { useContext } from 'react';
import { ActionsContext } from '../context/ActionsContext';
import { useProperty } from './useProperty';
import { useObjectType } from './useObjectType';
import { isContactEmail } from 'customer-data-objects/property/PropertyIdentifier';
export const useActionsForProperty = () => {
  const property = useProperty();
  const objectType = useObjectType();
  const {
    actions
  } = useContext(ActionsContext);
  if (isContactEmail(property, objectType)) {
    return actions.email;
  }
  return undefined;
};