// eslint-disable-next-line no-restricted-imports -- we use http by default
import http from 'hub-http/clients/apiClient';
import { registerQuery, useQuery } from 'data-fetching-client';
export const CAMPAIGNS_OBJECT_TYPE_ID = '0-35';
const FETCH_CAMPAIGNS = registerQuery({
  fieldName: 'hasCampaigns',
  args: [],
  fetcher: () => http.post('/crm-search/search', {
    data: {
      count: 1,
      offset: 0,
      objectTypeId: CAMPAIGNS_OBJECT_TYPE_ID,
      filterGroups: []
    }
  }).then(res => res.results.length > 0)
});
export const useFetchHasCampaigns = () => {
  return useQuery(FETCH_CAMPAIGNS);
};